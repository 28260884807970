import React from "react";

import CheckIcon from "../../../../../assets/svg/icons/check.svg";
import GavelIcon from "../../../../../assets/svg/icons/gavel.svg";
import RocketIcon from "../../../../../assets/svg/icons/rocket.svg";

function Markers(props){
    const roles = ['isVerified', 'isDeveloper', 'isModerator', 'boosted'];
    let hasMarkers = roles.some(role => props.user.info[role]);

    if(!hasMarkers){
        return null;
    }

    const size = 16
    
    return (
        <div style={{
            padding: "4px 4px 4px 4px",
            marginLeft: "8px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            gap: "10px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
        }}>
            {props.user.info.isVerified && 
            <div style={{
                color: "cyan",
                cursor: "default",
                userSelect: "none",
                display: "flex",
                alignItems: "center",
            }}>
                <CheckIcon height={size} />
            </div>
            }
            {props.user.info.boosted &&
            <div style={{
                color: "orchid",
                cursor: "default",
                userSelect: "none",
                padding: "0px",
                margin: "0px",
                display: "flex",
                alignItems: "center",
                }}>
                    <RocketIcon height={size} />
            </div>
            }
            {props.user.info.isDeveloper &&
            <h4 style={{
                color: "lightgreen",
                cursor: "default",
                userSelect: "none",
                padding: "0px",
                margin: "0px",
                fontFamily: "monospace",
                }}>
                    {`</>`}
            </h4>
            }
            {props.user.info.isModerator == true && !props.user.info.isAdmin &&
            <div style={{
                color: "orange",
                cursor: "default",
                userSelect: "none",
                display: "flex",
                alignItems: "center",
            }}>
                <GavelIcon height={size} />
            </div>
            }
        </div>
    )
}

export default Markers;