// @flow

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import supportStyles from "../../../../css/layout/support/support.css";
import cardStyles from "../../../../css/components/card.css";

import Container from "../../layout/parts/support/Container";

function SupportHome(props) {
    return (
        <Container>
            <div className={supportStyles.body}>
                <div className={cardStyles.card}>
                    <div className={cardStyles.cardHeader}>
                        Tiblur Support
                    </div>
                    <div className={cardStyles.cardBody}>
                        <ul>
                            <li>Join the <a target="_blank" href="https://discord.gg/pJk64gWBSs">Tiblur Discord server</a></li>
                            <li><Link to="/privacy">Tiblur Privacy Policy</Link></li>
                            {/* <li><Link to="/support/pages/contact">How to contact support.</Link></li> */}
                            <li><Link to="/support/pages/rules">End User License Agreement</Link></li>
                            <li><Link to="/support/pages/suppression">Limited visibility</Link></li>
                            {/* <li><Link to="/support/pages/tos">Terms of Service</Link></li> */}
                            <li><Link to="/support/pages/financials">Billing/financials</Link></li>
                            <li><Link to="/support/pages/about">Mission statement and About Tiblur</Link></li>
                            <li><Link to="/support/tutorial">Tiblur tutorial</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(SupportHome);
