import React, { useEffect } from "react";
import { connect } from "react-redux";

function PlainAd(props){
    return (null)
    if(!props.auth.isAuthenticated){
        return (null)
    }
    if(!props.auth.user){
        return (null)
    }
    if(!props.auth.user.info.username == "stormy"){
        return (null)
    }

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
    <div style={{marginBottom: "15px", border: "1px solid red"}}>
        <ins class="adsbygoogle"
        style={{display: "block"}}
        data-ad-client="ca-pub-9365032194420637"
        data-ad-slot="7439163479"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
    )
}

const mapStateToProps = state => ({
    theme: state.theme,
    auth: state.auth
});

export default connect(mapStateToProps)(PlainAd);