import React, {useState, useEffect } from "react";

import nStyles from "../../../../../css/components/notification.css";

import useWindowDimensions from "../../../../../util/hooks/useWindowDimensions";
import isMobile from "is-mobile";
import axios from "axios";
import { API_URL } from "../../../../../util/constants";
import DefaultPost from "../social/post/DefaultPost";
import Notification from "../social/notifications/Notification";
import { connect } from "react-redux";

const AnnouncementsPopup = React.forwardRef((props, ref) => {
    const {height, width} = useWindowDimensions();
    const mobile = isMobile()

    const [announcements, setAnnouncements] = useState([])

    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        }
        axios.get(`${API_URL}/announcements`, config)
            .then(res => {
                setAnnouncements(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return(
        <div id="announcements-popup"
             ref={ref}
        >
            <div style={{marginTop: "15px"}}>
            {announcements.length > 0 &&
                announcements.map(post => 
                    <DefaultPost post={post} noFooter={true}/>
                )
            }
            </div>
        </div>
    );
})

const mapStateToProps = state => {
    const { auth } = state;
    return { auth };
}

export default connect(mapStateToProps)(AnnouncementsPopup);