// @flow

import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

import layoutStyles from "../../../../../../../css/layout.css";
import formStyles from "../../../../../../../css/form.css";
import cardStyles from "../../../../../../../css/components/card.css";

import EmptyGraphic from "../../../../../../../assets/graphics/notifications.svg";

import Notification from "../Notification";

import {API_URL, MAX_MOBILE_WIDTH} from "../../../../../../../util/constants";
import store from "../../../../../../../store";
import {updateSocialNotificationsCount} from "../../../../../../../reducers/notifications/actions";
import ContentLoader from "../../../../../../../util/components/ContentLoader";
import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";
import { getToken } from "../../../../../../../reducers/auth/actions";

function ListTab(props) {
    const {width} = useWindowDimensions();
    const _isMounted = useRef(true);
    
    const [manager, setManager] = useState({
        notifications: [],
        loading: true,
        finished: false,
        offset: 0
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false
        }
    }, []);

    useEffect(() => {
        fetchNotifications();
    }, []);

    function fetchNotifications() {
        setManager(manager => ({
            ...manager,
            loading: true,
        }));

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        };

        axios.get(API_URL + "/@me/notifications?offset=" + manager.offset, config).then(res => {
            if(res.data.data.notifications){
                store.dispatch(updateSocialNotificationsCount());

                if(_isMounted.current){
                    if(res.data.data.notifications.length === 0){
                        setManager(manager => ({
                            ...manager,
                            loading: false,
                            finished: true,
                        }));
                        return;
                    }
                    setManager(manager => ({
                        ...manager,
                        notifications: [...manager.notifications].concat(res.data.data.notifications),
                        loading: false,
                        offset: manager.offset + res.data.data.notifications.length,
                    }));
                }
            }
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );
            setTimeout(() => {

                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 300);
        })
    }
    
    const ProfileLoader = (props) => (
        <ContentLoader
            width="100%"
            speed={1}
            viewBox="0 0 400 60"
        >
            <circle cx="27" cy="27" r="18" />
            <rect x="53" y="14" rx="3" ry="3" width="240" height="13" />
            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
            <rect x="67" y="30" rx="3" ry="3" width="120" height="10" />
            <circle cx="380" cy="27" r="8" />
            <rect x="0" y="53" rx="0" ry="0" width="400" height="1" />
            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>
    )

    function markSeen(id){
        manager.notifications.forEach((notification, index) => {
            if(notification.id === id){
                manager.notifications[index].seen = true;
            }
        });
    }

    return (
        <div>
            {
                manager.notifications.length === 0 && !manager.loading && !props.noCard && width > MAX_MOBILE_WIDTH &&
                <div className={cardStyles.card + ' ' + layoutStyles.mT1}>
                    <div className={cardStyles.cardBody}>
                        <EmptyGraphic />
                    </div>
                </div>
            }
            {
                manager.notifications.length === 0 && !manager.loading && (props.noCard || width <= MAX_MOBILE_WIDTH) &&
                <EmptyGraphic />
            }
            {
                manager.loading &&
                <div className={layoutStyles.mT1}>
                    {
                        [...Array(4)].map((e, i) => <ProfileLoader key={i} />)
                    }
                    <ProfileLoader />
                </div>
            }
            {
                <div>
                    <div>
                    {manager.notifications.map((notification) =>
                        <div onClick={() => markSeen(notification.id)}>
                            <Notification key={notification.id} notification={notification} />
                        </div>
                    )}
                    </div>
                    {!manager.loading && !manager.finished &&
                    <div style={{display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "15px"}}>
                        <button
                            className={formStyles.button + " " + layoutStyles.wF}
                            style={{width: "75%"}}
                            onClick={fetchNotifications}
                        >
                            Load more
                        </button>
                    </div>
                    }
                    {manager.finished &&
                    <div style={{display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "15px"}}>
                        <p>You have reached the end!</p>
                    </div>
                    }
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

ListTab.propTypes = {
    noCard: PropTypes.bool
};

ListTab.defaultProps = {
    noCard: false,
};

export default connect(mapStateToProps)(ListTab);
