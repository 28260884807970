// @flow

import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {toast} from "react-toastify";

import previewStyles from "../../../../../../css/components/profile-preview.css";
import layoutStyles, {mL} from "../../../../../../css/layout.css";
import {hide} from "../../../../../../css/layout.css";
import navStyles from "../../../../../../css/components/tabs-nav.css";

import LocationIcon from "../../../../../../assets/svg/icons/map-marker.svg";
import profileStyles from "../../../../../../css/layout/social/profile.css";
import {Link} from "react-router-dom";
import formStyles from "../../../../../../css/form.css";
import axios from "axios";
import {API_URL, MAX_MOBILE_WIDTH} from "../../../../../../util/constants";
import ContentLoader from "../../../../../../util/components/ContentLoader";
import useWindowDimensions from "../../../../../../util/hooks/useWindowDimensions";

import store from "../../../../../../store";
import { getToken } from "../../../../../../reducers/auth/actions";
import Markers from "../../markers/Markers";

function PreviewProfile(props) {
    const [manager, setManager] = useState({
        user: props.user ? props.user : props.social.previewProfile.user,
        avatarLoaded: false,
        followed: false,
        unfollowed: false,
        bannerLoaded: false,
    });

    let [button, setButton] = useState("Follow")

    useEffect(() => {
        if(props.social.previewProfile.user == null){ return }
        try{
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Auth-Token": store.dispatch(getToken())
                }
            };
            axios.get(API_URL + "/@me/checkfollow/" + manager.user.info.id, config)
                .then(res => {
                    setButton(res.data.data)
                })
                .catch(err => {
                    setButton("Follow")
                })
        } catch(err){
            console.log(err)
        }
    }, [manager.user])

    useEffect(() => {
        if(props.social.previewProfile.user == null){ return }
        setManager({...manager, user: props.social.previewProfile.user})
    }, [props.social.previewProfile.user])

    useEffect(() => {
        if(props.user == null){ return }
        setManager({...manager, user: props.user})
    }, [props.user])

    const {width} = useWindowDimensions();
    let bannerWidth = width;

    if(bannerWidth > MAX_MOBILE_WIDTH){
        bannerWidth = 525;
    }

    const ProfileLoader = () => (
        <ContentLoader
            speed={2}
            width={100}
            height={100}
            viewBox="0 0 100 100"
        >
            <rect x="0" y="0" width="100" height="100" />
        </ContentLoader>
    )

    const BannerLoader = () => (
        <ContentLoader
            speed={2}
            width={bannerWidth}
            height={bannerWidth/(3.5)}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
        >
            <rect x="0" y="0" width="100%" height="100" />
        </ContentLoader>
    )

    async function handleFollow() {
        if(!props.auth.isAuthenticated || manager.user.info.id === props.auth.user.info.id) return;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": await store.dispatch(getToken())
            }
        };

        axios
            .post(API_URL + "/users/follow/" + manager.user.info.id, {}, config)
            .then(res => {
                if(res.data.data.status === "requested"){
                    setButton("Requested")
                } else if(res.data.data.status === "following"){
                    setButton("Following")
                } else if(res.data.data.status === "followed"){
                    setButton("Following")
                }
                try{
                    props.followCallback(res.data.data.status);
                } catch(err){
                }
            })
            .catch(err => {
                const Notification = () => (
                    <div>
                        There was an error!
                    </div>
                );

                setTimeout(() => {
                    toast.error(<Notification />, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }, 800);
            });
    }

    async function handleUnfollow() {
        if(!props.auth.isAuthenticated || manager.user.info.id === props.auth.user.info.id) return;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": await store.dispatch(getToken())
            }
        };

        axios
            .delete(API_URL + "/users/follow/" + manager.user.info.id, config)
            .then(res => {
                if(res.data.data.status === "unfollowed"){
                    setButton("Follow")
                }
                try{
                    props.followCallback(res.data.data.status);
                } catch(err){
                }
            })
            .catch(err => {
                const Notification = () => (
                    <div>
                        There was an error!
                    </div>
                );

                setTimeout(() => {
                    toast.error(<Notification />, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }, 800);
            });
    }

    function handleClick() {
        if(button === "Follow"){
            handleFollow();
        } else if(button === "Following" || button === "Requested"){
            handleUnfollow();
        }
    }

    if(!manager.user){
        return (
            <div className={profileStyles.header}>
                <div className={profileStyles.cover}>
                </div>
            </div>
        );
    }

    let bannerStyles = {};
    if(manager.user.info.banner == null){
        bannerStyles = {
            // height: "150px"
            height: bannerWidth/3.5 + "px"
        }
    }

    return (
        <header className={layoutStyles.wF}>
            <div className={previewStyles.header} style={props.noRounding && {borderRadius: "0"}}>
                <div className={previewStyles.cover} style={bannerStyles}>
                    {
                        <img src={`${API_URL}/social/banner/${manager.user.info.id}`}
                             style={{ width: bannerWidth + "px", height: (bannerWidth/3.5) + "px" }}
                             onLoad={() => {
                                 setManager({...manager, bannerLoaded: true})
                             }}
                             className={!manager.bannerLoaded ? hide : ''} alt="profile banner"/>
                    }
                    {
                        manager.user.info.banner !== null && !manager.bannerLoaded && <BannerLoader />
                    }
                    <div className={previewStyles.profileImage}>
                        {
                            !manager.avatarLoaded && <ProfileLoader />
                        }
                            <img src={`${API_URL}/social/avatar/${manager.user.info.id}`}
                                onLoad={() => { setManager({ ...manager, avatarLoaded: true }) }}
                                className={!manager.avatarLoaded ? 'hide' : ''}
                                alt="header" />
                    </div>
                </div>
                <div className={previewStyles.main} style={{backgroundColor: "var(--sidebar-active)"}}> 
                    <div className={previewStyles.info}>
                        <div className={previewStyles.username}>
                            <Link to={`/user/${manager.user.info.username}`}>
                                <h3>{manager.user.info.username}</h3>
                            </Link>
                            {
                                manager.user.info.followed_by && <label>Follows you</label>
                            }
                            {
                                manager.user.info.blocking && <label>Blocked</label>
                            }
                        <Markers user={manager.user} />
                        </div>
                        {
                            manager.user.info.location !== null &&
                            <div className={previewStyles.location}>
                                <LocationIcon height={16} />
                                <span>{manager.user.info.location}</span>
                            </div>
                        }
                        {
                            !props.noBiography && manager.user.info.biography !== null &&
                            <div className={previewStyles.biography}>
                                <span>{manager.user.info.biography}</span>
                            </div>
                        }
                    </div>
                </div>
                {!props.noButtons &&
                <div className={navStyles.nav}>
                    <div className={navStyles.pages}>
                        <div className={navStyles.page}>
                            <label>
                                Feed
                            </label>
                            <span className={navStyles.active}/>
                        </div>
                        <div className={mL + ' ' + profileStyles.follow}>
                            {
                                !props.auth.isAuthenticated &&
                                <Link to={`/login`}
                                      className={formStyles.button + ' ' + formStyles.buttonPrimary}>
                                    Follow
                                </Link>
                            }
                            {
                                props.auth.isAuthenticated &&
                                <button disabled={manager.user.info.id === props.auth.user.info.id || manager.user.info.blocking}
                                        onClick={handleClick}
                                        className={button == "Following" || button == "Requested" ? formStyles.button + ' ' + formStyles.buttonFaded : formStyles.button + ' ' + formStyles.buttonPrimary}
                                        style={button == "Following" || button == "Requested" ? {color: "darkgray"} : {}}>
                                    {button}
                                </button>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        </header>
    );
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(PreviewProfile);
