import React from "react";
import {connect} from "react-redux";

import postStyles from "../../../../../css/components/post.css";
import layoutStyles, {mLN} from "../../../../../css/layout.css";

import useWindowDimensions from '../../../../../util/hooks/useWindowDimensions';
import isMobile from 'is-mobile';

function NewPostAd(props) {
    const {width} = useWindowDimensions()
    const mobile = isMobile()

    let style = {
        padding: "10px",
        marginTop: "10px",
        marginBottom: "10px",
    }

    if(props.grid){
        style = {
            maxWidth: "400px",
            minWidth: "400px",
            maxHeight: "250px",
            minHeight: "100px",
        }
    } else{
        style = {
            maxWidth: props.maxWidth ? props.maxWidth : `${width - 10}px`,
            minWidth: props.minWidth ? props.minWidth : mobile ? `${width-10}px` : "600px",
            maxHeight: props.maxHeight ? props.maxHeight : "100%",
            minHeight: props.minHeight ? props.minHeight : "100px",
        }
    }

    return (
        <div className={postStyles.post + ' ' + layoutStyles.mB1}>
            <div className={postStyles.header}>
                <h4 className={mLN}>Advertisement</h4>
            </div>
            <main style={style}>
                <div style={{zIndex: 0, display: "flex", justifyContent: "center"}}>
                    <p style={{marginTop: "20px"}}>Your ad here, if you were cool.</p>
                </div>
                <ins className="adsbygoogle"
                    style={{
                        display: "block",
                        width: style.minWidth,
                        height: style.minHeight
                    }}
                    data-ad-client="ca-pub-9365032194420637"
                    data-ad-slot="5144740188"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
            </main>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(NewPostAd);