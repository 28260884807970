// @flow

import React from "react";
import DOMPurify from "dompurify";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {PostType} from "../../../../../../util/types/PostTypes";
import PropTypes from "prop-types";

import postStyles from "../../../../../../css/components/post.css";
import layoutStyles, {mTN, mLN} from "../../../../../../css/layout.css";
import formStyles from "../../../../../../css/form.css";

import ClockIcon from "../../../../../../assets/svg/icons/clock.svg";

import {formatDate} from "../../../../../../util/date";
import {smartSubstr} from "../../../../../../util/smartSubstr";

import PostMediaWrapper from "./media/MediaWrapper";
import Magnet from "./media/magnet/Magnet";

import Header from "./components/Header";

function ReblogPost(props) {
    if(props.post == null){
        return null;
    }

    if(props.post.attachments == null){
        return null
    }

    let html = Number.isInteger(props.truncateAmount) ? smartSubstr(DOMPurify.sanitize(props.post.body), props.truncateAmount) : DOMPurify.sanitize(props.post.body);

    function HTMLStringToReactComponents(htmlString) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const elements = Array.from(htmlDoc.body.children);

        const allowedElements = ["a", "br", "b", "i", "u", "s", "strong", "em", "p", "h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "li", "blockquote", "code", "pre", "img", "div", "span", "hr"]
        const allowedAttributes = ["href", "src", "class", "id", "alt", "title", "target", "rel"]
        return elements.map((element, index) => {
            if(allowedElements.indexOf(element.tagName.toLowerCase()) === -1) return (<></>)
            if(element.tagName === "BR"){
                return <br key={index} />
            }

            if(element.tagName === "A"){
                try{
                    let url = new URL(element.href);
                    let url_no_protocol = url.hostname + url.pathname
                    if(url_no_protocol.startsWith("www.")){
                        url_no_protocol = url_no_protocol.substring(4)
                    }

                    let is_youtube = false
                    for(let url of ["youtube.com", "youtu.be"]){
                        if(url_no_protocol.startsWith(url)){
                            is_youtube = true
                            break
                        }
                    }

                    let is_twitter = false
                    if(!url_no_protocol.startsWith("twitter.com/hashtag")){
                        for(let url of ["twitter.com", "t.co", "x.com", "mobile.twitter.com"]){
                            if(url_no_protocol.startsWith(url)){
                                is_twitter = true
                                break
                            }
                        }
                    }

                    return(
                        <span>
                            <a
                            className="link"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                let hostname = new URL(element.href).hostname
                                if(hostname.startsWith("www.")){
                                    hostname = hostname.substring(4)
                                }
                                if(hostname === window.location.hostname){
                                    history.push(new URL(element.href).pathname)
                                } else{
                                    window.open(element.href)
                                }
                            }}
                            key={index}
                            to={element.href}>
                                <span data-tip data-for={`url-${element.href}`} style={{color: "inherit"}}>
                                    {element.textContent}
                                </span>
                                
                                <ReactTooltip id={`url-${element.href}`} place="bottom" type="dark" effect="solid">
                                    <span>{element.href}</span>
                                </ReactTooltip>
                                
                                {is_youtube && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="560"
                                    height="315"
                                    style={{border: "2px solid var(--link-color)", borderRadius: "5px", maxWidth: "100%"}}
                                    src={`https://www.youtube.com/embed/${url.searchParams.get("v")}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                                }
                                {is_twitter && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="550"
                                    height="285"
                                    style={{borderRadius: "5px", maxWidth: "100%"}}
                                    src={`${API_URL}/external/twitter-embed?url=${element.href}`}
                                    ></iframe>
                                }
                            </a>
                            {(is_youtube || is_twitter) && !manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => allowEmbed(element.href)}
                                > (embed)</span>
                            }
                            {(is_youtube || is_twitter) && manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => disallowEmbed(element.href)}
                                > (unembed)</span>
                            }
                            {mobile && !is_youtube && !is_twitter &&
                               <span style={{color: "pink"}}> ({url_no_protocol})</span>
                            }
                        </span>
                    )
                }
                catch(e){
                    return(
                        <span>
                            <a
                            className="link"
                            onClick={() => window.open(element.href)}
                            style={{
                                cursor: "pointer"
                            }}
                            key={index}>
                                {element.textContent}
                            </a>
                            <span style={{color: "pink"}}> ({element.href})</span>
                        </span>
                    )
                }
            }

            const elementProps = {};
            Array.from(element.attributes).forEach((attribute) => {
                if(allowedAttributes.indexOf(attribute.name) === -1) return
                elementProps[attribute.name] = attribute.value;
            });

            if(["ol", "ul"].indexOf(element.tagName.toLowerCase()) !== -1){
                const childComponents = Array.from(element.children).map((childElement, childIndex) => {
                    if(childElement.tagName.toLowerCase() === 'li'){
                        return HTMLStringToReactComponents(childElement.outerHTML);
                    } else{
                        return null;
                    }
                });

                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.filter(child => child !== null),
                );
            }

            if(element.tagName.toLowerCase() === 'img'){
                return null
            }

            if(element.textContent == null || element.textContent === ''){
                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps, dangerouslySetInnerHTML: { __html: element.innerHTML } },
                );
            }

            const childNodes = Array.from(element.childNodes);
            const childComponents = childNodes.map((childNode, childIndex) => {
                if(childNode.nodeType === Node.TEXT_NODE){
                    const words = childNode.textContent.split(' ');
                    return words.map((word, wordIndex) => {
                        if(word.startsWith('@') && word.length > 1){
                            return <Link key={wordIndex} to={`/${word.substring(1)}`}>{word} </Link>;
                        } else{
                            return <span key={wordIndex}>{word} </span>;
                        }
                    });
                } else if(childNode.nodeType === Node.ELEMENT_NODE && childNode.tagName.toLowerCase() === 'a'){
                    return HTMLStringToReactComponents(childNode.outerHTML)
                } else {
                    return HTMLStringToReactComponents(childNode.outerHTML);
                }
            });

            return (
                React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.flat(),
                )
            );
        });
    }

    return (
        <div className={postStyles.post + ' ' + postStyles.reblog}>
            <Header isOnPost={true} post={props.post} previewProfile={props.previewProfile} />
            <main style={{margin: "5px", marginLeft: "15px"}}>
                <PostMediaWrapper views={props.post.views} attachments={props.post.attachments}/>
                {
                    (props.post.body !== null || props.post.title !== null) &&
                    <div className={
                        postStyles.body + ' '
                        + (props.post.attachments.length > 0 ? mTN : '')
                    }>
                        {
                            props.post.title !== null &&
                            <h4>{props.post.title}</h4>
                        }
                        {
                            props.post.body !== null &&
                            // <p dangerouslySetInnerHTML={{ __html: smartSubstr(html) }} />
                            HTMLStringToReactComponents(props.post.body)
                        }
                    </div>
                }
                {
                    props.post.magnet !== null && <div className={layoutStyles.mB1}><Magnet magnet={props.post.magnet} /></div>
                }
            </main>
        </div>
    );
}

ReblogPost.propTypes = {
    post: PostType,
    truncateAmount: PropTypes.number,
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(ReblogPost);
