// @flow

import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import formStyles from "../../../../../../../css/form.css";
import layoutStyles, {
    mL, m1
} from "../../../../../../../css/layout.css";
import postModalStyles from "../../../../../../../css/layout/social/post-modal.css";

import CircleLoading from "../../../../../../../assets/loading/circle-loading.svg";

const Footer = (props) => {
    let [submitting, setSubmitting] = useState(false);
    let [buttonString, setButtonString] = useState(null);

    function submit(){
        setSubmitting(true);
        props.handleCaptchaNext()
    }

    useEffect(() => {
        if(!props.isSubmitting){
            setButtonString("Post")
            return
        }

        if(props.isConverting){
            if(props.compressionPercentage != null){
                setButtonString("Converting... " + props.compressionPercentage + "%")
                return
            }
            setButtonString("Converting...")
            return
        }

        setButtonString("Posting...")
    })

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {buttonString != "Post" && 
            <div style={{padding: "10px"}}>
                <p>Your post is being processed. You may close this modal, but closing or reloading this tab will abort the process.</p>
                <br/>
            </div>
            }

            <div className={postModalStyles.footer}>
                {
                    !props.showCaptcha && <button className={formStyles.button} disabled={props.submitting} onClick={props.callbackClose}>Close</button>
                }
                {
                    props.showCaptcha &&
                    <button className={formStyles.button}
                            disabled={props.submitting}
                            onClick={props.handleCaptchaBack}>
                        Back
                    </button>
                }
                {
                    ((props.auth.user !== null && props.auth.user.boosted) || (props.showCaptcha)) &&
                    <button
                        disabled={
                            props.postButtonDisabled()
                            ||
                            (props.auth.user !== null && !props.auth.user.boosted && props.securityCodeLength === 0)
                        }
                        className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + formStyles.buttonIcon + ' ' + postModalStyles.submitButton + ' ' + layoutStyles.mL}
                        onClick={props.handleSubmit}>
                        {buttonString}
                    </button>
                }
                {
                    props.auth.user !== null && !props.auth.user.boosted && !props.showCaptcha &&
                    <button
                        className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + layoutStyles.mL}
                        disabled={props.postButtonDisabled()}
                        onClick={submit}>
                        {(props.isSubmitting) &&
                            <CircleLoading width={16} />
                        }
                        {buttonString}
                    </button>
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

Footer.propTypes = {
    postButtonDisabled: PropTypes.func,
    handleSubmit: PropTypes.func,
    callbackClose: PropTypes.func,
    handleCaptchaNext: PropTypes.func,
    showCaptcha: PropTypes.bool,
    securityCodeLength: PropTypes.number,
    isSubmitting: PropTypes.bool,
}

export default connect(mapStateToProps)(Footer);
