import React from 'react'
import switchStyles from '../../../../../css/components/switch.css'

// NOTE: TODO: This is currently unused.
// The plan is to add this to all post view pages,
// so there's no need to re-do the same thing over and over.

function Switch(text, onChange, value){
    return(
        <div className={switchStyles.switchInput}>
        <label className={switchStyles.lever}>
            <input onChange={() => onChange(!value)}
                   type="checkbox"
                   checked={value}/>
            <span className={switchStyles.lever}/>
            {text}
        </label>
        </div>
    )
}

export default Switch