import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import chatStyles from "../../../../css/layout/chat/chat.css";
import axios from "axios";
import { API_URL, MAX_MOBILE_WIDTH } from "../../../../util/constants";
import ChatHistory from "./ChatHistory";
import isMobile from "is-mobile";

import { Alert } from "../../layout/parts/alert/Alert";
import PersonPreview from "./PersonPreview";
import useWindowDimensions from "../../../../util/hooks/useWindowDimensions";
import MainContainer from "../../layout/parts/social/container/MainContainer";

function Channel(props){
    const mobile = isMobile()
    
    const { width } = useWindowDimensions();
    const [user, setUser] = useState(null)

    let id = props.recipientID;
    if(!id){
        // get id from url
        try{
            id = props.match.params.id;
        } catch(e){}
    }

    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        }
        axios.get(`${API_URL}/users/profile/${id}`, config)
            .then(res => {
                res.data.data.user.info.status = "offline"
                setUser(res.data.data.user)
            })
            .catch(err => {
                setUser(null)
            })
    }, [props.chat.currentChannel, id]);

    if(!user){
        return(
            <div className={chatStyles.chatContainer} style={{display: "flex", justifyContent: "center", marginTop: "50px"}}>
            </div>
        )
    }

    if(id == null){
        return(
            <div style={{width: "100vw"}}>
                <div style={{display: "flex", justifyContent: "center", marginTop: "30%"}}>
                <Alert
                    type="warning"
                    title="Note"
                    text="Tiblur Chat is currently experimental. Messages may be lost and things may break!"
                />
                </div>
            </div>
        )
    }

    function handleDelete(id){
        props.handleDelete(id)
    }

    if(mobile){
        return (
            <MainContainer>
                <ChatHistory
                    fetchPreviousMessages={props.getPreviousHistory}
                    sendMessage={props.sendMessage}
                    user={user}
                    sendIsTyping={props.sendIsTyping}
                    handleDelete={handleDelete}
                />
            </MainContainer>
        )
    }

    return(
        <div style={{width: "100vw", height: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", height: "100%"}}>
                <ChatHistory
                    fetchPreviousMessages={props.getPreviousHistory}
                    sendMessage={props.sendMessage}
                    user={user}
                    sendIsTyping={props.sendIsTyping}
                    handleDelete={handleDelete}
                />
                {width > MAX_MOBILE_WIDTH + 250 &&
                    <PersonPreview user={user} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
});

export default connect(mapStateToProps)(Channel);