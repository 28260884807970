
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import formStyles from "../../../../css/form.css"

import isMobile from 'is-mobile';
import MainContainer from '../../layout/parts/social/container/MainContainer';
import fetchCaptcha from '../../../../util/captcha';

import { wF } from "../../../../css/layout.css";
import RecommendNav from '../../layout/parts/social/nav/right/RecommendNav';

function CaptchaWall(props){
    const urlParams = new URLSearchParams(window.location.search);
    const destination = urlParams.get("dest")

    const [state, setState] = useState({
        loading: true,
        error: null,
        captchaURL: null,
        captchaId: null,
        captchaCode: null,
        enableButton: false,
        invalidated: false
    })

    const mobile = isMobile()

    useEffect(() => {
        if(!destination){
            setState({loading: false, error: "No destination provided."})
            return
        }

        async function fetchData(){
            const result = await fetchCaptcha(destination)
            setState({
                ...state,
                loading: false,
                captchaURL: result.captcha,
                captchaId: result.id
            })
        }

        fetchData()
    }, [])

    if(state.error != null){
        return(
            <MainContainer >
                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "12%"}}>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "center", padding: "15px"}}>
                        <h1>Error</h1>
                        <p>{state.error}</p>

                        <button
                            className={formStyles.button + " " + formStyles.buttonPrimary}
                            style={{marginTop: "10px"}}
                            onClick={() => {
                                window.location.href = "/"
                            }}>
                            Go Home
                        </button>
                    </div>
                </div>
            </MainContainer>
        )
    }

    if(state.invalidated){
        return(
            <MainContainer >
                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "12%"}}>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "center", padding: "15px"}}>
                        <h1>Captcha no longer valid.</h1>
                    </div>
                </div>
            </MainContainer>
        )
    }

    function onChange(e) {
        const value = e.target.value;
        const enableButton = value.length === 4;
        setState({
            ...state,
            captchaCode: value,
            enableButton: enableButton
        })
    }

    function onClick() {
        if(!state.enableButton){
            return
        }

        const destinationWithCaptcha = destination + "?securityId=" + state.captchaId + "&securityCode=" + state.captchaCode
        window.location.href = destinationWithCaptcha

        setState({
            ...state,
            invalidated: true
        })
    }

    return(
        <MainContainer>
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "12%"}}>
                <div style={{display: "flex", flexDirection: "column", textAlign: "center", padding: "15px"}}>
                    <h1>Captcha Required</h1>
                    <p>The page you are trying to access requires a captcha to be solved.</p>
                    <p>Please solve the captcha below to continue.</p>

                    <div style={{display: 'flex', justifyContent: "center"}}>
                        <div className={formStyles.formGroup} style={{width: mobile ? "90%" : "500px", border: "2px solid white", borderRadius: "10px", padding: "10px"}}>
                        {
                            state.captchaURL &&
                            <img
                                alt="captcha"
                                className={wF}
                                src={state.captchaURL}
                            />
                        }
                        </div>
                    </div>

                    <div className={formStyles.formGroup} style={{display: 'flex', justifyContent: "center"}}>
                        <p>Captcha is case insensitive.</p>
                        <input
                            type="text"
                            onChange={onChange}
                            maxLength={4}
                            className={
                                formStyles.input + ' ' +
                                (props.auth.error === "WRONG_SECURITY_CODE" ? formStyles.invalidInput : '')
                            }
                            style={{maxWidth: "200px", marginLeft: "auto", marginRight: "auto"}}
                            placeholder="Security code"
                            autoFocus={true}
                        />
                    </div>

                    <div style={{display: 'flex', justifyContent: "center"}}>
                        <button
                            className={formStyles.button + " " + (state.enableButton ? formStyles.buttonPrimary : "")}
                            style={{marginTop: "10px"}}
                            onClick={onClick}>
                            Continue
                        </button>
                    </div>

                </div>
            </div>
            {!mobile && <RecommendNav />}
        </MainContainer>
    )
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(CaptchaWall);
