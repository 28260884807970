import { useEffect } from "react";

const outsideClick = (ref, callback, byID=false) => {
  const handleClick = e => {
    if(byID){
      let toCallback = true
      let element = e.target;
      while(element){
        if(element.id === "announcements-popup"){
          toCallback = false
        }
        element = element.parentNode;
      }
      if(toCallback){
        callback();
      }
      return
    }

    if(ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    window.__app_mount.addEventListener("click", handleClick);

    return () => {
      window.__app_mount.removeEventListener("click", handleClick);
    };
  });
};

export default outsideClick;
