import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import plyr from 'plyr'
import 'plyr/dist/plyr.css'
import '../../css/components/plyr.css'

import formStyles from '../../css/form.css'
import layoutStyles, {mL} from '../../css/layout.css'
import modalStyles from '../../css/components/modal.css'
import { box } from "../../css/layout/chat/message-box.css";

import {isMobile} from 'is-mobile'

import ContentLoader from "../../util/components/ContentLoader";
import fetchCaptcha from '../captcha'

import isVisible from '../../util/hooks/isVisible'

class CaptchaPlaceholder extends React.Component {
    render() {
        return (
            <ContentLoader
                width="100%"
                height="135"
                viewBox="0 0 100 135"
                preserveAspectRatio="none"
            >
                <rect x="0" y="0" width="100%" height="135" />
            </ContentLoader>
        )
    }
}

function PlyrComponent(props) {
    const [state, setState] = useState({
        showCaptcha: false,
        captchaURL: null,
        captchaId: null,
        captchaLoading: true,
        error: null,
        securityCode: null,
        sending: false
    })

    const [plyrPlayer, setPlyrPlayer] = useState(null)

    const videoRef = useRef(null)
    const isOnScreen = isVisible(videoRef)

    useEffect(() => {
        if(!isOnScreen){
            if(plyrPlayer != null){
                plyrPlayer.pause()
            }
        }
    }, [isOnScreen])

    useEffect(() => {
        let options = props.options
        options.autoplay = props.autoplay
        options.urls = {
            download: ""
        }

        let player = new plyr('.js-plyr', options)

        const sourceUrl = props.sources.sources[0].src
        const addPlyr = !sourceUrl.startsWith("blob")

        let newSourceUrl
        if (addPlyr) {
            newSourceUrl = sourceUrl + "?plyr=true"
        } else {
            newSourceUrl = sourceUrl
        }
        newSourceUrl += "&token=" + props.auth.token

        player.source = {
            type: 'video',
            sources: [
                { src: newSourceUrl }
            ]
        }

        player.elements.buttons.download.addEventListener('click', (e) => {
            e.preventDefault()
            openModal()
        })

        setPlyrPlayer(player)

        return () => {
            player.destroy()
        }
    }, [])

    function getNewCaptcha() {
        fetchCaptcha().then((data) => {
            setState({
                ...state,
                captchaURL: data.captcha,
                captchaId: data.id,
                captchaLoading: false,
                securityCode: "",
            })
        })
    }

    function openModal() {
        fetchCaptcha().then((data) => {
            setState({
                ...state,
                captchaURL: data.captcha,
                captchaId: data.id,
                captchaLoading: false,
                showCaptcha: true
            })
        })
    }

    function closeModal() {
        setState({
            ...state,
            showCaptcha: false,
            securityCode: "",
            sending: false
        })
    }

    function stopLoading() {
        setState({
            ...state,
            captchaLoading: false
        })
    }

    function handleUpgrade() {
    }

    function handleSecurityCode(event) {
        if(event.target.value.length > 4) return

        setState({
            ...state,
            securityCode: event.target.value
        })
    }

    function handleEnter(event) {
        if(event.key === "Enter"){
            downloadVideo()
        }
    }

    function downloadVideo() {
        const downloadUrl = props.sources.sources[0].src + "?securityId=" + state.captchaId + "&securityCode=" + state.securityCode

        // Fetch the video
        fetch(downloadUrl)
            .then(response => response.blob())
            .then(blob => {
                if(!blob.type.startsWith("video")){
                    throw new Error("WRONG_SECURITY_CODE")
                }

                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = "video.mp4"
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                setState({
                    ...state,
                    error: "WRONG_SECURITY_CODE"
                })

                getNewCaptcha()
            })
    }

    return (
        <div ref={videoRef}>
            {state.showCaptcha ?
            <div className={box}>
                <div className={modalStyles.containerOuter + (isMobile() ? (" " + modalStyles.mobile) : "")}>
                    <div className={modalStyles.wrapper}>
                        <div className={modalStyles.containerInner}>
                            <div className={modalStyles.modalContainer}>
                                <div className={modalStyles.modal}>
                                    <div className={modalStyles.top}>
                                        <div className={modalStyles.header}>
                                            <h4>Complete captcha</h4>
                                        </div>
                                    </div>
                                    <div className={modalStyles.body}>
                                        <div className={formStyles.formGroup}>
                                            {
                                                state.captchaURL &&
                                                <img
                                                    alt="captcha"
                                                    className={layoutStyles.wF + ' ' + (state.captchaLoading ? layoutStyles.hide : '')}
                                                    style={{position: "relative"}}
                                                    onLoad={stopLoading}
                                                    src={state.captchaURL}
                                                />
                                            }
                                            {
                                                (!state.captchaURL || state.captchaLoading) && <CaptchaPlaceholder />
                                            }
                                        </div>
                                        <div className={formStyles.formGroup}>
                                            <div className={formStyles.alert}>
                                                <div>
                                                    <p>Video downloads require a captcha.</p>
                                                    <p>Captcha is case insensitive.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={formStyles.formGroup}>
                                            {
                                                state.error === "WRONG_SECURITY_CODE" && <label className={formStyles.invalidLabel}>Incorrect security code.</label>
                                            }
                                            <input
                                                type="text"
                                                // ref={securityCodeRef}
                                                onChange={handleSecurityCode}
                                                // onKeyPress={handleSubmit}
                                                value={state.securityCode}
                                                maxLength={4}
                                                className={
                                                    formStyles.input + ' ' +
                                                    (state.error === "WRONG_SECURITY_CODE" ? formStyles.invalidInput : '')
                                                }
                                                placeholder="Security code"
                                            />
                                        </div>
                                    </div>
                                    <div className={modalStyles.footer}>
                                        <button className={formStyles.button}
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                        <button className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + mL}
                                            onClick={downloadVideo}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div></div>
            }
            <video preload='metadata' autoplay={props.autoplay} className='js-plyr plyr'>
            </video>
        </div>
    )
}

PlyrComponent.defaultProps = {
    autoplay: false,
    options: {
        controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'settings',
            'fullscreen',
            'download'
        ],
        i18n: {
            restart: 'Restart',
            rewind: 'Rewind {seektime}s',
            play: 'Play',
            pause: 'Pause',
            fastForward: 'Forward {seektime}s',
            seek: 'Seek',
            seekLabel: '{currentTime} of {duration}',
            played: 'Played',
            buffered: 'Buffered',
            currentTime: 'Current time',
            duration: 'Duration',
            volume: 'Volume',
            mute: 'Mute',
            unmute: 'Unmute',
            enableCaptions: 'Enable captions',
            disableCaptions: 'Disable captions',
            download: 'Download',
            enterFullscreen: 'Enter fullscreen',
            exitFullscreen: 'Exit fullscreen',
            frameTitle: 'Player for {title}',
            captions: 'Captions',
            settings: 'Settings',
            menuBack: 'Go back to previous menu',
            speed: 'Speed',
            normal: 'Normal',
            quality: 'Quality',
            loop: 'Loop',
        },
        speed: {
            selected: 1,
            options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
        },
        iconUrl: "/plyr/icons.svg",
        blankVideo: "/plyr/blank.mp4",
        volume: 0.35 // Prevent earrape. NOTE: Plyr will save the last used volume in localStorage, if the user changes it.
    },
}

PlyrComponent.propTypes = {
    options: PropTypes.object,
    sources: PropTypes.object,
    source: PropTypes.func,
    destroy: PropTypes.func,
    autoplay: PropTypes.bool,
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(PlyrComponent);