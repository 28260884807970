// @flow

import React, {useState, useEffect} from "react";
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import postStyles from "../../../../../../../css/components/post.css";
import {mTN, pBN} from "../../../../../../../css/layout.css";
import formStyles, {button} from "../../../../../../../css/form.css";

import ReblogPost from "../ReblogPost";
import MediaWrapper from "../media/MediaWrapper";

import {smartSubstr} from "../../../../../../../util/smartSubstr";

import {PostType} from "../../../../../../../util/types/PostTypes";
import {UserType} from "../../../../../../../util/types/UserTypes";
import Poll from "../media/poll/Poll";
import Magnet from "../media/magnet/Magnet";
import {parseMentions} from "../../../../../../../util/parsePost";
import history from "../../../../../../../util/history";
import isMobile from "is-mobile";
import DefaultPost from "../DefaultPost";

import axios from "axios";
import store from "../../../../../../../store";
import { getToken } from "../../../../../../../reducers/auth/actions";
import { API_URL } from "../../../../../../../util/constants";
import { toast } from "react-toastify";
import { handleHidePost } from "../../../../../../../reducers/social/actions";
import ReactTooltip from "react-tooltip";

function Body(props) {
    let post = null;
    if(props.post.reblog !== null && (props.post.body === null && props.post.attachments.length === 0)){
        post = props.post.reblog;
    } else {
        post = props.post;
    }

    if(post.body != null){
        post.body = post.body.replaceAll("&nbsp;", " ")
    }

    const mobile = isMobile()
    const maxLength = mobile ? 512 : 1024

    const [manager, setManager] = useState({
        showNsfw: false,
        hidden: props.post.filtered,
        allowedEmbeds: [],
        renderedPost: null,
        showAll: post.body == null || post.body.length < maxLength 
    });

    useEffect(() => {
        setManager(manager => ({ ...manager, hidden: props.post.filtered }));
    }, [props.post.filtered]);

    function handleNsfw() {
        setManager(manager => ({ ...manager, showNsfw: true }));
    }

    function handleMouseClick(e){
        return // TODO: Temporarily disabled, add to settings.
        if(isMobile()) return;
        let level = e.target
        while(level != null){
            if(level.id.startsWith("reblog")){
                history.push(`/post/${post.reblog.id}`);
                return
            } else if(level.id.startsWith("post")){
                history.push(`/post/${post.id}`);
                return
            }
            level = level.parentNode;
        }
    }

    function allowEmbed(url){
        setManager(manager => ({
            ...manager,
            allowedEmbeds: [...manager.allowedEmbeds, url]
        }))
    }

    function disallowEmbed(url){
        setManager(manager => ({
            ...manager,
            allowedEmbeds: manager.allowedEmbeds.filter(u => u !== url)
        }))
    }

    function HTMLStringToReactComponents(htmlString) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const elements = Array.from(htmlDoc.body.children);

        const allowedElements = ["a", "br", "b", "i", "u", "s", "strong", "em", "p", "h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "li", "blockquote", "code", "pre", "img", "div", "span", "hr"]
        const allowedAttributes = ["href", "src", "class", "id", "alt", "title", "target", "rel"]
        return elements.map((element, index) => {
            if(allowedElements.indexOf(element.tagName.toLowerCase()) === -1) return (<></>)
            if(element.tagName === "BR"){
                return <br key={index} />
            }

            if(element.tagName === "A"){
                try{
                    let url = new URL(element.href);
                    let url_no_protocol = url.hostname + url.pathname
                    if(url_no_protocol.startsWith("www.")){
                        url_no_protocol = url_no_protocol.substring(4)
                    }

                    let is_youtube = false
                    let yt_video_id;
                    for(let entry of ["www.youtube.com", "youtube.com", "youtu.be"]){
                        if(url_no_protocol.startsWith(entry)){
                            is_youtube = true
                            if(url_no_protocol.startsWith("youtu.be")){
                                yt_video_id = url_no_protocol.split("/")[1]
                            } else {
                                yt_video_id = url.searchParams.get("v")
                            }
                            break
                        }
                    }

                    let is_twitter = false
                    if(!url_no_protocol.startsWith("twitter.com/hashtag")){
                        for(let url of ["twitter.com", "t.co", "x.com", "mobile.twitter.com"]){
                            if(url_no_protocol.startsWith(url)){
                                is_twitter = true
                                break
                            }
                        }
                    }

                    return(
                        <span>
                            <a
                            className="link"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                let hostname = new URL(element.href).hostname
                                if(hostname.startsWith("www.")){
                                    hostname = hostname.substring(4)
                                }
                                if(hostname === window.location.hostname){
                                    history.push(new URL(element.href).pathname)
                                } else{
                                    window.open(element.href)
                                }
                            }}
                            key={index}
                            to={element.href}>
                                <span data-tip data-for={`url-${element.href}`} style={{color: "inherit"}}>
                                    {element.textContent}
                                </span>
                                
                                <ReactTooltip id={`url-${element.href}`} place="bottom" type="dark" effect="solid">
                                    <span>{element.href}</span>
                                </ReactTooltip>
                                
                                {is_youtube && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="560"
                                    height="315"
                                    style={{border: "2px solid var(--link-color)", borderRadius: "5px", maxWidth: "100%"}}
                                    src={`https://www.youtube.com/embed/${yt_video_id}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                                }
                                {is_twitter && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="550"
                                    height="285"
                                    style={{borderRadius: "5px", maxWidth: "100%"}}
                                    src={`${API_URL}/post/twitter-embed?url=${element.href}`}
                                    ></iframe>
                                }
                            </a>
                            {(is_youtube || is_twitter) && !manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => allowEmbed(element.href)}
                                > (embed)</span>
                            }
                            {(is_youtube || is_twitter) && manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => disallowEmbed(element.href)}
                                > (unembed)</span>
                            }
                            {mobile && !is_youtube && !is_twitter &&
                               <span style={{color: "pink"}}> ({url_no_protocol})</span>
                            }
                        </span>
                    )
                }
                catch(e){
                    return(
                        <span>
                            <a
                            className="link"
                            onClick={() => window.open(element.href)}
                            style={{
                                cursor: "pointer"
                            }}
                            key={index}>
                                {element.textContent}
                            </a>
                            <span style={{color: "pink"}}> ({element.href})</span>
                        </span>
                    )
                }
            }

            const elementProps = {};
            Array.from(element.attributes).forEach((attribute) => {
                if(allowedAttributes.indexOf(attribute.name) === -1) return
                elementProps[attribute.name] = attribute.value;
            });

            if(["ol", "ul"].indexOf(element.tagName.toLowerCase()) !== -1){
                const childComponents = Array.from(element.children).map((childElement, childIndex) => {
                    if(childElement.tagName.toLowerCase() === 'li'){
                        return HTMLStringToReactComponents(childElement.outerHTML);
                    } else{
                        return null;
                    }
                });

                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.filter(child => child !== null),
                );
            }

            if(element.tagName.toLowerCase() === 'img'){
                return null
            }

            if(element.textContent == null || element.textContent === ''){
                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps, dangerouslySetInnerHTML: { __html: element.innerHTML } },
                );
            }

            const childNodes = Array.from(element.childNodes);
            const childComponents = childNodes.map((childNode, childIndex) => {
                if(childNode.nodeType === Node.TEXT_NODE){
                    const words = childNode.textContent.split(' ');
                    return words.map((word, wordIndex) => {
                        if(word.startsWith('@') && word.length > 1){
                            return <Link key={wordIndex} to={`/user/${word.substring(1)}`}>{word} </Link>;
                        } else{
                            return <span key={wordIndex}>{word} </span>;
                        }
                    });
                } else if(childNode.nodeType === Node.ELEMENT_NODE && childNode.tagName.toLowerCase() === 'a'){
                    return HTMLStringToReactComponents(childNode.outerHTML)
                } else {
                    return HTMLStringToReactComponents(childNode.outerHTML);
                }
            });

            return (
                React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.flat(),
                )
            );
        });
    }

    function unhidePost(){
        if(!props.auth.isAuthenticated) return;

        setManager(manager => ({
            ...manager,
            hidden: false
        }));

        axios.delete(API_URL + "/@me/filter-post/" + props.post.id, {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            store.dispatch(handleHidePost(props.post.id, false))

            const Notification = () => (
                <div>
                    Post revealed!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    if(post.attachments == null || post.author == null){
        return null
    }

    useEffect(() => {
        if(!post.body){ return }
        let render;
        if(manager.showAll){
            render = HTMLStringToReactComponents(post.body)
        } else{
            render = HTMLStringToReactComponents(post.body.slice(0, maxLength) + (post.body.length > maxLength ? "..." : ""))
        }

        setManager(manager => ({
            ...manager,
            renderedPost: render
        }))
    }, [manager.showAll, manager.allowedEmbeds])

    function onReadMore(){
        setManager(manager => ({
            ...manager,
            showAll: true
        }))
    }
    
    return (
        <main id={`post-${props.post.id}`}>
            {props.auth.user == null ?
                post.attachments.length > 0 && (!post.nsfw || manager.showNsfw) && <MediaWrapper views={post.views} attachments={post.attachments} />
            :
                post.attachments.length > 0 && (post.nsfw == false || manager.showNsfw || props.auth.user.info.nsfw_filter == false) && !manager.hidden &&
                <MediaWrapper views={post.views} attachments={post.attachments} />
            }

            {
                (!manager.showNsfw && post.nsfw && (props.auth.user === null || props.auth.user.info.nsfw_filter == true)) &&
                <div className={postStyles.nsfw}>
                    {
                        props.auth.user === null && "This post contains NSFW."
                    }
                    {
                        props.auth.user !== null &&
                        "This post contains NSFW content. You can disable the filter in your settings."
                    }
                    <button className={formStyles.button + ' ' + postStyles.show} onClick={handleNsfw}>Show</button>
                </div>
            }
            {manager.hidden &&
                <div className={postStyles.nsfw}>
                    You have hidden this post.
                    <button className={formStyles.button + ' ' + postStyles.show} onClick={unhidePost}>Reveal</button>
                </div>
            }
            {
                (post.body != null || post.title != null ) && (!post.nsfw || (manager.showNsfw || !(props.auth.user === null || props.auth.user.nsfw_filter))) && !manager.hidden &&
                <div className={postStyles.body + ' ' + (post.attachments.length > 0 ? mTN : '') + ' ' + (post.tags.length > 0 ? pBN : '')}
                style={{"padding": "0.5rem", "paddingLeft": "1.25rem", overflowWrap: "anywhere"}}
                onMouseDown={(e) => handleMouseClick(e)}>
                    {post.title !== null && <h4>{post.title}</h4>}
                    {props.auth.user == null ?
                        post.body != null && (!post.nsfw || manager.showNsfw) &&
                        manager.renderedPost
                    :
                        post.body != null && (!post.nsfw || manager.showNsfw || props.auth.user.info.nsfw_filter == false) &&
                        manager.renderedPost
                    }
                    {!manager.showAll &&
                    <div>
                        <p onClick={onReadMore} style={{color: "cyan", cursor: "pointer"}}>Read more...</p>
                    </div>
                    }
                </div>
            }
            {
                post.poll != null && post.poll.options.length > 0 && <Poll post={props.post} poll={post.poll} />
            }
            {
                post.magnet !== null && <Magnet magnet={post.magnet} />
            }
            {post.reblog != null &&
                <div id={`reblog-${props.post.reblog.id}`}
                onMouseDown={(e) => handleMouseClick(e)}
                style={{display: "flex", justifyContent: "center", marginTop: "5px", marginBottom: "5px"}}
                >
                    {post.reblog == "unavailable" ?
                        <p style={{color: "darkgray"}}>Reblog is unavailable.</p>
                        :
                        <DefaultPost isReblog={true} post={post.reblog} />
                    }
                </div>
            }
            {
                post.tags.length > 0 && !props.previewProfile && (!props.profile || props.profileUser !== null) &&
                <div className={postStyles.tagsWrapper}>
                    {post.tags.map((tag) => (
                        <Link key={tag.id}
                              to={`/search/${encodeURIComponent("#" + tag)}`}
                              className={postStyles.tag}>
                            <label>#{tag}</label>
                        </Link>
                    ))}
                </div>
            }
            {
                post.tags.length > 0 && props.previewProfile && props.profileUser !== null &&
                <div className={postStyles.tagsWrapper}>
                    {post.tags.map((tag) => (
                        <Link key={tag.id}
                              to={`/user/${props.profileUser.info.username}?q=#${tag}`}
                              className={postStyles.tag}>
                            <label>#{tag}</label>
                        </Link>
                    ))}
                </div>
            }
        </main>
    );
}

Body.propTypes = {
    profile: PropTypes.bool,
    profileUser: UserType,
    previewProfile: PropTypes.bool,
    post: PostType.isRequired,
    truncate: PropTypes.bool,
};

Body.defaultProps = {
    profile: false,
    profileUser: null,
    previewProfile: false,
    truncate: true,
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(Body);
