import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import formStyles from "../../../../../../css/form.css";

import {API_URL} from "../../../../../../util/constants";

import store from "../../../../../../store"
import {getToken} from "../../../../../../reducers/auth/actions";

function FollowButton(props) {
    let [button, setButton] = useState("Follow")


    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        };

        axios.get(API_URL + "/@me/checkfollow/" + props.user.info.id, config)
            .then(res => {
                setButton(res.data.data)
            })
            .catch(err => {
                setButton("Follow")
            })
    }, [])

    async function handleFollow() {
        if(!props.auth.isAuthenticated || props.user.info.id === props.auth.user.info.id) return;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": await store.dispatch(getToken())
            }
        };

        axios
            .post(API_URL + "/users/follow/" + props.user.info.id, {}, config)
            .then(res => {
                if(res.data.data.status === "requested"){
                    setButton("Requested")
                } else if(res.data.data.status === "following"){
                    setButton("Following")
                } else if(res.data.data.status === "followed"){
                    setButton("Following")
                }
                props.followCallback(res.data.data.status);
            })
            .catch(err => {
                const Notification = () => (
                    <div>
                        There was an error!
                    </div>
                );

                setTimeout(() => {
                    toast.error(<Notification />, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }, 800);
            });
    }

    async function handleUnfollow() {
        if(!props.auth.isAuthenticated || props.user.info.id === props.auth.user.info.id) return;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": await store.dispatch(getToken())
            }
        };

        axios
            .delete(API_URL + "/users/follow/" + props.user.info.id, config)
            .then(res => {
                if(res.data.data.status === "unfollowed"){
                    setButton("Follow")
                }
                props.unfollowCallback();
            })
            .catch(err => {
                const Notification = () => (
                    <div>
                        There was an error!
                    </div>
                );

                setTimeout(() => {
                    toast.error(<Notification />, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }, 800);
            });
    }

    function handleClick() {
        if(button === "Follow"){
            handleFollow();
        } else if(button === "Following" || button === "Requested"){
            handleUnfollow();
        }
    }

    return (
      <>
          {
              !props.auth.isAuthenticated && !props.user.info.following && !props.user.info.requested &&
              <Link to={`/login`}
                    className={formStyles.button + ' ' + formStyles.buttonPrimary}>
                  Follow
              </Link>
          }
          {
              props.auth.isAuthenticated &&
              <button disabled={props.user.info.id === props.auth.user.info.id || props.user.info.blocking}
                      onClick={handleClick}
                      className={button == "Following" || button == "Requested" ? formStyles.button + ' ' + formStyles.buttonFaded : formStyles.button + ' ' + formStyles.buttonPrimary}
                      style={button == "Following" || button == "Requested" ? {color: "darkgray"} : {}}>
                {button}
              </button>
          }
      </>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(FollowButton);
