import React, { useRef } from "react"
import { connect } from "react-redux"

import Header from "../../layout/parts/user/preview/Header"
import {container} from "./PersonPreview.css"
import FeedContainer from "../../layout/parts/social/feed/FeedContainer"

import {postContainer} from "../../../../css/layout/social/container/main.css";
import layoutStyles, {mB1, mT1} from "../../../../css/layout.css";

import profileStyles from "../../../../css/layout/social/profile.css";

import Loading from "../../layout/parts/social/feed/components/Loading"
import store from "../../../../store"
import { fetchPosts } from "../../../../reducers/social/actions"
import DefaultPost from "../../layout/parts/social/post/DefaultPost"
import PostAd from "../social/ad/PostAd"
import { SocialProvider } from "../../layout/parts/social/context"
import About from "../profile/About"
import { ProfileProvider } from "../../layout/parts/profile/context"

function PersonPreview(props){
    if(props.user == null){
        return(
            <div style={{border: "1px solid red", minWidth: "300px", maxWidth: "30%", width: "100%", height: "100%"}}>
            </div>
        )
    }

    return(
        <div className={container} style={{backgroundColor: "var(--sidebar-active)"}}>
            <Header noRounding={true} noButtons={true} user={props.user} />
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "100%"}}>
                    <ProfileProvider>
                        <About user={props.user} />
                    </ProfileProvider>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(PersonPreview);