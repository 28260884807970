import React, {useState} from "react";
import NewPost from "./QuickPost";

import formStyles from "../../../../../../../css/form.css";

function QuickPostButton(props) {
    const [show, setShow] = useState(false);

    return (
        <div>
            {show ?
            <NewPost
                onSubmit={() => setShow(false)}
            />
            :
            <button
                className={formStyles.button}
                style={{width: "100%"}}
                onClick={() => setShow(true)}
                >
                    + Quick Post
                </button>
            }
        </div>
    );k
}

export default QuickPostButton;