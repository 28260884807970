import React, { useEffect } from "react";

function DanboAd(props) {
    return (null) // Danbo ads disabled.

    useEffect(() => {
        window.Danbo.dispatchEvent("DanboNewRenderEvent");
    }, []);

    return(
        <div
            className="danbo_dta"
            data-danbo="45-pol-0-300-250"
            // style={{width: "300px", height: "250px", border: "1px solid red"}}
        >
        </div>
    )
}

export default DanboAd;