import React from "react";
import FeedTopNav from "../social/nav/top/FeedTopNav";
import AnnouncementsPopup from "./AnnouncementsPopup";

function AnnouncementsContainer(props) {
    return(
        <div style={{display: "flex", flexDirection: "column"}}>
            <FeedTopNav />
            <AnnouncementsPopup />
        </div>
    );
}

export default AnnouncementsContainer;