import React from "react";
import messageStyles from "../../../../css/layout/chat//message-box.css";

export default function ChatProfilePicture(props){
    return(
        <div>
            <img className={props.reply ? messageStyles.chatProfilePicReply : messageStyles.chatProfilePic}
             src={props.picture_url} />
        </div>
    )
}