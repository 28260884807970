// @flow

import React, {useState, useRef, useEffect} from "react";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {isMobile} from "is-mobile";

import postStyles from "../../../../../../../css/components/post.css";
import formStyles from "../../../../../../../css/form.css";
import mobileOptionsStyles from "../../../../../../../css/components/mobile-options.css";
import layoutStyles from "../../../../../../../css/layout.css";

import ClockIcon from "../../../../../../../assets/svg/icons/clock.svg";
import ReblogIcon from "../../../../../../../assets/svg/icons/reblog.svg";
import CogIcon from "../../../../../../../assets/svg/icons/gear.svg";
import InfoIcon from "../../../../../../../assets/svg/icons/info.svg";
import EllipseVIcon from "../../../../../../../assets/svg/icons/ellipsisV.svg";

import ProfilePicture from "../../../user/ProfilePicture";
import ConfirmModal from "../../../ConfirmModal";
import Info from "./header/Info";
import ReportPost from "./header/ReportPost";
import InteractionsModal from "./header/InteractionsModal";
import VisibilityModal from "./header/VisibilityModal";
import ListModal from "./header/ListModal";
import CopyButton from "../../../../../../../util/components/CopyButton";

import {colorizeStyle} from "../../../../../../../util/colorizeStyle";
import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";
import outsideClick from "../../../../../../../util/components/outsideClick";
import {formatDate, timeAgoHR} from "../../../../../../../util/date";
import {PostType} from "../../../../../../../util/types/PostTypes";
import store from "../../../../../../../store";
import {deletePost, handleHidePost, pinPost} from "../../../../../../../reducers/social/actions";
import {API_URL, MAX_MOBILE_WIDTH, WEB_URL} from "../../../../../../../util/constants";

import { getToken } from "../../../../../../../reducers/auth/actions";

import history from "../../../../../../../util/history";
import { formatDistanceToNowStrict } from 'date-fns';
import Markers from "../../../markers/Markers";

function Header(props) {
    const {width} = useWindowDimensions();

    let baseUrl = WEB_URL;
    try{
        const url = new URL(WEB_URL);
        baseUrl = `${url.protocol}//${url.hostname}`; // This will remove the port
    } catch(e) {}

    let isReblogged = props.post.reblog !== null && (props.post.body === null && props.post.attachments.length === 0);

    let [manager, setManager] = useState({
        showOptions: false,
        showInfo: false,
        interactionsModal: false,
        visibilityModal: false,
        listModal: false,
        mobilePopup: false,
        confirm: {
            pinPost: false,
            unpinPost: false,
            deletePost: false,
            reportPost: false,
            promotePost: false,
        },
        suppressed: props.post.suppressed ? props.post.suppressed : false,
        hidden: props.post.filtered ? props.post.filtered : false,
        nsfw: props.post.nsfw ? props.post.nsfw : false,
    });

    useEffect(() => {
        setManager(manager => ({ ...manager, hidden: props.post.filtered }))
    }, [props.post.filtered])

    const optionsRef = useRef();
    const infoRef = useRef();

    function error() {
        const Notification = () => (
            <div>
                There was an error!
            </div>
        );

        toast.error(<Notification />, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handlePin() {
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + "/post/pin" + (!props.post.pinned ? "/" + props.post.id : ''),
            method: props.post.pinned ? 'delete' : 'post',
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            },
        }).then(res => {
            const Notification = () => (
                <div>
                    {props.post.pinned ? "Pinned" : "Unpinned"} post!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            store.dispatch(pinPost(props.post.id));
            handleConfirm("pinPost", false);
            handleConfirm("unpinPost", false);
        }).catch(err => {
            error();
        });
    }

    async function handleDelete() {
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + "/post/" + props.post.id,
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": await store.dispatch(getToken())
            }
        }).then(res => {
            const Notification = () => (
                <div>
                    Deleted post!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            store.dispatch(deletePost(props.post.id));
        }).catch(err => {
            (err)
            error();
        });
    }

    function handleConfirm(modal, status) {
        setManager(manager => ({
            ...manager,
            confirm: {
                ...manager.confirm,
                [modal]: status
            }
        }));
    }

    function handleCancelReport() {
        setManager(manager => ({
            ...manager,
            confirm: {
                ...manager.confirm,
                reportPost: false
            }
        }))
    }

    function handleVisibility() {
        setManager(manager => ({
            ...manager,
            visibilityModal: !manager.visibilityModal,
        }))
    }

    function handleList() {
        setManager(manager => ({
            ...manager,
            listModal: !manager.listModal,
        }))
    }

    function handleInteractions() {
        setManager(manager => ({
            ...manager,
            interactionsModal: !manager.interactionsModal,
        }))
    }

    function handleMobilePopup() {
        setManager(manager => ({
            ...manager,
            mobilePopup: !manager.mobilePopup,
        }))
    }

    function handleBlock(){
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + "/users/block/" + props.post.author.info.id,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            const Notification = () => (
                <div>
                    Blocked user!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            error();
        });
    }

    outsideClick(optionsRef, () => {
        if (manager.showOptions) {
            setManager(manager => ({
                ...manager,
                showOptions: false
            }));
        }
    });

    outsideClick(infoRef, () => {
        if (manager.showInfo) {
            setManager(manager => ({
                ...manager,
                showInfo: false
            }));
        }
    });

    // NOTE: Something is re-rendering this component with raw post data.
    let username = ""
    try{
        username = props.post.author.info.username.length > 16
        ? props.post.author.info.username.substr(0, 16) + "..." : props.post.author.info.username;
    } catch(e) {
        return null
    }

    function handleClick(e){
        return // TODO: Temporarily disabled, add to settings.
        if(isMobile()) return;
        if(e.target.nodeName.toLowerCase() != "div") return
        history.push(`/post/${props.post.id}`)
    }

    function handleSuppress(){
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + `/moderation/${manager.suppressed ? "unsuppress" : "suppress"}/post/` + props.post.id,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                suppressed: !manager.suppressed
            }));
            const Notification = () => (
                <div>
                    {manager.suppressed ? "Unsuppressed" : "Suppressed"} post!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    function handleMarkNSFW(){
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + `/moderation/setNSFW/` + props.post.id,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            },
            data: {
                mark: !manager.nsfw
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                nsfw: !manager.nsfw
            }));
            const Notification = () => (
                <div>
                    {manager.nsfw ? "Unmarked" : "Marked"} post as NSFW!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    
    function hidePost(){
        if(!props.auth.isAuthenticated) return;

        setManager(manager => ({
            ...manager,
            hidden: true
        }));

        store.dispatch(handleHidePost(props.post.id, true))

        axios.post(API_URL + "/@me/filter-post/" + props.post.id, {}, {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            const Notification = () => (
                <div>
                    Post hidden!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    function unhidePost(){
        if(!props.auth.isAuthenticated) return;

        setManager(manager => ({
            ...manager,
            hidden: false
        }));

        axios.delete(API_URL + "/@me/filter-post/" + props.post.id, {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            store.dispatch(handleHidePost(props.post.id, false))

            const Notification = () => (
                <div>
                    Post revealed!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    function handleModDelete(){
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + "/moderation/markDeletePost/" + props.post.id,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            const Notification = () => (
                <div>
                    Deleted post!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            store.dispatch(deletePost(props.post.id));
        }).catch(err => {
            (err)
            error();
        });
    }

    function handlePromote(){
        if(!props.auth.isAuthenticated) return;

        if(!manager.confirm.promotePost){
            setManager(manager => ({
                ...manager,
                confirm: {
                    ...manager.confirm,
                    promotePost: true
                }
            }));
        } else{
            setManager(manager => ({
                ...manager,
                confirm: {
                    ...manager.confirm,
                    promotePost: false
                }
            }));

            axios({
                url: API_URL + "/post/promote/" + props.post.id,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Auth-Token": store.dispatch(getToken())
                }
            }).then(res => {
                const Notification = () => (
                    <div>
                        Promoted post!
                    </div>
                );

                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }).catch(err => {
                setManager(manager => ({
                    ...manager,
                    confirm: {
                        ...manager.confirm,
                        promotePost: false
                    }
                }));
                console.log(err)
                error();
            });
        }
    }

    function handleUnpromote(){
        if(!props.auth.isAuthenticated) return;

        setManager(manager => ({
            ...manager,
            confirm: {
                ...manager.confirm,
                promotePost: false
            }
        }));

        axios({
            url: API_URL + "/post/promote/" + props.post.id,
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            const Notification = () => (
                <div>
                    Unpromoted post!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            setManager(manager => ({
                ...manager,
                confirm: {
                    ...manager.confirm,
                    promotePost: false
                }
            }));
            console.log(err)
            error();
        });
    }

    function closePromoteModal(){
        setManager(manager => ({
            ...manager,
            confirm: {
                ...manager.confirm,
                promoteFetch: false,
                promotePost: false,
                unpromotePost: false
            }
        }));
    }

    return (
        <div className={postStyles.header}
        onClick={(e) => {handleClick(e)}}>
            {manager.confirm.promotePost && props.post.promoted &&
                <ConfirmModal header="Remove promotion" body="Stop promoting this post. Spent TibCoinz will not be refunded, or however this will be decided on idk."
                              cancel={() => {closePromoteModal()}}
                              callback={() => {handleUnpromote()}} />
            }
            {manager.confirm.promotePost && !props.post.promoted &&
                <ConfirmModal header="Promote post" body="This post will be promoted across the platform. This will deduct 69 TibCoinz from your account."
                              cancel={() => {closePromoteModal()}}
                              callback={() => {handlePromote()}} />
            }

            {
                manager.confirm.pinPost &&
                <ConfirmModal header="Pin post" body="By pinning this post it will remove any previous pinned post and make the post public if it is private. Please confirm"
                              cancel={() => { handleConfirm("pinPost", false) }}
                              callback={handlePin} />
            }
            {
                manager.confirm.unpinPost &&
                <ConfirmModal header="Unpin post" body="Do you wish to unpin this post? Please confirm"
                              cancel={() => { handleConfirm("unpinPost", false) }}
                              callback={handlePin} />
            }
            {
                manager.confirm.deletePost &&
                <ConfirmModal header="Delete post" body="You cannot recover a deleted post. It will be completely removed from Tiblur. Please confirm"
                              cancel={() => { handleConfirm("deletePost", false) }}
                              callback={handleDelete} />
            }
            {
                manager.confirm.reportPost &&
                <ReportPost type={props.isOnPost ? "post" : "user"} post={props.post} cancel={handleCancelReport} />
            }
            {
                manager.visibilityModal &&
                <VisibilityModal post={props.post} close={handleVisibility} />
            }
            {
                manager.listModal &&
                <ListModal post={props.post} close={handleList} />
            }
            {
                manager.interactionsModal &&
                <InteractionsModal post={props.post} close={handleInteractions} />
            }
            {
                isReblogged === false &&
                <ProfilePicture profilePreview={true}
                                user={props.post.author}
                                small={width <= MAX_MOBILE_WIDTH}
                                popup={true} />
            }
            {
                isReblogged === true &&
                <ProfilePicture profilePreview={true}
                                user={props.post.reblog.author}
                                small={width <= MAX_MOBILE_WIDTH}
                                popup={true} />
            }
            <div style={{display: "flex", flexDirection: "column", marginLeft: "20px"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Link to={`/user/${props.post.author.info.username}`}>
                        <h4 style={{margin: "0", color: props.post.author.info.username_color}}>
                            {username}
                        </h4>
                    </Link>
                    <Markers user={props.post.author} onProfile={false}/>
                </div>
            {
                <span style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "5px"}}>
                    <ClockIcon width="15" height="15" />
                    {formatDistanceToNowStrict(new Date(props.post.timestamp), { addSuffix: true })}
                </span>
            }
            </div>
            {
                isReblogged === true &&
                <div className={postStyles.reblog}>
                    <ReblogIcon height={18} />
                    <Link to={`/user/${props.post.reblog.author.info.username}`}>
                        <h4 style={colorizeStyle(props.post.reblog.author.info.username_color)}>
                            {props.post.reblog.author.info.username}
                        </h4>
                    </Link>
                </div>
            }
            {
                manager.showInfo && <Info post={props.post} ref={infoRef} />
            }
            {
                props.auth.isAuthenticated && !isMobile() &&
                ((props.auth.user != null && props.post.author.info.id !== props.auth.user.info.id)) &&
                !props.previewProfile
                &&
                <div style={{marginLeft: "auto"}} className={postStyles.options} onClick={() => { setManager(manager => ({ ...manager, showOptions: !manager.showOptions })) }}>
                    <EllipseVIcon height={16} width={16} />
                    {
                        manager.showOptions &&
                        <div className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight} ref={optionsRef} style={{right: "10px", maxHeight: "unset"}}>
                            <div className={formStyles.dropdownItem} onClick={() => history.push(`/post/${props.post.id}`)}>
                                <li>
                                    Open post
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={handleList}>
                                <li>
                                    Add to list
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={() => { handleConfirm("reportPost", true) }}>
                                <li>
                                    Report
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={() => { handleBlock() }}>
                                <li>
                                    Block author
                                </li>
                            </div>
                            {!manager.hidden ?
                            <div className={formStyles.dropdownItem} onClick={() => { hidePost() }}>
                                <li>
                                    Hide post
                                </li>
                            </div>
                            :
                            <div className={formStyles.dropdownItem} onClick={() => { unhidePost() }}>
                                <li>
                                    Reveal post
                                </li>
                            </div>
                            }

                            {props.auth.user.info.isModerator &&
                            <div className={formStyles.dropdownItem} onClick={() => { handleModDelete() }}>
                                <li>
                                    [MOD] Delete
                                </li>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.suppressed == false &&
                            <div className={formStyles.dropdownItem} onClick={() => { handleSuppress() }}>
                                <li>
                                    [MOD] Suppress
                                </li>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.suppressed == true &&
                            <div className={formStyles.dropdownItem} onClick={() => { handleSuppress() }}>
                                <li>
                                    [MOD] Unsuppress
                                </li>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.nsfw == false &&
                            <div className={formStyles.dropdownItem} onClick={() => { handleMarkNSFW() }}>
                                <li>
                                    [MOD] Mark NSFW
                                </li>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.nsfw == true &&
                            <div className={formStyles.dropdownItem} onClick={() => { handleMarkNSFW() }}>
                                <li>
                                    [MOD] Mark non-NSFW
                                </li>
                            </div>
                            }
                        </div>
                    }
                </div>
            }
            {
                props.auth.isAuthenticated && !isMobile() &&
                ((props.auth.user != null && props.post.author.info.id === props.auth.user.info.id) &&
                (isReblogged === false))
                && !props.previewProfile
                &&
                <div style={{marginLeft: "auto"}} className={postStyles.options} onClick={() => { setManager(manager => ({ ...manager, showOptions: !manager.showOptions })) }}>
                    <CogIcon height={16} width={16}/>
                    {
                        manager.showOptions &&
                        <div className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight} ref={optionsRef} style={{right: "10px", maxHeight: "unset"}}>
                            <div className={formStyles.dropdownItem} onClick={() => history.push(`/post/${props.post.id}`)}>
                                <li>
                                    Open post
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={() => handlePromote()}>
                                <li>
                                    Manage Promotion
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={handleList}>
                                <li>
                                    Add to list
                                </li>
                            </div>
                            {
                                !props.post.pinned &&
                                <div className={formStyles.dropdownItem} onClick={() => { handleConfirm("pinPost", true) }}>
                                    <li>
                                        Pin
                                    </li>
                                </div>
                            }
                            {
                                props.post.pinned &&
                                <div className={formStyles.dropdownItem} onClick={() => { handleConfirm("unpinPost", true) }}>
                                    <li>
                                        Unpin
                                    </li>
                                </div>
                            }
                            <div className={formStyles.dropdownItem} onClick={handleVisibility}>
                                <li>
                                    Visibility
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={handleInteractions}>
                                <li>
                                    Interactions
                                </li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={() => { handleConfirm("deletePost", true) }}>
                                <li>
                                    Delete
                                </li>
                            </div>
                        </div>
                    }
                </div>
            }
            {
                isMobile() && props.auth.isAuthenticated &&
                <div className={postStyles.mobileOptions} onClick={handleMobilePopup}>
                    <EllipseVIcon height={16} width={16} />
                </div>
            }
            {
                manager.mobilePopup &&
                <div className={mobileOptionsStyles.container} onClick={handleMobilePopup}>
                    <div className={mobileOptionsStyles.options}>
                        <div className={mobileOptionsStyles.optionGroup}>
                            <div className={mobileOptionsStyles.info}>
                                <span className={mobileOptionsStyles.date}><ClockIcon width="15" height="15" />{formatDate(props.post.timestamp)}</span>
                            </div>
                        </div>
                        <div className={mobileOptionsStyles.optionGroup}>
                            <div className={mobileOptionsStyles.option}>
                                <Link className={formStyles.button} to={"/post/" + props.post.id}>
                                    Open Post
                                </Link>
                            </div>
                            <div className={mobileOptionsStyles.option}>
                                <CopyButton className={formStyles.button + ' ' + layoutStyles.wF} copyText={baseUrl + "/post/" + props.post.id}>
                                    Copy Link
                                </CopyButton>
                            </div>
                            <div className={mobileOptionsStyles.option} onClick={handleList}>
                                <p className={formStyles.button}>Add to list</p>
                            </div>
                            {props.post.author.info.id !== props.auth.user.info.id &&
                            <div className={mobileOptionsStyles.option}
                                    onClick={() => { handleConfirm("reportPost", true) }}>
                                <p className={formStyles.button}>Report</p>
                            </div>
                            }
                            {props.post.author.info.id !== props.auth.user.info.id &&
                            <div className={mobileOptionsStyles.option}
                                    onClick={() => { handleBlock() }}>
                                <p className={formStyles.button}>Block author</p>
                            </div>
                            }
                            {props.post.author.info.id !== props.auth.user.info.id && !manager.hidden &&
                                <div className={mobileOptionsStyles.option}
                                        onClick={() => { hidePost() }}>
                                    <p className={formStyles.button}>Hide post</p>
                                </div>
                            }
                            {props.post.author.info.id !== props.auth.user.info.id && manager.hidden &&
                                <div className={mobileOptionsStyles.option}
                                        onClick={() => { unhidePost() }}>
                                    <p className={formStyles.button}>Reveal post</p>
                                </div>
                            }
                            {props.auth.user.info.isModerator && props.post.author.info.id !== props.auth.user.info.id &&
                            <div className={mobileOptionsStyles.option} onClick={() => { handleModDelete() }}>
                                <p className={formStyles.button}>
                                    [MOD] Delete
                                </p>
                            </div>}
                            {props.auth.user.info.isModerator && manager.suppressed == false &&
                            <div className={mobileOptionsStyles.option} onClick={() => { handleSuppress() }}>
                                <p className={formStyles.button}>
                                    [MOD] Suppress
                                </p>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.suppressed == true &&
                            <div className={mobileOptionsStyles.option} onClick={() => { handleSuppress() }}>
                                <p className={formStyles.button}>
                                    [MOD] Unsuppress
                                </p>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.nsfw == false &&
                            <div className={mobileOptionsStyles.option} onClick={() => { handleMarkNSFW() }}>
                                <p className={formStyles.button}>
                                    [MOD] Mark NSFW
                                </p>
                            </div>
                            }
                            {props.auth.user.info.isModerator && manager.nsfw == true &&
                            <div className={mobileOptionsStyles.option} onClick={() => { handleMarkNSFW() }}>
                                <p className={formStyles.button}>
                                    [MOD] Mark non-NSFW
                                </p>
                            </div>
                            }
                        </div>
                        {
                            props.auth.isAuthenticated &&
                            ((props.post.author.info.id === props.auth.user.info.id) && (isReblogged === false))
                            && !props.previewProfile
                            &&
                            <div className={mobileOptionsStyles.optionGroup}>
                                {
                                    !props.post.pinned &&
                                    <div className={mobileOptionsStyles.option} onClick={() => { handleConfirm("pinPost", true) }}>
                                        <p>
                                            Pin
                                        </p>
                                    </div>
                                }
                                {
                                    props.post.pinned &&
                                    <div className={mobileOptionsStyles.option} onClick={() => { handleConfirm("unpinPost", true) }}>
                                        <p>
                                            Unpin
                                        </p>
                                    </div>
                                }
                                <div className={mobileOptionsStyles.option} onClick={handleVisibility}>
                                    <p>
                                        Visibility
                                    </p>
                                </div>
                                <div className={mobileOptionsStyles.option} onClick={handleInteractions}>
                                    <p>
                                        Interactions
                                    </p>
                                </div>
                                <div className={mobileOptionsStyles.option + ' ' + mobileOptionsStyles.danger}
                                     onClick={() => { handleConfirm("deletePost", true) }}>
                                    <p>
                                        Delete
                                    </p>
                                </div>
                            </div>
                        }
                        <div className={mobileOptionsStyles.optionGroup}>
                            <div className={mobileOptionsStyles.option}>
                                <p>Cancel</p>
                            </div>
                        </div>
                    </div>

                </div>
            }
            <Link className={postStyles.postLink} to={`/post/${props.post.id}`}/>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

Header.propTypes = {
    post: PostType,
    previewProfile: PropTypes.bool,
}

Header.defaultProps = {
    previewProfile: false,
}

export default connect(mapStateToProps)(Header);
