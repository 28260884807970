// @flow

import React, { useRef, useState, useEffect, Suspense } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import axios from "axios";
const Picker = React.lazy(
    () => import('emoji-mart').then(module => ({ default: module.Picker }))
);
import {toast} from "react-toastify";
import ReactTooltip from "react-tooltip";
import 'emoji-mart/css/emoji-mart.css'
import {isMobile} from "is-mobile";

import formStyles, { button, buttonIcon } from "../../../../../../../../css/form.css";
import mBoxStyles, {
  replyContainer,
  box,
  textContainer,
  textInput,
  buttonOption,
  replyTo
} from "../../../../../../../../css/layout/chat/message-box.css";

import SendIcon from "../../../../../../../../assets/svg/icons/send.svg";
import EmojiIcon from "../../../../../../../../assets/svg/icons/smile.svg";
import EmojiSheet from "../../../../../../../../assets/emojis/sheet/64.png";
import FolderIcon from "../../../../../../../../assets/svg/icons/folder.svg";


import { API_URL } from "../../../../../../../../util/constants";
import outsideClick from "../../../../../../../../util/components/outsideClick";
import {ReplyType} from "../../../../../../../../util/types/PostTypes";
import fetchCaptcha from "../../../../../../../../util/captcha";
import modalStyles from "../../../../../../../../css/components/modal.css";
import layoutStyles, {mL} from "../../../../../../../../css/layout.css";
import ContentLoader from "../../../../../../../../util/components/ContentLoader";
import {CONTAINER, LAYER, useLayerDispatch} from "../../../../../layer/context";
import Upgrade from "../../../../../../pages/settings/Upgrade";
import Container from "../../../../settings/Container";
import store from "../../../../../../../../store";
import { getToken } from "../../../../../../../../reducers/auth/actions";

function ReplyForm(props) {
  let post = props.post.reblog !== null && (props.post.body === null && props.post.attachments.length === 0)
      ? props.post.reblog : props.post;

  let ref = useRef();
  let containerRef = useRef();
  const _isMounted = useRef(true);
  const securityCodeRef = useRef();

  const dispatchLayer = useLayerDispatch();

  let [manager, setManager] = useState({
    input: "",
    sending: false,
    showEmojis: false,
    caretPos: 0,
    securityCode: "",
    showCaptcha: false,
    captchaURL: null,
    captchaId: null,
    captchaLoading: true,
    error: null,
    isBoosted: false,
  });

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, []);

  useEffect(() => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Auth-Token": store.dispatch(getToken())
        }
    };

    axios.get(API_URL + '/@me/checkboost', config)
        .then(function (res) {
            setManager(manager => ({
                ...manager,
                isBoosted: res.data.data.boosted,
            }));
        })
        .catch(function (err) {
            setManager(manager => ({
                ...manager,
                isBoosted: false
            }));
        });
}, []);

  useEffect(() => {
    if(securityCodeRef.current){
      securityCodeRef.current.focus();
    }
  }, [securityCodeRef.current]);

  useEffect(() => {
    if(ref.current.value !== undefined && manager.input.length > 0){
      ref.current.setSelectionRange(manager.caretPos, manager.caretPos);
      ref.current.focus();
    }
  }, [manager.caretPos]);

  useEffect(() => {
    if(props.replyTo !== null){
      let {input} = manager;

      input = props.prependTag + input;

      setManager(manager => ({
        ...manager,
        input
      }))

      ref.current.focus();
    }
  }, [props.replyTo]);

  function isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
  }

  async function sendContentMessage() {
    if(isEmptyOrSpaces(manager.input) && attachment === null){
      ref.current.focus();
      return;
    }

    if(manager.sending){
      return;
    }

    if(manager.isBoosted == false && manager.securityCode.length === 0){
      setManager(manager => ({
        ...manager,
        showCaptcha: true,
      }))

      loadCaptcha();
      return;
    }

    const Notification = () => (
      <div>
          Sending reply...
      </div>
    );

    toast(<Notification />, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    setManager(manager => ({
      ...manager,
      sending: true
    }))

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Auth-Token": store.dispatch(getToken())
      }
    };

    let parent = props.replyTo === null || props.replyTo === undefined ? null : props.replyTo.id;

    let data = new FormData();

    if(attachment){
      let content = attachment;
      if(["image/png", "image/jpg", "image/jpeg"].includes(content.type)){
        let image = new Image();
        image.src = URL.createObjectURL(content);

        const originalSizeMB = content.size/1000/1000;
        let quality = 0.95;
        if(originalSizeMB > 0.3){
            quality = 0.9;
        }
        if(originalSizeMB > 0.8){
            quality = 0.85;
        }
        if(originalSizeMB > 1.5){
            quality = 0.8;
        }

        content = await new Promise((resolve, reject) => {
            image.onload = function() {
                let canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                let ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, image.width, image.height);

                canvas.toBlob(function(blob) {
                    let newFile = new File([blob], content.name, {
                        type: "image/webp",
                    });

                    resolve(newFile);
                }, 'image/webp', quality);
            }

            image.onerror = reject;
        });

        try{
          let name = content.name.split('.').slice(0, -1).join('.');
          content = new File([content], name + '.webp', {type: "image/webp"});
        } catch (e) {
          console.log(e)
        }
      }

      data.append('attachment', content);
    }

    data.append('body', manager.input);
    data.append('parent', parent);
    data.append('security_id', manager.captchaId);
    data.append('security_code', manager.securityCode);

    axios
        .post(API_URL + "/post/reply/" + post.id, data, config)
        .then(res => {
          setManager(manager => ({
            ...manager,
            input: "",
            error: null,
            showCaptcha: false,
            securityCode: "",
            sending: false,
          }));

          const Notification = () => (
            <div>
                Reply sent!
            </div>
          );

          toast(<Notification />, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });

          props.callback(res.data.data.reply);
          props.cancelReply();
        
      })
        .catch(err => {
          if(err.response != null && err.response.status === 429 || err.response.status === 403){
            setManager({
              ...manager,
              error: err.response.data.message,
              showCaptcha: false,
              sending: false,
            });
            // return;
          }

          if(err.response && err.response.data.errors && err.response.data.errors.captcha){
            setManager(manager => ({ ...manager, sending: false, error: "WRONG_SECURITY_CODE", showCaptcha: true }));
            loadCaptcha();
            return;
          }

          let message = "There was an error!"
          try {
            message = err.response.data.error;
          } catch {}

          const Notification = () => (
              <div>
                {message}
              </div>
          );

          toast.error(<Notification />, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

    ref.current.focus();
    setAttachment(null);
  }

  async function handleSubmit(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();


      sendContentMessage();
    }
  }

  const getCaretPos = (ref) => {
    return ref.current.selectionStart;
  };

  function handleInput(e) {
    let value = e.target.value;

    setManager(manager => ({
      ...manager,
      input: value,
      caretPos: getCaretPos(ref),
    }));
  }

  function handleEmoji(emoji) {
    let pos = getCaretPos(ref);
    let {input} = manager;

    let shouldLPad = input.substring(0, pos).charAt(pos) !== " " && input.length > 0;
    let shouldRPad = input.charAt(pos) !== " " && input.length > 0;

    let update = input.substring(0, pos) + (shouldLPad ? " ": "") + emoji.native + (shouldRPad ? " ": "") + input.substring(pos);

    setManager(manager => ({
      ...manager,
      input: update,
      caretPos: pos + emoji.native.length + 1,
    }));
  }

  function loadCaptcha() {
    fetchCaptcha().then(data => {
      if(_isMounted.current) {
        setManager(manager => ({
          ...manager,
          captchaURL: data.captcha,
          captchaId: data.id,
        }))
      }
    });
  }

  function handleSecurityCode(e) {
    let value = e.target.value;

    setManager(manager => ({
      ...manager,
      securityCode: value
    }))
  }

  function handleUpgrade() {
    dispatchLayer({ type: LAYER, payload: <Upgrade /> });
    dispatchLayer({ type: CONTAINER, payload: <Container /> });
  }

  outsideClick(containerRef, () => {
    setManager({
      ...manager,
      showEmojis: false,
    });
  });

  const backgroundImageFn = () => EmojiSheet;

  const CaptchaPlaceholder = () => (
      <ContentLoader
          width="100%"
          height="135"
          viewBox="0 0 100 135"
          preserveAspectRatio="none"
      >
        <rect x="0" y="0" width="100%" height="135" />
      </ContentLoader>
  );

  const [attachment, setAttachment] = useState(null);

  function handleClickUploadAttachment() {
    let element = document.getElementById("reply-attachment")
    if (element) {
      element.remove()
    }
    const input = document.createElement('input', {id: "reply-attachment"});
    input.type = 'file';
    input.accept = 'image/*, video/*, .gif';
    input.click()
    input.onchange = async () => { 
      const file = input.files[0];
      setAttachment(file);
    }
  }

  return (
      <div className={box}>
        {
          manager.showCaptcha &&
          <div className={modalStyles.containerOuter + (isMobile() ? (" " + modalStyles.mobile) : "")}>
            <div className={modalStyles.wrapper}>
              <div className={modalStyles.containerInner}>
                <div className={modalStyles.modalContainer}>
                  <div className={modalStyles.modal}>
                    <div className={modalStyles.top}>
                      <div className={modalStyles.header}>
                        <h4>Complete captcha</h4>
                      </div>
                    </div>
                    <div className={modalStyles.body}>
                      <div className={formStyles.formGroup}>
                        {
                          manager.captchaURL &&
                          <img
                              alt="captcha"
                              className={layoutStyles.wF + ' ' + (manager.captchaLoading ? layoutStyles.hide : '')}
                              onLoad={() => { setManager(manager => ({ ...manager, captchaLoading: false })) }}
                              src={manager.captchaURL}
                          />
                        }
                        {
                          (!manager.captchaURL || manager.captchaLoading) && <CaptchaPlaceholder />
                        }
                      </div>
                      <div className={formStyles.formGroup}>
                        <div className={formStyles.alert}>
                          <div>
                            <p>Case insensitive.</p>
                            {!isMobile() && 
                            <p>You can <a onClick={handleUpgrade} className={layoutStyles.pointer}>upgrade your account</a> to remove captchas and ads.</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className={formStyles.formGroup}>
                        {
                          manager.error === "WRONG_SECURITY_CODE" && <label className={formStyles.invalidLabel}>Incorrect security code.</label>
                        }
                        <input
                            type="text"
                            ref={securityCodeRef}
                            onChange={handleSecurityCode}
                            onKeyPress={handleSubmit}
                            value={manager.securityCode}
                            maxLength={4}
                            className={
                              formStyles.input + ' ' +
                              (manager.error === "WRONG_SECURITY_CODE" ? formStyles.invalidInput : '')
                            }
                            placeholder="Security code"
                        />
                      </div>
                    </div>
                    <div className={modalStyles.footer}>
                      <button className={formStyles.button}
                              onClick={() => { setManager(manager => ({ ...manager, showCaptcha: false, securityCode: "", sending: false, })) }}>
                        Close
                      </button>
                      <button className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + mL}
                              onClick={sendContentMessage}>
                        Post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          props.replyTo !== null && props.replyTo !== undefined &&
          <div className={replyTo}>
            <small>Replying to {props.prependTag} <a onClick={props.cancelReply}>(cancel)</a></small>
          </div>
        }
        {
          manager.error !== "WRONG_SECURITY_CODE" &&
          <div className={mBoxStyles.errorMessage}>
            <small>{manager.error}</small>
          </div>
        }
        {attachment &&
          <div className={mBoxStyles.attachmentContainer}>
            <div className={mBoxStyles.attachment}>
              {attachment.type.includes("video") &&
                <video style={{borderRadius: "5px"}} height={"50px"} width={"50px"} src={URL.createObjectURL(attachment)} />
              }
              {attachment.type.includes("image") &&
                <img style={{borderRadius: "5px"}} height={"50px"} width={"50px"} src={URL.createObjectURL(attachment)} />
              }
            </div>
            {manager.sending ?
              <p>Uploading...</p>
            :
            <div className={mBoxStyles.attachmentName} style={{display: "flex"}}>
              <button style={{height: "25px", width: "25px"}} className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => setAttachment(null)}>X</button>
              {attachment.name.slice(0, 20) + (attachment.name.length > 20 ? "..." : "")}
            </div>
            }
          </div>
        }
        <div style={{display: "flex", alignItems: "center"}} className={replyContainer + ' ' + (manager.error !== null ? mBoxStyles.error : '')} ref={containerRef}>
          {/* <button
              className={button + " " + buttonOption + " " + buttonIcon}
              onClick={() => { setManager({ ...manager, showEmojis: !manager.showEmojis }) }}>
            <EmojiIcon height="75%" />
          </button> */}
          <button
            className={button + " " + buttonOption + " " + buttonIcon}
            onClick={() => handleClickUploadAttachment()}
            >
            <FolderIcon height="75%" />
          </button>
          {
            manager.showEmojis &&
            <div className={mBoxStyles.emojiContainer}>
              <Suspense fallback={<div className={layoutStyles.mT1}>Loading...</div>}>
                <Picker
                    title='emojis!'
                    emoji='point_up'
                    color='#7286e9'
                    set='twitter'
                    backgroundImageFn={backgroundImageFn}
                    i18n={{
                      categories: {
                        search: 'Search',
                        recent: 'Frequently Used',
                        smileys: 'Smileys',
                        people: 'People',
                        nature: 'Nature',
                        foods: 'Food',
                        activity: 'Activity',
                        places: 'Travel',
                        objects: 'Objects',
                        symbols: 'Symbols',
                        flags: 'Flags',
                        custom: 'Custom',
                      },
                    }}
                    onSelect={handleEmoji}/>
              </Suspense>
            </div>
          }
          
          <div className={textContainer}>
            <TextareaAutosize
                disabled={!props.auth.isAuthenticated}
                placeholder={!props.auth.isAuthenticated ? "Please login." : "Your comment..."}
                onKeyPress={handleSubmit}
                onChange={handleInput}
                maxRows={3}
                rows={1}
                maxLength={280}
                inputRef={ref}
                className={textInput}
                value={manager.input}
            />
          </div>
          <button
              disabled={!props.auth.isAuthenticated}
              onClick={sendContentMessage}
              className={button + " " + buttonOption + " " + buttonIcon}
          >
            <SendIcon height="50%" />
          </button>
        </div>
      </div>
  );
}

const mapStateToProps = state => {
  const { auth } = state;
  return { auth: auth };
};

ReplyForm.propTypes = {
  replyTo: ReplyType,
};

export default connect(mapStateToProps)(ReplyForm);
