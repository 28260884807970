import React from "react";

import {container} from "../../../css/layout/connection-error.css";

import tiblurLogo from "../../../assets/svg/tiblur-logo.png";
import AutoAuth from "../layout/AutoAuth";

function LoadUser() {
    return (
        <AutoAuth>
            <div className={container}>
                <img src={tiblurLogo} width={"75px"} height={"75px"} />
            </div>
        </AutoAuth>
    );
}

export default LoadUser;
