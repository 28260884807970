import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { connect, batch } from "react-redux";
import ChatProfilePicture from "./ChatProfilePicture";

import messageStyles from "../../../../css/layout/chat//message-box.css";
import formStyles from "../../../../css/form.css";

import optionStyles from "./Options.css"

import Attachment from "./Attachment";

import { formatDistanceToNowStrict } from "date-fns";

import EllipsisHIcon from "../../../../assets/svg/icons/ellipsisH.svg";
import ReplyIcon from "../../../../assets/svg/icons/share.svg";

import outsideClick from "../../../../util/components/outsideClick";
import useLongPress from "../../../../util/hooks/useLongPress";

import isMobile from "is-mobile";

function Message(props){
    const mobile = useMemo(() => isMobile(), []);
    const [message, setMessage] = useState(() => props.message);
    const [messageEdited, setMessageEdited] = useState(() => props.edited);
    const [parentContent, setParentContent] = useState({
        message: null,
        picture_url: null,
    });

    const [uiState, setUiState] = useState({
        showOptions: false,
        showOptionsIcon: false,
        editingMessage: false,
        replying: false
    });

    const optionsRef = useRef(null);
    const editingTextAreaRef = useRef(null);

    const localAdjustEditTextHeight = useCallback(() => {
        if (editingTextAreaRef.current) {
            props.adjustEditTextHeight(editingTextAreaRef.current);
        }
    }, []);

    const localHandleReplyJump = useCallback(() => {
        props.handleReplyJump(props.parent);
    }, [props.parent]);

    function localHandleMessageEdit() {
        setUiState(prev => ({ ...prev, editingMessage: false }));
        const editedMessage = editingTextAreaRef.current.value;
        props.handleMessageEdit(props.id, editedMessage, setMessage, setMessageEdited);
    }

    function localHandleReply(){
        setUiState(prev => ({ ...prev, showOptions: false, replying: true }))
        props.handleReply(props.id)
    }

    const handleShowMessageEdit = useCallback(() => {
        setUiState(prev => ({ ...prev, showOptions: false, editingMessage: true }));
    }, []);

    const handleOptions = useCallback(() => {
        if(props.chat.replyingTo == null) {
            setUiState(prev => ({ ...prev, showOptions: !prev.showOptions }));
        }
    }, [props.chat.replyingTo]);

    const handleOutsideClick = useCallback(() => {
        setUiState(prev => ({ ...prev, showOptions: false, editingMessage: false }));
    }, []);

    function handleDelete(){
        props.handleDelete(props.id)
        setUiState(prev => ({ ...prev, showOptions: false }));
    }

    const longPressEvents = useLongPress(() => {
        handleOptions()
    }, 500);

    useEffect(() => {
        if(uiState.replying && props.chat.replyingTo == null) {
            setUiState(prev => ({ ...prev, replying: false }));
        }
    }, [props.chat.replyingTo]);

    outsideClick(optionsRef, handleOutsideClick);
    outsideClick(editingTextAreaRef, handleOutsideClick);

    useEffect(() => {
        if (props.parent) {
            const parentMessage = props.chat.history.find(message => message.id === props.parent);
            if (parentMessage) {
                if(parentMessage.content == "" && parentMessage.attachments) {
                    parentMessage.content = "(Attachement)"
                }
                setParentContent(({message: props.shortenText(parentMessage.content), picture_url: parentMessage.picture_url }));
            } else {
                props.handleParentContent(props.parent, setParentContent);
            }
        } else {
            setParentContent({ message: null, picture_url: null });
        }
    }, []);

    if(!props.bubblesLayout && !mobile) { 
        return (
            <>
            <div className={!props.mine && props.parent ? messageStyles.messageHiglightReply : ""}>
                {parentContent.message &&
                <div onClick={localHandleReplyJump} className={messageStyles.messageReplyContainer}
                    style={{ marginLeft: !props.mine && props.parent ? "36px" : "39px" }}
                >
                    <ReplyIcon className={messageStyles.smallReplyIcon} />
                    <div><ChatProfilePicture reply={true} picture_url={parentContent.picture_url} /></div>
                    <div className={messageStyles.messageReplyUsername} >{props.replyUsername}</div>
                    <div>{parentContent.message}</div>
                </div>
                }
                <div 
                    id={props.temp_id ? props.temp_id : props.id}
                    className={`${messageStyles.leftMessageContainer} ${uiState.showOptions || uiState.replying ? messageStyles.highlightedChatMessage : ''}`}
                    onMouseEnter={() => setUiState(prev => ({ ...prev, showOptionsIcon: true }))}
                    onMouseLeave={() => setUiState(prev => ({ ...prev, showOptionsIcon: false }))} >
                    <div style={{ marginLeft: !props.mine && props.parent ? "15px" : "18px" }}>
                        {!props.showProfilePicture ? (
                            <div className={messageStyles.compactProfilePicPlaceholderDiv}>
                                {uiState.showOptionsIcon && 
                                    <p className={messageStyles.dmMessageDate}>{formatDistanceToNowStrict(props.timestamp, { addSuffix: true })}</p>
                                }
                            </div>
                        ) : (
                            <ChatProfilePicture picture_url={props.picture_url} />
                        )}
                    </div>
                    <div className={messageStyles.innerCompactMessageContainer}>
                        <div>
                            {props.showProfilePicture && (
                                <span className={messageStyles.compactProfilePicSpan}>
                                    <h4>{props.user.info.username}</h4>
                                    {props.timestamp != undefined && (
                                        <h6 className={messageStyles.miscMessagesColor}>{formatDistanceToNowStrict(props.timestamp, { addSuffix: true })}</h6>
                                    )}
                                </span>
                            )}
                            {props.message != "" && 
                                <div className={`${messageStyles.compactMessageContainer} ${props.mine && props.failed && messageStyles.compactFailed}`}>
                                    {props.deleted ? (
                                        <p className={messageStyles.miscMessagesColor}>*Message deleted*</p>
                                    ) : (
                                        uiState.editingMessage ? 
                                            <>
                                                <textarea
                                                    className={messageStyles.editTextArea}
                                                    ref={editingTextAreaRef}
                                                    autoFocus={true}
                                                    defaultValue={`${message}`}
                                                    onFocus={(e) =>
                                                        e.currentTarget.setSelectionRange(
                                                        e.currentTarget.value.length,
                                                        e.currentTarget.value.length
                                                        )
                                                    }
                                                    onInput={localAdjustEditTextHeight}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            localHandleMessageEdit();
                                                        }
                                                    }}
                                                />
                                                <h6 className={messageStyles.editTextAreaInfo}>enter to save</h6>
                                            </>
                                        : 
                                            <>
                                            <p>{message}</p>
                                            {messageEdited ? <h6 className={messageStyles.miscMessagesColor}>edited</h6> : null}
                                            </>
                                    )}
                                </div>
                            }
                            {props.attachments && props.attachments.length > 0 && (
                                <div className={messageStyles.compactAttachementDiv}>
                                    <Attachment url={props.attachments[0].file.url} />
                                </div>
                            )}
                        </div>
                        {!props.deleted && (
                            <div onClick={handleOptions} className={`${optionStyles.leftLayoutEllipsis} ${uiState.showOptionsIcon ? optionStyles.leftLayoutOptionsVisible : ''}`}>
                                {props.mine ? 
                                <EllipsisHIcon className={optionStyles.Options} />                            
                                :
                                <ReplyIcon className={optionStyles.replyIcon} />                            
                                }

                            </div>
                        )}
                    </div>
                    {(uiState.showOptions && props.mine) && (
                        <div ref={optionsRef} className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight}>
                            <div className={formStyles.dropdownItem} onClick={handleDelete}>
                                <li>Delete message</li>
                            </div>
                            <div className={formStyles.dropdownItem} onClick={handleShowMessageEdit}>
                                <li>Edit message</li>
                            </div>
                        </div>
                    )}
                    {(uiState.showOptions && !props.mine) && (
                        <div ref={optionsRef} className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight}>
                            <div className={formStyles.dropdownItem} onClick={localHandleReply}>
                                <li>Reply</li>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </>
        )
    } else {
        return (
            <div className={!props.mine && props.parent ? messageStyles.messageHiglightReply : ""}>
                {parentContent.message &&
                <div onClick={localHandleReplyJump} className={props.mine ? messageStyles.messageReplyContainerBubbles : messageStyles.messageReplyContainer}>
                    {props.mine && 
                    <>
                        <div>{parentContent.message}</div>
                        <div className={messageStyles.messageReplyUsername} >{props.replyUsername}</div>
                        <div><ChatProfilePicture reply={true} picture_url={parentContent.picture_url} /></div>
                        <ReplyIcon className={messageStyles.smallReplyIconBubbles} />                
                    </>
                    }
                    {!props.mine &&
                    <>
                        <ReplyIcon className={messageStyles.smallReplyIcon} />
                        <div><ChatProfilePicture reply={true} picture_url={parentContent.picture_url} /></div>
                        <div className={messageStyles.messageReplyUsername} >{props.replyUsername}</div>
                        <div>{parentContent.message}</div>                
                    </>
                    }
                </div>
                }
                <div {...((mobile && !props.deleted) ? longPressEvents : {})} >
                        {props.mine &&
                        <div
                            id={props.temp_id ? props.temp_id : props.id}
                            className={`${uiState.showOptions ? messageStyles.highlightedChatMessage : ''} ${messageStyles.bubblesDivContainer} `}
                        >
                            {(uiState.showOptions && !mobile) &&
                                <div ref={optionsRef} className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight}>
                                    <div className={formStyles.dropdownItem} onClick={handleDelete}>
                                        <li>Delete message</li>
                                    </div>
                                    <div className={formStyles.dropdownItem} onClick={handleShowMessageEdit}>
                                        <li>Edit message</li>
                                    </div>
                                </div>
                            }
                            {(uiState.showOptions && mobile) &&
                                <div ref={optionsRef} style={{height: "15vh"}} className={formStyles.mobileMessageOptionsMenu}>
                                    <div className={formStyles.mobileMessageOptionsMenuItem} onClick={handleDelete}>
                                        <li>Delete message</li>
                                    </div>
                                    <div className={formStyles.mobileMessageOptionsMenuItem} onClick={handleShowMessageEdit}>
                                        <li>Edit message</li>
                                    </div>
                                </div>
                            }                        
                            <div className={messageStyles.bubblesMessageContainer}>
                                <span className={messageStyles.bubblesMessageContainerInner}>
                                    {!props.deleted && !mobile &&
                                        <div onClick={handleOptions}>
                                            <EllipsisHIcon className={optionStyles.Options} />
                                        </div>
                                    }
                                    {props.timestamp != undefined &&
                                        <>
                                        <h6 className={messageStyles.miscMessagesColor}>{formatDistanceToNowStrict(props.timestamp, { addSuffix: true })}</h6>
                                        {messageEdited ? <h6 className={messageStyles.miscMessagesColor}>*edited</h6> : null}
                                        </>
                                    }
                                    <h4>{props.user.info.username}</h4>
                                </span>
                                {props.message != "" &&
                                <div                         
                                className={`${messageStyles.container} ${props.sent && messageStyles.sentMine} ${props.failed && messageStyles.failed}`}>
                                    {props.deleted ?
                                        <p className={messageStyles.bubblesMessageDeleted}>*Message deleted*</p>
                                    :
                                        uiState.editingMessage ? 
                                        <>
                                            <textarea
                                                className={messageStyles.editTextAreaBubbles}
                                                ref={editingTextAreaRef}
                                                autoFocus={true}
                                                defaultValue={`${message}`}
                                                onFocus={(e) =>
                                                    e.currentTarget.setSelectionRange(
                                                    e.currentTarget.value.length,
                                                    e.currentTarget.value.length
                                                    )
                                                }
                                                onInput={localAdjustEditTextHeight}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        localHandleMessageEdit();
                                                    }
                                                }}
                                            />
                                            <h6 className={messageStyles.editTextAreaInfo}>enter to save</h6>
                                        </>
                                    :
                                        <p className={messageStyles.bubblesMessageColor}>{message}</p>
                                    }
                                </div>
                                }
                                {props.attachments && props.attachments.length > 0 &&
                                <div className={mobile ? messageStyles.bubblesAttachementDivMobile : messageStyles.bubblesAttachementDiv}>
                                    <Attachment mine={props.mine} url={props.attachments[0].file.url} />
                                </div>
                                }
                            </div>
                            <div className={messageStyles.bubblesMine}>
                                <ChatProfilePicture picture_url={props.picture_url} />
                            </div>
                        </div>
                        }
        
                        {!props.mine &&
                        <div 
                            id={props.temp_id ? props.temp_id : props.id}  
                            className={`${uiState.showOptions || uiState.replying ? messageStyles.highlightedChatMessage : ''} ${messageStyles.bubblesReceivedDivContainer}`}
                        > 
                            <div style={{ marginLeft: props.parent ? "15px" : "18px" }}>
                                <ChatProfilePicture picture_url={props.picture_url} />
                            </div>
                            <div className={messageStyles.bubblesReceivedMessageContainer}>
                                <span className={messageStyles.bubblesInnerReceivedMessageContainer}>
                                    <h4>{props.user.info.username}</h4>
                                    {props.timestamp != undefined &&
                                        <>
                                        <h6 className={messageStyles.miscMessagesColor}>{formatDistanceToNowStrict(props.timestamp, { addSuffix: true })}</h6>
                                        {messageEdited ? <h6 className={messageStyles.miscMessagesColor}>*edited</h6> : null}
                                        </>
                                    }
                                    {!props.deleted && !mobile &&
                                        <div onClick={handleOptions}>
                                            <EllipsisHIcon className={optionStyles.Options} />
                                        </div>
                                    }                                
                                </span>
                                {props.message != "" &&
                                <div className={`${messageStyles.container} ${messageStyles.sent}`}>
                                    {props.deleted ?
                                    <p className={messageStyles.bubblesMessageDeleted}>*Message deleted*</p>
                                    :
                                    <p>{message}</p>
                                    }
                                </div>
                                }
                                {props.attachments && props.attachments.length > 0 &&
                                <div className={mobile ? messageStyles.bubblesAttachementDivMobile : messageStyles.bubblesAttachementDiv}>
                                    <Attachment mine={props.mine} url={props.attachments[0].file.url} />
                                </div>
                                }
                            </div>
                            {(uiState.showOptions && !mobile) &&
                                <div ref={optionsRef} className={formStyles.dropdownMenuBubblesNotMine}>
                                    <div className={formStyles.dropdownItem} onClick={localHandleReply}>
                                        <li>Reply</li>
                                    </div>
                                </div>
                            }
                            {(uiState.showOptions && mobile) &&
                                <div ref={optionsRef} className={formStyles.mobileMessageOptionsMenu}>
                                    <div className={formStyles.mobileMessageOptionsMenuItem} onClick={localHandleReply}>
                                        <li>Reply</li>
                                    </div>
                                </div>
                            }
                        </div>
                        }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
});

export default connect(mapStateToProps)(Message);