import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router";

import SidebarAction from '../sidebar/SidebarAction';
import sidebarStyles from "../../../../../css/layout/social/nav/sidebar.css";

import outsideClick from "../../../../../util/components/outsideClick";
import useWindowDimensions from '../../../../../util/hooks/useWindowDimensions';
import {UPDATE_OUTSIDE_CHILDREN, useSidebarDispatch} from "../sidebar/context";
import {MAX_MOBILE_WIDTH} from "../../../../../util/constants";

import AnnouncementsPopup from './AnnouncementsPopup';

function SidebarAnnouncements(props) {
    const notificationsRef = useRef();
    const {width} = useWindowDimensions();
    const dispatch = useSidebarDispatch();

    const [manager, setManager] = useState({
        popup: false,
    });
    const [active, setActive] = useState(false);

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            if(location.pathname === "/notifications"){
                setActive(true);
            }else{
                setActive(false);
            }
        })
    },[history])

    outsideClick(notificationsRef, () => {
        if(!manager.popup) return;
        dispatch({ type: UPDATE_OUTSIDE_CHILDREN, payload: null })

        setManager(manager => ({
            ...manager,
            popup: false,
        }));
    }, true);

    function handleAnnouncements() {
        if(width <= MAX_MOBILE_WIDTH){
            history.push("/announcements");
            return;
        }

        setManager(manager => ({
            ...manager,
            popup: true
        }))

        dispatch({ type: UPDATE_OUTSIDE_CHILDREN, payload: <AnnouncementsPopup ref={notificationsRef} /> })
    }

    return (
        <div ref={notificationsRef}>
            <SidebarAction
                icon={
                    <div className={sidebarStyles.notification} style={{backgroundColor: "gray"}}>
                        0
                    </div>
                }
                text="Announcements"
                active={manager.popup || active}
                onClick={handleAnnouncements}
            />
        </div>
    )
}

const mapStateToProps = state => {
    const { notifications } = state;
    return { notifications: notifications };
};

export default connect(mapStateToProps)(SidebarAnnouncements);
