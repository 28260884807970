// @flow

import React, {useCallback, useEffect, useRef, useState} from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {isMobile} from "is-mobile";

import mainStyles from "../../../../../../../css/layout/main.css";
import layoutStyles from "../../../../../../../css/layout.css";

import SearchInput from "../../../search/SearchInput";
import Actions from "./Actions";
import ChatActions from "./ChatActions";
import Hamburger from "./Hamburger";

import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";
import {MAX_MOBILE_WIDTH} from "../../../../../../../util/constants";
import outsideClick from "../../../../../../../util/components/outsideClick";

import BackArrow from "../../../back/BackButton";
import { useHistory } from "react-router";
import Switch from "../../../switch/Switch";

import ProfilePicture from "../../../../../layout/parts/user/ProfilePicture"

function FeedTopNav(props) {
    const {width} = useWindowDimensions();

    const searchRef = useRef();

    const [manager, setManager] = useState({
        show: false,
    });

    const [grid, setGrid] = useState(false)

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    function setShow(show) {
        setManager(manager => ({
            ...manager,
            show: show
        }))
    }

    const escFunction = useCallback(e => {
        window.focus();
        if (e.keyCode === 27) {
            setShow(false);
        }
    }, []);

    outsideClick(searchRef, () => {
        setShow(false)
    })
    
    const showProfilePic = (props.isDesktopChat || props.mobileChat) && props.chatUser != null
    const showDMheader = props.isDesktopChat ? true : props.mobileChat ? props.chatUser == null ? true : false : false;

    return (
        <div className={mainStyles.topNav}>
            {width <= MAX_MOBILE_WIDTH && (!isMobile() || !manager.show) && !props.mobileChat && <Hamburger />}
            <div style={{alignItems: "center"}} className={layoutStyles.flex + ' ' + layoutStyles.flexGrow} ref={searchRef}>
                {showDMheader && (
                    <div className={mainStyles.chatTopNavDM} style={{width: props.mobileChat ? "50vw" : "" }}>
                        <h4 style={{ marginLeft: "3.2%"}}>
                            Direct Messages
                        </h4>
                    </div>
                )}
                {(!props.isDesktopChat && !props.mobileChat) &&
                    <>
                    <BackArrow />
                    <SearchInput show={manager.show} setShow={setShow} />
                    </>
                }
                {props.mobileChat && props.chatUser &&
                    <BackArrow mobileChat={true}/>
                }
                {props.mobileChat && !props.chatUser &&
                    <SearchInput show={manager.show} setShow={setShow}/>
                }
                {showProfilePic &&
                <>
                    <div className={mainStyles.chatTopNavProfilePic} style={{ marginLeft: props.mobileChat ? "3.2%" : "" }} >
                        <Link to={`/user/${props.chatUser.info.username}`} className={mainStyles.chatTopNavUsernameLink} >
                            <ProfilePicture small={true} inChat={true} showStatus={true} user={props.chatUser} />
                            <h3 style={{ marginLeft: "10px" }}>{props.chatUser.info.username}</h3>
                        </Link>
                    </div>
                </>
                }
            </div>
            {width > 600 ? props.isDesktopChat ? <ChatActions chatUser={props.chatUser}/> : <Actions /> : null}
            {(props.mobileChat && props.chatUser !== null ) ? <ChatActions chatUser={props.chatUser} mobileChat={true}/> : null }
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth, chat: state.chat };
};

export default connect(mapStateToProps)(FeedTopNav);
