// @flow

import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import {connect} from "react-redux";
import {isMobile} from "is-mobile";

import ReplyIcon from "../../../../../../../../assets/svg/icons/share.svg";
import EllipsisV from "../../../../../../../../assets/svg/icons/ellipsisV.svg";
import DeleteIcon from "../../../../../../../../assets/svg/icons/question.svg";

import postStyles from "../../../../../../../../css/components/post.css";
import {hide, mL} from "../../../../../../../../css/layout.css";
import formStyles from "../../../../../../../../css/form.css";
import mobileOptionsStyles from "../../../../../../../../css/components/mobile-options.css";

import {API_URL} from "../../../../../../../../util/constants";
import {timeAgoHR} from "../../../../../../../../util/date";
import outsideClick from "../../../../../../../../util/components/outsideClick";

import ContentLoader from "../../../../../../../../util/components/ContentLoader";
import store from "../../../../../../../../store";
import { getToken } from "../../../../../../../../reducers/auth/actions";
import {previewShow, previewUserId} from "../../../../../../../../reducers/social/actions";
import {colorizeStyle} from "../../../../../../../../util/colorizeStyle";

import fetchCaptcha from "../../../../../../../../util/captcha";
import ReportPost from "../header/ReportPost";
import { Link } from "react-router-dom";
import MediaWrapper from "../../media/MediaWrapper";
import Markers from "../../../../markers/Markers";
import { formatDistanceToNowStrict } from "date-fns";

const ReplyContainer = (props) => {
    const [manager, setManager] = useState({
        error: null,
        securityCode: "",
        showCaptcha: false,
        captchaURL: null,
        captchaId: null,
        captchaLoading: true,
        reported: false,
        reporting: false,
        loading: true,
        showDelete: false,
        deleted: false,
    });

    const ref = useRef();
    const _isMounted = useRef(true);

    useEffect(() => {
        return () => {
            _isMounted.current = false
        }
    }, []);

    const AvatarLoader = () => (
        <ContentLoader
            width={32}
            height={32}
            viewBox="0 0 32 32"
        >
            <rect x="0" y="0" rx="2" ry="2" width="32" height="32" />
        </ContentLoader>
    );

    function handleDelete() {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            },
        };

        // axios.delete(API_URL + '/post/reply/' + props.reply.id, config)
        axios.delete(API_URL + `/post/reply/${props.post.id}/${props.reply.id}`, config)
            .then(function (res) {
                setManager({ ...manager, showDelete: false, deleted: true, });
            })
            .catch(function (err) {
                if(err.response && err.response.data.errors && err.response.data.errors.captcha){
                    setManager(manager => ({
                        ...manager,
                        error: "WRONG_SECURITY_CODE",
                        securityCode: "",
                        submitting: false,
                    }));
                    loadCaptcha();
                    return;
                }

                setManager(manager => ({
                    ...manager,
                    submitting: false,
                    error: true,
                }));
            });
    }

    function handleModDelete(){
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }

        axios.post(`${API_URL}/moderation/deleteReply/${props.post.id}/${props.reply.id}`, {}, config)
            .then(function (res) {
                setManager({ ...manager, showDelete: false, deleted: true, });
            }
        )
    }

    function loadCaptcha() {
        fetchCaptcha().then(data => {
            if(_isMounted.current) {
                setManager(manager => ({
                    ...manager,
                    captchaURL: data.captcha,
                    captchaId: data.id,
                }))
            }
        });
    }

    let replies = props.reply.replies.map(reply => (
        <Reply isReported={props.isReported} key={reply.id} reply={reply} post={props.post} replyAction={props.replyAction} />
    ));

    outsideClick(ref, () => {
       setManager({ ...manager, showDelete: false, });
    });

    function handleClick() {
        store.dispatch(previewShow(true));
        store.dispatch(previewUserId(props.reply.author.info.id));
    }

    function handleReport(){
        if(!manager.reporting){
            loadCaptcha()
            setManager({...manager, reporting: true});
            return
        }


        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            },
        };

        // axios.post(`${API_URL}/report/reply/${props.post.id}/${props.reply.id}`,
        //             {security_id: manager.captchaId, security_code: manager.securityCode}, config)
        //     .then(function (res) {
        //         setManager({...manager, showDelete: false, reported: true});
        //     })
        //     .catch(function (err) {
        //         if(err.response && err.response.data.errors && err.response.data.errors.captcha){
        //             setManager(manager => ({
        //                 ...manager,
        //                 error: "WRONG_SECURITY_CODE",
        //             }));
        //             loadCaptcha();
        //             return;
        //         }

        //         setManager(manager => ({
        //             ...manager,
        //             error: true,
        //         }));
        //     });
    }

    return (
        <div className={postStyles.reply}>
            {manager.reporting && 
                <ReportPost type="reply" parent={props.post} post={props.reply} cancel={() => setManager({...manager, reporting: false})}/>
            }
            
            <div className={postStyles.main}>
                {
                    (props.reply.author !== null && !manager.deleted) &&
                    <img className={postStyles.avatar + ' ' + (manager.loading ? hide : '')}
                         onLoad={() => { setManager({ ...manager, loading: false }) }}
                         onClick={handleClick}
                         src={`${API_URL}/social/avatar/${props.reply.author.info.id}`}
                         alt="avatar" />
                }
                {
                    (props.reply.author === null || manager.deleted) &&
                        <div className={postStyles.avatar}>
                            <DeleteIcon height={24} width={24} />
                        </div>
                }
                {
                    manager.loading && props.reply.author !== null && !manager.deleted && <AvatarLoader />
                }
                <div className={postStyles.body}>
                    <div className={postStyles.top}>
                        <div className={postStyles.info} style={{alignItems: "center"}}>
                            <Link to={`/user/${props.reply.author !== null ? props.reply.author.info.username : ''}`}>
                                <h4 style={colorizeStyle(props.reply.author !== null ? props.reply.author.info.username_color : null)}>
                                    {(props.reply.author === null || manager.deleted) ? "DELETED" : props.reply.author.info.username}
                                </h4>
                            </Link>
                            {props.reply.author != null &&
                                <Markers user={props.reply.author} />
                            }
                            <p style={{fontSize: "0.8rem"}} className={postStyles.timestamp}>{formatDistanceToNowStrict(props.reply.timestamp, {addSuffix: true})}</p>
                            {
                                props.reply.author !== null && !manager.deleted && !props.isReported &&
                                <div className={postStyles.replyButton} onClick={() => {props.replyAction(props.reply.id)}}>
                                    <ReplyIcon width={14} height={14} />
                                </div>
                            }
                        </div>
                        { props.auth.isAuthenticated && !props.isReported &&
                            <div className={postStyles.delete + ' ' + mL}
                                 style={{opacity: manager.showDelete ? 1 : ''}}
                                 ref={ref}>
                                <EllipsisV onClick={() => {setManager({ ...manager, showDelete: true })}}
                                           height={14} />
                                {
                                    (manager.showDelete && !isMobile()) &&
                                    <div className={formStyles.dropdownMenu + ' ' + formStyles.dropdownMenuRight} style={{right: "10px"}}>
                                        {props.auth.user.info.id === props.reply.author.info.id ?
                                        <div className={formStyles.dropdownItem} onClick={handleDelete}>
                                            <li>
                                                Delete
                                            </li>
                                        </div>
                                        :
                                        <div className={formStyles.dropdownItem} onClick={handleReport}>
                                            <li>
                                                Report
                                            </li>
                                        </div>
                                        }
                                        {props.auth.user.info.id == props.post.author.info.id && props.auth.user.info.id != props.reply.author.info.id &&
                                        <div className={formStyles.dropdownItem} onClick={handleDelete}>
                                            <li>
                                                Delete
                                            </li>
                                        </div>
                                        }
                                        {props.auth.user.info.isModerator &&
                                        <div className={formStyles.dropdownItem} onClick={handleModDelete}>
                                            <li>
                                                [MOD] Delete
                                            </li>
                                        </div>
                                        }
                                    </div>
                                }
                                {
                                    (manager.showDelete && isMobile()) &&
                                    <div className={mobileOptionsStyles.container} onClick={() => {setManager({ ...manager, showDelete: false })}}>
                                        <div className={mobileOptionsStyles.options}>
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                {props.auth.user.info.id === props.reply.author.info.id ?
                                                <div className={mobileOptionsStyles.option + ' ' + mobileOptionsStyles.danger} onClick={handleDelete}>
                                                    <p>Delete</p>
                                                </div>
                                                :
                                                <div className={mobileOptionsStyles.option + ' ' + mobileOptionsStyles.danger} onClick={handleReport}>
                                                    <p>Report</p>
                                                </div>
                                                }
                                            </div>
                                            {props.auth.user.info.id == props.post.author.info.id && props.auth.user.info.id != props.reply.author.info.id &&
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                <div className={mobileOptionsStyles.option + ' ' + mobileOptionsStyles.danger} onClick={handleDelete}>
                                                    <p>Delete</p>
                                                </div>
                                            </div>
                                            }
                                            {props.auth.user.info.isModerator &&
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                <div className={mobileOptionsStyles.option + ' ' + mobileOptionsStyles.danger} onClick={handleModDelete}>
                                                    <p>[MOD] Delete</p>
                                                </div>
                                            </div>
                                            }
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                <div className={mobileOptionsStyles.option}>
                                                    <p>Cancel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        !manager.deleted &&
                        props.reply.body.split(" ").map((word, index) => {
                            if(word.startsWith("@")){
                                return(
                                    <Link key={index} to={`/user/${word.slice(1, word.length)}`}>
                                        <span className={postStyles.mention} style={{wordBreak: "break-all"}}>
                                            <a>{word} </a>
                                        </span>
                                    </Link>
                                )
                            }
                            return <span lang="en" key={index} style={{wordBreak: "break-word"}}>{word} </span>
                        })
                    }

                    {props.reply.attachments && props.reply.attachments.length > 0 &&
                    <div style={{marginTop: "5px"}}>
                        <MediaWrapper
                            borderRadius={"10px"}
                            maxWidth={"370px"}
                            maxHeight={"550px"}
                            center={false}
                            attachments={props.reply.attachments}
                        />
                    </div>
                    }
                    {
                        manager.deleted && <p>[deleted]</p>
                    }
                </div>
            </div>
            {
                replies.length > 0 &&
                <div className={postStyles.replies}>
                    {replies}
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

const Reply = connect(mapStateToProps)(ReplyContainer);
export default Reply;
