import React from "react";

// https://via.placeholder.com/300x250

function FixedImageAd(props){

    function click(){
        window.open("https://discord.gg/hWbWXAJPNX", "_blank")
    }

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div onClick={click} style={{width: "300px", height: "250px", cursor: "pointer"}}>
                <img src="/draftm.png" style={{width: "100%", height: "100%"}} />
            </div>
        </div>
    )
}

export default FixedImageAd;