import React from "react";
import formStyles from "../../../../../../css/form.css";
import layoutStyles from "../../../../../../css/layout.css";
import CheckIcon from "../../../../../../assets/svg/icons/check.svg";

function BoostPrivileges(){
    return(
    <div className={layoutStyles.wF}>
        <br/>
        <div className={formStyles.check}>
            <CheckIcon height="15" />
            <span>No storage limits</span>
        </div>
        <div className={formStyles.check}>
            <CheckIcon height="15" />
            <span>Custom username color</span>
        </div>
        <div className={formStyles.check}>
            <CheckIcon height="15" />
            <span>No captchas</span>
        </div>
        <div className={formStyles.check}>
            <CheckIcon height="15" />
            <span>Less frequent ads</span>
        </div>
    </div>
    )
}

export default BoostPrivileges;