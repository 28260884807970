import React from "react";
import { Link } from "react-router-dom";

import {isMobile} from 'is-mobile'

import Top from "../layout/parts/support/nav/Top";

function PrivacyPolicy(){
    return(
        <div style={{textAlign: "justify", marginLeft: "auto", marginRight: "auto", maxWidth: isMobile() ? "95%" : "70%", paddingBottom: "200px"}}>
            <Top/>
            <p><Link to="/register">Go to signup</Link></p>
            <p><Link to="/social/trending">Go to Trending</Link></p>
            <h1>Tiblur Privacy Policy</h1>
            <p>Last updated: 2023-06-28 (YYYY-MM-DD)</p>
            <br/>
            <p>At Tiblur, we value the privacy of our users and are committed to protecting their personal information. This Privacy Policy outlines how we collect, use, and safeguard the data provided by users while accessing our website. By using Tiblur, you consent to the practices described in this policy.</p>
            <ol>
                <li>Data Collection</li>
                <ul type="a">
                    <li>Personally Identifiable Information: Tiblur may collect certain personally identifiable information (PII) from users, such as usernames, passwords, and email addresses. This information is essential for the functioning of the website and is collected with the user's consent.</li>
                    <li>Non-Personally Identifiable Information: Tiblur may also collect non-personally identifiable information (NPII), including but not limited to IP addresses, browser type, operating system, and website usage data. This information is collected automatically and helps us improve our services and enhance user experience.</li>
                </ul>
                <li style={{marginTop: "5px"}}>Use of Data</li>
                <ul type="a">
                    <li>Personal Information: The personal information collected by Tiblur is used solely for the purpose of providing and improving our services. We may use this information to communicate with users, customize their experience, and address their inquiries or concerns. We do not sell, rent, or share personal information with any third parties unless required by law or with the explicit consent of the user.</li>
                    <li>Non-Personal Information: Non-personally identifiable information collected by Tiblur is used for statistical analysis, troubleshooting, and website optimization. This data helps us understand user preferences, enhance functionality, and improve the overall user experience.</li>
                </ul>
                <li style={{marginTop: "5px"}}>Data Security</li>
                <ul type="a">
                    <li>Tiblur takes appropriate measures to protect user data from unauthorized access, alteration, or disclosure. We implement industry-standard security protocols and regularly review our practices to ensure data security. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security of user data.</li>
                </ul>
                <li style={{marginTop: "5px"}}>Account Deletion</li>
                <ul type="a">
                    <li>Users have the right to request the deletion of their accounts and associated data at any time. Upon receiving such a request, Tiblur will permanently delete the account and all personal information associated with it, except where retention is required by law or for legitimate business purposes. Users can initiate the account deletion process by contacting our support team.</li>
                </ul>
                <li style={{marginTop: "5px"}}>Underage Privacy</li>
                <ul type="a">
                    <li>Tiblur does not knowingly collect personal information from individuals under the age of 18. If we become aware that we have inadvertently collected personal information from an individual under 18, we will promptly take steps to delete such data from our records.</li>
                </ul>
                <li style={{marginTop: "5px"}}>Changes to the Privacy Policy</li>
                <ul type="a">
                    <li>Tiblur reserves the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage users to review this policy periodically for any updates.</li>
                </ul>
            </ol>
        </div>
    )
}

export default PrivacyPolicy;