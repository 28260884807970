// @flow

import React, {Suspense, useEffect} from "react";

import analyticsStyles from "../../../../../../css/layout/social/analytics.css";
import layoutStyles from "../../../../../../css/layout.css";

const RecentViews = React.lazy(() => import("./stats/RecentViews"));
const HistoricalAnalytics = React.lazy(() => import("./stats/HistoricalAnalytics"));

import AnalyticsNav from "../nav/AnalyticsNav";

import {useAnalyticsState} from "./context";
import PeriodDropdown from "./PeriodDropdown";

import useWindowDimensions from "../../../../../../util/hooks/useWindowDimensions";
import axios from "axios";
import store from "../../../../../../store";
import { getToken } from "../../../../../../reducers/auth/actions";
import { API_URL } from "../../../../../../util/constants";

function Box(props){
    let symbol;
    let numberColor;
    if(props.number > 0){
        symbol = "+";
        numberColor = "#15de97";
    } else if(props.number < 0){
        // symbol = "-";
        numberColor = "#da133e";
    } else{
        symbol = "";
        numberColor = "#7286e9";
    }

    return(
        <div style={{border: "2px solid whitesmoke", borderRadius: "5px", padding: "10px", maxWidth: "fit-content"}}>
            <h3>{props.title}</h3>
            <h4 style={{color: numberColor}}>{symbol}{props.number}</h4>
        </div>
    )
}

function Stats() {
    const {width} = useWindowDimensions();
    const state = useAnalyticsState();

    const [userStats, setUserStats] = React.useState({})

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': store.dispatch(getToken())
            }
        };
        axios.get(`${API_URL}/instance/stats`, config)
            .then(res => {
                if(!state.historicalStats){return}
                
                let likesDifference = 0;
                let followersDifference = 0;
                
                for(let i = 0; i < state.historicalStats.favorites.length; i++){
                    likesDifference += state.historicalStats.favorites[i].count;
                }
                for(let i = 0; i < state.historicalStats.followers.length; i++){
                    followersDifference += state.historicalStats.followers[i].count;
                }
                
                res.data.data.ranks.likes.difference = likesDifference;
                res.data.data.ranks.followers.difference = followersDifference;

                setUserStats(res.data.data);
            })
    }, [state])

    return (
        <div className={analyticsStyles.analytics}>
            <div className={analyticsStyles.main}>
                <AnalyticsNav />
                {
                    width <= 1250 &&
                    <div className={layoutStyles.flex + ' ' + layoutStyles.alignItemsCenter + ' ' + layoutStyles.mT1 + ' ' + layoutStyles.mB1}>
                        <PeriodDropdown />
                    </div>
                }
                {
                    (state.historicalStats) &&
                    <Suspense fallback={<div className={layoutStyles.mT1}>Loading...</div>}>
                        {state.historicalChart == "LIKES" &&
                        <div style={{marginLeft: "40px", marginTop: "15px"}}>
                            <Box
                                title={`${userStats.ranks.likes.my_total} Likes`}
                                number={userStats.ranks.likes.difference}
                            />
                        </div>
                        }
                        {state.historicalChart == "FOLLOWERS" &&
                        <div style={{marginLeft: "40px", marginTop: "15px"}}>
                            <Box
                                title={`${userStats.ranks.followers.my_total} Followers`}
                                number={userStats.ranks.followers.difference}
                            />
                        </div>
                        }
                        {/* {state.historicalChart == "PROFILE" &&
                        <div style={{marginLeft: "40px", marginTop: "15px"}}>
                            <Box
                                title={`${userStats.ranks.user.my_total} Views`}
                                number={userStats.ranks.user.difference}
                            />
                        </div>
                        } */}
                        <HistoricalAnalytics data={state.historicalStats} />
                    </Suspense>
                }
            </div>
            {
                (state.recentStats && state.recentStats.recent_views && state.recentStats.top_posts) &&
                <div className={analyticsStyles.sidebar}>
                    <Suspense fallback={<></>}>
                        <RecentViews data={state.recentStats} />
                    </Suspense>
                </div>
            }
        </div>
    );
}

export default Stats;
