import React from "react";
import {Link} from "react-router-dom";

import supportStyles from "../../../css/layout/support/support.css";
import cardStyles from "../../../css/components/card.css";
import Container from "../layout/parts/support/Container";

import EyeSlashIcon from "../../../assets/svg/icons/eyeSlash.svg";

function Suppression(props) {
    return (
        <Container>
            <div className={supportStyles.body}>
                <p><Link to="/register">Go to signup</Link></p>
                <p><Link to="/social/trending">Go to Trending</Link></p>
                <div className={cardStyles.card}>
                    <div className={cardStyles.cardHeader}>
                        <EyeSlashIcon width="30" />
                       <h1>Limited Visibility</h1>
                    </div>
                    <div className={cardStyles.cardBody}>
                        <p>While Tiblur dedicates itself to freedom of speech, and allows users a great extent of expression, it is impossible to provide this without limits.</p>
                        <p>To ensure the viability of Tiblur as both a service and a business, Tiblur may set limits on the visibility of user generated content.</p>
                        <p style={{marginTop: "10px"}}>This will cause the content to only be visible when specifically searched for (such as a post not being visible on the Newest page, but being visible on the author's profile).</p>
                        <p>Visibility limitations are enforced on content that is generally objectionable, but not enough to warrant deletion.</p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Suppression;
