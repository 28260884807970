// @flow

import React from "react";
import {connect} from "react-redux";

import switchStyles from "../../../../../../../css/components/switch.css";
import postModalStyles from "../../../../../../../css/layout/social/post-modal.css";

const CompressionCheckbox = (props) => {
    function handleChange(e) {
        props.onChange(e.target.checked);
    }

    return (
        <div className={postModalStyles.nsfw}>
            <div className={switchStyles.switchInput}>
                <p>
                    <label>
                        <input onChange={handleChange}
                               disabled={props.submitting}
                               checked={props.on}
                               type="checkbox" />
                        <span>[ADMIN] SKIP video compression</span>
                    </label>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(CompressionCheckbox);
