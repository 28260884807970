import React from "react";
import {toast} from "react-toastify";
import {connect} from "react-redux";

import layoutStyles from '../../css/layout.css';
import formStyles from '../../css/form.css';

import {updateError} from "../../reducers/error/actions";

import PostError from "../../assets/graphics/post-error.svg";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.props.updateError(true);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={layoutStyles.flex + ' ' + layoutStyles.flexExpandAuto + ' ' + layoutStyles.flexColumn + ' ' + + layoutStyles.p1}>
                   <div className={layoutStyles.mA}>
                        <PostError width="100%" />
                        <h3
                        className={layoutStyles.mT1}
                        style={{textAlign: "center"}}
                        >Uh oh, something went wrong.</h3>
                        <hr />
                        <p className={layoutStyles.mT1}>
                            We're hardly perfect! If this error persists, please let us know on our <a href="https://discord.gg/pJk64gWBSs" target="_blank" rel="noopener noreferrer">Discord</a> server.
                        </p>
                        <hr/>
                        <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                            className={formStyles.button + " " + formStyles.buttonPrimary}
                            style={{marginTop: "10px"}}
                            onClick={() => {
                                window.location.href = "/"
                            }}>
                            Go Home
                        </button>
                        </div>
                   </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = state => {
    const { error } = state;
    return { error: error };
};

const mapDispatchToProps = dispatch => {
    return {
        updateError: error => dispatch(updateError(error))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
