// @flow

import React, {useState} from "react";

import postStyles from "../../../../../../../../css/components/post.css";
import formStyles from "../../../../../../../../css/form.css";

const PDF = (props) => {
    const [showFile, setShowFile] = useState(false);

    return (
        <div className={postStyles.pdf}>
            
            {showFile ?
                <iframe frameBorder={0} src={props.file.file.url}/>
                :
                <div style={{display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center", marginTop: "5px", marginBottom: "5px"}}>
                    <p style={{color: "lightgray", textAlign: "center"}}>PDF file attached.</p>
                    <button style={{width: "50%"}} className={formStyles.button + ' ' + postStyles.show} onClick={() => {setShowFile(true)}}>Show</button>
                    <button style={{width: "50%"}} className={formStyles.button + ' ' + postStyles.show} onClick={() => {window.open(props.file.file.url), "_blank"}}>Open in new tab</button>
                </div>
            }

        </div>
    );
};

export default PDF;
