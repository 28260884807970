export function parseMentions(text, mentions) {
    if(mentions == null){
        return text
    }
    
    let textLength = text.length;
    let offset = 0;

    for (let mention of mentions) {
        if(mention.indices[0] > textLength){
            break;
        }

        let userMention = mention.user.info.username
        if(userMention == null){
            userMention = mention.user.info.id
        }

        let link = "<a href='/" + userMention + "'>@" + userMention + "</a>";

        text = text.substring(0, mention.indices[0] + offset) + link + text.substring(mention.indices[1] + offset)

        offset += (link.length) - (mention.indices[1] - mention.indices[0]);
    }

    return text;
}