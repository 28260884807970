// @flow
import axios from "axios";

import {UPDATE_CHAT, UPDATE_SOCIAL_FOLLOW_REQUEST_COUNT, UPDATE_SOCIAL_NOTIFICATIONS_COUNT} from "./constants";
import {API_URL} from "../../util/constants";
import {batch} from "react-redux";
import store from "../../store";
import { getToken } from "../auth/actions";

export function updateChat(info) {
  return {
    type: UPDATE_CHAT,
    info
  };
}

export const updateSocialNotificationsCount = () => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Auth-Token": store.dispatch(getToken())
    }
  };

  axios.get(API_URL + `/@me/notifications/count`, config)
      .then(function (res) {
          if(res.data.data){
              let chatTotal = 0
              for(let chat in res.data.data.chats){
                  chatTotal += res.data.data.chats[chat]
              }
              batch(() => {
                  dispatch({ type: UPDATE_SOCIAL_NOTIFICATIONS_COUNT, payload: res.data.data.notifications })
                  dispatch({ type: UPDATE_SOCIAL_FOLLOW_REQUEST_COUNT, payload: res.data.data.requests })
                  dispatch({ type: "UPDATE_CHAT_TOTAL", payload: chatTotal})
              })
          }
      })
      .catch(function (err) {

      });
}