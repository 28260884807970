// @flow

import axios from "axios";
import {batch} from "react-redux";

import {
  RESET_FEED,
  UPDATE_PERIOD,
  UPDATE_SORT,
  UPDATE_POSTS,
  CREATE_ERROR,
  STOP_LOADING,
  UPDATE_OFFSET, HIT_LIMIT, UPDATE_TYPE,
  UPDATE_PREVIEW_SHOW, UPDATE_PREVIEW_USER_ID, UPDATE_PREVIEW_USER, UPDATE_PREVIEW_POSTS
} from "./constants";
import {API_URL, POST_LIMIT, POST_OFFSET} from "../../util/constants";

import store from "../../store";
import {getToken} from "../../reducers/auth/actions";

export const previewShow = (status) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PREVIEW_SHOW, payload: status })
}

export const previewUserId = (id) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PREVIEW_USER_ID, payload: id })
}

export const previewUser = (user) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PREVIEW_USER, payload: user })
}

export const previewPosts = (posts) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PREVIEW_POSTS, payload: posts })
}

export const addPreviewPosts = (posts) => (dispatch, getState) => {
  let state = getState().social;
  let newPosts = state.previewProfile.posts.concat(posts);

  dispatch({ type: UPDATE_PREVIEW_POSTS, payload: newPosts });
};


export const reset = () => (dispatch, getState) => {
  dispatch({ type: RESET_FEED });
}

export const error = () => (dispatch, getState) => {
  dispatch({ type: CREATE_ERROR });
}

export const updatePeriod = (period) => (dispatch, getState) => {
  if(getState().social.period === period){
    return;
  }

  batch(() => {
    dispatch({ type: RESET_FEED });
    dispatch({ type: UPDATE_PERIOD, payload: period });
  })
}

export const updateType = (type) => (dispatch, getState) => {
  if(getState().social.type === type){
    return;
  }

  batch(() => {
    dispatch({ type: RESET_FEED });
    dispatch({ type: UPDATE_TYPE, payload: type });
  })
}

export const updateSort = (sort) => (dispatch, getState) => {
  if(getState().social.sort === sort){
    return;
  }

  batch(() => {
    dispatch({ type: RESET_FEED });
    dispatch({ type: UPDATE_SORT, payload: sort });
  })
}

export const addPosts = (posts, offset=10, hiddenOffset=0) => (dispatch, getState) => {
  let state = getState().social;

  let newPosts = state.posts.concat(posts);

  batch(() => {
    dispatch({ type: UPDATE_POSTS, payload: newPosts });
    dispatch({ type: UPDATE_OFFSET, payload: state.offset + offset + hiddenOffset});
    dispatch({ type: STOP_LOADING });

    if(posts.length < POST_LIMIT){
      dispatch({ type: HIT_LIMIT });
    }
  })
}

export const addPostsToTop = (posts) => (dispatch, getState) => {
  let state = getState().social;

  if(state.topLockFetch){
    dispatch({ type: "STOP_LOADING_TOP" });
    return
  }

  if(posts.length === 0){
    batch(() => {
      dispatch({ type: "STOP_LOADING_TOP" });
      dispatch({ type: "SET_TOP_LOCK_FETCH", payload: true });
    })
    return
  }
  let newPosts = [...posts, ...state.posts]
  
  batch(() => {
    dispatch({ type: UPDATE_POSTS, payload: newPosts });
    dispatch({ type: "STOP_LOADING_TOP" });
    dispatch({ type: "SET_TOP_LOCK_FETCH", payload: false });
  })
}

export const stopLoading = () => (dispatch, getState) => {
  dispatch({ type: STOP_LOADING });
}

export const offset = (offset) => (dispatch, getState) => {
  dispatch({ type: UPDATE_OFFSET, payload: offset });
}

export const fetchPosts = (url, params, key = "posts", scrollTo=null) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Auth-Token": store.dispatch(getToken())
    },
    params: params,
  };

  function sleep(seconds) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }

  let counter = 0;

  const fetchNext = () => {
    if (counter >= 10) {
      return;
    }

    axios.get(API_URL + url, config)
      .then(function (res) {
        addPosts(res.data.data[key], res.data.data.posts.length, res.data.data.hiddenOffset ? res.data.data.hiddenOffset : 0)(dispatch, getState);
        if(scrollTo != null){
          let post = document.getElementById(scrollTo)
          if(post != null){
              post.scrollIntoView({behavior: "smooth"})
          } else{
              post = document.getElementById(`reblog-${scrollTo}`)
              if(post != null){
                  post.scrollIntoView({behavior: "smooth"})
              }
          }
        }
      })
      .catch(async function (err) {
        if(err.response && err.response.status === 404){
          addPosts([])(dispatch, getState);
        } else{
          await sleep(1.2);
          counter++;
          fetchNext();
        }
      });
  };

  fetchNext();
};

export const fetchTopPostsAction = (url, params, key = "posts") => (dispatch, getState) => {
  let lastTopFetch = getState().social.lastTopFetch;
  if(lastTopFetch > (Date.now() / 1000) - 2){ return }

  batch(() => {
    store.dispatch({type: "SET_LAST_TOP_FETCH", payload: Date.now() / 1000})
    store.dispatch({type: "START_LOADING_TOP"})
  })

  if(params.useTopPost === true){
    params.topPost = getState().social.posts[0].id;
  }
  
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Auth-Token": store.dispatch(getToken())
    },
    params: params,
  };

  axios.get(API_URL + url, config)
    .then(function (res) {
      addPostsToTop(res.data.data[key])(dispatch, getState);
      let post = document.getElementById(params.topPost)
      if(post != null){
        post.scrollIntoView()
      }
    })
    .catch(function (err) {
      console.log(err)
    });
}

export const handleHidePost = (id, setTo) => (dispatch, getState) => {
  function change(post) {
    if(post.id !== id){
      return post;
    }

    return {
      ...post,
      filtered: setTo,
    }
  }

  let newPostsArray = getState().social.posts.map(post => change(post));
  let newPreviewArray = getState().social.previewProfile.posts.map(post => change(post));

  batch(() => {
    dispatch({ type: UPDATE_POSTS, payload: newPostsArray });
    dispatch({ type: UPDATE_PREVIEW_POSTS, payload: newPreviewArray });
  })
}

/**
 * Interactions
 */
export const favoritePost = (id, like=null) => (dispatch, getState) => {
  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    let clone = Array.isArray(obj) ? [] : {};
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = deepClone(obj[key]);
      }
    }
  
    return clone;
  }
  
  function change(post) {
    if(post.id != id && (post.reblog == null || post.reblog == "unavailable" || post.reblog.id != id)){
      return post;
    }
    if(post.id == id){
      if(like !== null){
        post.is_favorited = like;
      } else{
        post.is_favorited = !post.is_favorited;
        if(post.is_favorited){
          post.favorites_count += 1;
        } else{
          post.favorites_count -= 1;
        }
      }
    }
    if(post.reblog && post.reblog.id == id){
      if(like !== null){
        post.reblog.is_favorited = like;
      } else{
        post.reblog.is_favorited = !post.reblog.is_favorited;
        if(post.reblog.is_favorited){
          post.reblog.favorites_count += 1;
        } else{
          post.reblog.favorites_count -= 1;
        }
      }
    }
    // The original implementation of this function (change) was returning a shallow copy, which was causing issues with deep attributes like `reblog`.
    // Therefore, we need to return a deep copy of the post object.
    return deepClone(post)
  }
  
  let newPostsArray = getState().social.posts.map(post => change(post));

  dispatch({ type: UPDATE_POSTS, payload: newPostsArray });

  let newPreviewArray = getState().social.previewProfile.posts.map(post => change(post));

  dispatch({ type: UPDATE_PREVIEW_POSTS, payload: newPreviewArray });
}

export const reblogPost = (id) => (dispatch, getState) => {
  function change(post) {
    if(post.id !== id && (!post.reblog || post.reblog.id !== id)){
      return post;
    }

    if(!post.is_reblogged){
      post.reblogs_count += 1;
      post.is_reblogged = true;
    } else{
      post.reblogs_count -= 1;
      post.is_reblogged = false;
    }

    if(post.reblog !== null && !post.reblog.is_reblogged){
      post.reblog.reblogs_count += 1;
      post.reblog.is_reblogged = true;
    } else if(post.reblog !== null && post.reblog.is_reblogged){
      post.reblog.reblogs_count -= 1;
      post.reblog.is_reblogged = false;
    }

    return {
      ...post,
    }
  }

  let newPostsArray = getState().social.posts.map(post => change(post));

  dispatch({ type: UPDATE_POSTS, payload: newPostsArray });

  let newPreviewArray = getState().social.previewProfile.posts.map(post => change(post));

  dispatch({ type: UPDATE_PREVIEW_POSTS, payload: newPreviewArray });
}

export const votePost = (id, option) => (dispatch, getState) => {
  function change(post) {
    if(post.id != id && (!post.reblog || post.reblog.id != id)){
      return post;
    }

    if(!post.poll && !post.reblog.poll){
      return post;
    }

    if(post.poll != null && post.poll.options[option - 1]){
      post.poll.votes_count += 1;
      post.poll.options[option - 1].votes_count += 1;

      post.poll.my_vote = {
        id: "0000000000000000000",
        option: option,
      }
      post.poll.user_has_voted = true
      post.poll.my_vote = option - 1
    }

    if(post.reblog != null && post.reblog.poll !== null && post.reblog.poll.options[option - 1]) {
      post.reblog.poll.votes_count += 1;
      post.reblog.poll.options[option - 1].votes_count += 1;

      post.reblog.poll.my_vote = {
        id: "0000000000000000000",
        option: option,
      }
      post.reblog.poll.user_has_voted = true
      post.reblog.poll.my_vote = option - 1
    }

    return {
      ...post
    }
  }

  let newPostsArray = getState().social.posts.map(post => change(post));

  dispatch({ type: UPDATE_POSTS, payload: newPostsArray });

  let newPreviewArray = getState().social.previewProfile.posts.map(post => change(post));

  dispatch({ type: UPDATE_PREVIEW_POSTS, payload: newPreviewArray });
}

export const deletePost = (id) => (dispatch, getState) => {
  let {posts} = getState().social;

  let newArray = posts.filter((obj => 
    (obj.id != id && (obj.reblog == null || obj.reblog.id != id))
  ));

  dispatch({ type: UPDATE_POSTS, payload: newArray });
}

export const pinPost = (id) => (dispatch, getState) => {
  let {posts} = getState().social;

  let newArray = posts.map(post => {
    if(post.id !== id){
      return post;
    }

    post.pinned = !post.pinned;
    post.private = false;

    return {
      ...post
    }
  });

  dispatch({ type: UPDATE_POSTS, payload: newArray });
}


export const privatePost = (id, value) => (dispatch, getState) => {
  let {posts} = getState().social;

  let newArray = posts.map(post => {
    if(post.id !== id){
      return post;
    }

    post.private = value;

    return {
      ...post
    }
  });

  dispatch({ type: UPDATE_POSTS, payload: newArray });
}

export const followerPost = (id, value) => (dispatch, getState) => {
  let {posts} = getState().social;

  let newArray = posts.map(post => {
    if(post.id !== id){
      return post;
    }

    post.followers_only = value;

    return {
      ...post
    }
  });

  dispatch({ type: UPDATE_POSTS, payload: newArray });
}

export const setPostVisibility = (id, value) => (dispatch, getState) => {
  let {posts} = getState().social;

  let newArray = posts.map(post => {
    if(post.id !== id){
      return post;
    }

    post.visibility = value;

    return {
      ...post
    }
  });

  dispatch({ type: UPDATE_POSTS, payload: newArray });
}