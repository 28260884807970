const initialState = {
    chatList: [],
    closedChatsList: [],
    currentChannel: null,
    history: [],
    typingIDs: {},
    lastInteractedChannel: null,
    personPreview: localStorage.getItem('personPreview') !== 'false',
    bubblesLayout: localStorage.getItem('bubblesLayout') !== 'false',
    jumpedMessageID: null,
    performingJump: false,
    replyingTo: null,
};

function chat(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_TYPING":
            return {
                ...state,
                typingIDs: {
                    ...state.typingIDs,
                    [action.payload.sender]: action.payload.timestamp
                }
            }
        case "UPDATE_CLOSED_CHATS_LIST":
            return {
                ...state,
                closedChatsList: action.payload,
            }
        case "UPDATE_CHAT_LIST":
            return {
                ...state,
                chatList: action.payload,
            }
        case "UPDATE_CURRENT_CHANNEL":
            return {
                ...state,
                currentChannel: action.payload,
                performingJump: false,
                jumpedMessageID: null,
                replyingTo: null,
            }
        case "UPDATE_CHAT_HISTORY":
            return {
                ...state,
                history: action.payload,
            }
        case "PREPEND_CHAT_HISTORY":
            return {
                ...state,
                history: action.payload.concat(state.history),
            }
        case "APPEND_CHAT_HISTORY":
            return {
                ...state,
                history: state.history.concat(action.payload),
            }
        case "MARK_MESSAGE_AS_SENT":
            return {
                ...state,
                history: state.history.map(message => {
                    if(message.temp_id == action.payload.temp_id){
                        message.sent = true
                        message.id = action.payload.id
                    }
                    return message
                })
            }
        case "MARK_MESSAGE_AS_FAILED":
            return {
                ...state,
                history: state.history.map(message => {
                    if(message.temp_id == action.payload.temp_id){
                        message.failed = true
                    }
                    return message
                })
            }
        case "DELETE_MESSAGE":
            return {
                ...state,
                history: state.history.map(message => {
                    if(message.id == action.payload){
                        message.deleted = true
                        message.attachments = undefined
                        console.log("Message deleted")
                    }
                    return message
                })
            }
        case "SET_UNREAD_COUNT":
            let chatList = state.chatList.map(user => {
                if(user.info.id == action.payload.id){
                    user.unreadCount = action.payload.count
                }
                return user
            })
            return {
                ...state,
                chatList: chatList
            }
        case "SET_LAST_INTERACTED":
            return {
                ...state,
                lastInteractedChannel: action.payload,
            }
        case "TOGGLE_PERSON_PREVIEW":
            localStorage.setItem('personPreview', !state.personPreview);
            return {
                ...state,
                personPreview: !state.personPreview,
            }
        case "TOGGLE_CHAT_LAYOUT":
            localStorage.setItem('bubblesLayout', !state.bubblesLayout);
            return {
                ...state,
                bubblesLayout: !state.bubblesLayout,
            }  
        case "SET_JUMPED_MESSAGE_ID":
            return {
                ...state,
                jumpedMessageID: action.payload,
            }
        case "SET_PERFORMING_JUMP":
            return {
                ...state,
                performingJump: action.payload,
            }
        case "SET_REPLY_ID":
            return {
                ...state,
                replyingTo: action.payload,
            }           
        default:
            return state;
    }
}

export default chat;