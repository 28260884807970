// @flow

import React, { useEffect, useState } from "react";

import postStyles from "../../../../../../../../css/components/post.css";

import Video from "./Video";
import Row from "../file/Row";
import {numberToString} from "../../../../../../../../util/formatNumber";

const VideoContainer = (props) => {
    const [thumbUrl, setThumbUrl]= useState(null)

    useEffect(() => {
        if(props.files.length === 2){
            setThumbUrl(props.files[0].file.url)
        }
    }, [props.files]);

    if(thumbUrl){
        return (
            <div className={postStyles.rows}>
                <Row file={props.files[1]} key={props.files[1]}>
                    <Video file={props.files[1]} thumbnailUrl={thumbUrl}/>
                </Row>
            </div>
        )
    }

    return (
        <div className={postStyles.rows}>
            {props.files.map(file => (
                <Row file={file} key={file.id}>
                    <Video file={file} thumbnailUrl={thumbUrl}/>
                </Row>
            ))}
            {/* <p className={postStyles.views}>{numberToString(props.views)} views</p> */}
        </div>
    );
};

export default VideoContainer;
