import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import isMobile from "is-mobile";

import chatStyles from "../../../../css/layout/chat/chat.css";

import SendIcon from "../../../../assets/svg/icons/send.svg"
import FolderIcon from "../../../../assets/svg/icons/folder.svg"

import store from "../../../../store";

function InputBox(props){
    const mobile = isMobile();

    const [attachment, setAttachment] = useState(null);
    const [error, setError] = useState(false);

    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const adjustContainerHeight = () => {
        if (textareaRef.current && containerRef.current) {
            const textarea = textareaRef.current;
            const container = containerRef.current;
            textarea.style.height = 'auto';
            const scrollHeight = Math.max(textarea.scrollHeight, 24);
            textarea.style.height = `${scrollHeight}px`;
            container.style.height = `${scrollHeight}px`;
        }
    };
    
    function requestFile() {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";

        fileInput.onchange = (e) => {
            const file = e.target.files[0];
            const fileType = file.type.split("/")[0];
            const fileSizeMB = file.size / 1024 / 1024;

            if(fileType !== "image") {
                setError(true)
                return
            }
            if(fileSizeMB > 11) {
                setError(true)
                return
            }
            setAttachment(file);
        };

        fileInput.click();
    }

    function removeAttachment() {
        setAttachment(null);
        setError(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const sent = props.keyPress(e, attachment);
          if (sent) {
            textareaRef.current.value = "";
            setAttachment(null);
            setError(false);
            adjustContainerHeight();
          }
        } else {
            props.keyPress(e, null);
        }
      }
    
    const handleInputChange = () => {
      adjustContainerHeight();
    };

    function sendMessage(){
        const content = document.getElementById("chat-text-input").value
        const trimmedContent = content.trim()
        if(trimmedContent.length === 0 && attachment == null) return;

        const sent = props.sendMessage(trimmedContent, attachment)
        if(sent){
            store.dispatch({ type: "SET_REPLY_ID", payload: null });
            document.getElementById("chat-text-input").value = ""
            setAttachment(null)
            setError(false)
        }
    }

    useEffect(() => {
        if (props.chat.replyingTo != null && textareaRef.current) {
            textareaRef.current.focus();
        } else if (textareaRef.current) {
            textareaRef.current.blur();
        }
    }, [props.chat.replyingTo]);

    useEffect(() => {
        if (textareaRef.current && !mobile) {
          textareaRef.current.focus();
        }
    }, [props.chat.currentChannel]);

    useEffect(() => {
        adjustContainerHeight();
      }, []);

    return (
        <>
        {attachment != null &&
            <div className={chatStyles.attachementDiv}>
                <div className={chatStyles.attachementContainer}>
                    <img className={chatStyles.attachementIMG}
                        src={URL.createObjectURL(attachment)}
                        onClick={() => window.open(URL.createObjectURL(attachment), '_blank')}
                    />
                    <div className={chatStyles.attachementRemoveIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            removeAttachment();
                        }}
                    >
                        X
                    </div>
                </div>
            </div>
        }
        <div ref={containerRef} className={chatStyles.chatBoxContainer}>
            <div onClick={requestFile} className={chatStyles.folderIconContainer}>
                <FolderIcon color={error && "pink"} height="24px" />
            </div>
            <textarea
                ref={textareaRef}
                id="chat-text-input"
                className={chatStyles.chatBoxTextArea}
                style={{
                    width: props.mobile ? "90%" : "100%",
                    height: "24px",
                }}
                rows={1}
                placeholder={props.user && !props.mobile ? `Send a message to ${props.user.info.username}...` : "Send a message..."}
                onKeyDown={handleKeyPress}
                onInput={handleInputChange}
            />
            <div onClick={sendMessage} className={chatStyles.sendIconContainer}>
                <SendIcon height="24px" />
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    chat: state.chat,
});

export default connect(mapStateToProps)(InputBox);