// @flow

import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {isMobile} from "is-mobile";

import layoutStyles, {mL, hide} from "../../../../../../css/layout.css";
import navStyles from "../../../../../../css/components/tabs-nav.css";
import profileStyles from "../../../../../../css/layout/social/profile.css";
import actionStyles from "../../../../../../css/components/action-menu.css";
import outrunStyles from "../../../../../../css/themes/outrun-theme.css";
import cyberpunkStyles from "../../../../../../css/themes/cyberpunk-theme.css";
import newspaperStyles from "../../../../../../css/themes/newspaper-theme.css";
import mobileOptionsStyles from "../../../../../../css/components/mobile-options.css";

import LocationIcon from "../../../../../../assets/svg/icons/map-marker.svg";
import EllipsisIcon from "../../../../../../assets/svg/icons/ellipsisH.svg";
import GearIcon from "../../../../../../assets/svg/icons/gear.svg";
import LockIcon from "../../../../../../assets/svg/icons/lock.svg";
import CheckIcon from "../../../../../../assets/svg/icons/check.svg";
import ClockIcon from "../../../../../../assets/svg/icons/clock.svg";

import ChangeHeader from "./ChangeHeader";
import FollowButton from "./FollowButton";

import {UPDATE_USER, useProfileDispatch, useProfileState} from "../context";
import history from "../../../../../../util/history";
import {
    API_URL,
    CYBERPUNK_THEME,
    MAX_MOBILE_WIDTH,
    NEWSPAPER_THEME,
    OUTRUN_THEME
} from "../../../../../../util/constants";
import outsideClick from "../../../../../../util/components/outsideClick";
import {numberToString} from "../../../../../../util/formatNumber";
import ContentLoader from "../../../../../../util/components/ContentLoader";
import useWindowDimensions from "../../../../../../util/hooks/useWindowDimensions";
import ReportPost from "../../social/post/components/header/ReportPost";
import { getToken } from "../../../../../../reducers/auth/actions";
import store from "../../../../../../store";
import Markers from "../../markers/Markers";

import { formatDistanceToNowStrict } from 'date-fns'

function ProfileContainer(props) {
    let user;
    if(props.user){
        user = props.user;
    } else{
        user = useProfileState().user
    }
    // const { user } = useProfileState();
    const profileDispatch = useProfileDispatch();
    const optionsRef = useRef();
    const { width } = useWindowDimensions();

    let bannerWidth = width;
    let bannerHeight = 200;

    if(width > MAX_MOBILE_WIDTH){
        bannerWidth = props.video ? 900 : 700;
    }

    const [manager, setManager] = useState({
        reporting: false,
        avatarLoaded: false,
        bannerLoaded: false,
        showOptions: false,
        suppressed: user != undefined ? user.info.suppressed : false
    });

    useEffect(() => {
        if(user === null) return;
        setManager(manager => ({
            ...manager,
            suppressed: user.info.suppressed
        }));
    }, [user])

    const ProfileLoader = () => (
        <ContentLoader
            speed={2}
            width={100}
            height={100}
            viewBox="0 0 100 100"
        >
            <rect x="0" y="0" width="100" height="100" />
        </ContentLoader>
    )

    const BannerLoader = () => (
        <ContentLoader
            speed={2}
            width={bannerWidth}
            height={bannerHeight}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
        >
            <rect x="0" y="0" rx="1" width="100%" height="100" />
        </ContentLoader>
    )

    outsideClick(optionsRef, () => {
        if(manager.showOptions){
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));
        }
    })

    function handleBlock()
    {
        if(!props.auth.isAuthenticated || user.info.id === props.auth.user.info.id) return;

        axios({
            method: user.info.blocking ? 'delete' : 'post',
            url: API_URL + "/users/block/" + user.info.id,
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));

            let status = user.info.blocking ? "Unblocked" : "Blocked";

            const Notification = () => (
                <div>
                    {status} user!
                </div>
            );

            setTimeout(() => {
                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);

            user.info.blocking = !user.info.blocking;
            user.info.followed_by = false;
            user.info.following = false;
            profileDispatch({ type: UPDATE_USER, payload: user });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            setTimeout(() => {
                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        });
    }

    function usernameTheme() {
        if(user === null){
            return "";
        }

        if(user.info.profile_theme === OUTRUN_THEME){
            return ' ' + outrunStyles.username;
        }

        if(user.info.profile_theme === CYBERPUNK_THEME){
            return ' ' + cyberpunkStyles.username;
        }

        if(user.info.profile_theme === NEWSPAPER_THEME){
            return ' ' + newspaperStyles.username;
        }

        return "";
    }

    function handleBan(){
        axios({
            method: 'post',
            url: API_URL + `/moderation/ban/${user.info.id}`,
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            },
            data: {type: user.info.banned ? "unban" : "ban"}
        }).then(res => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));

            let status = user.info.banned ? "Unbanned" : "Banned";

            const Notification = () => (
                <div>
                    {status} user!
                </div>
            );

            setTimeout(() => {
                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);

            user.info.banned = !user.info.banned;
            profileDispatch({ type: UPDATE_USER, payload: user });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            setTimeout(() => {
                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        });
    }

    function handleReport(){
        if(!manager.reporting){
            setManager(manager => ({
                ...manager,
                reporting: true,
            }));
            return;
        }

        axios({
            method: 'post',
            url: API_URL + `/report/user/${user.info.id}`,
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));

            const Notification = () => (
                <div>
                    Reported user!
                </div>
            );

            setTimeout(() => {
                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            setTimeout(() => {
                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        });
    }

    function handleMod(){
        axios({
            method: 'post',
            url: API_URL + `/moderation/mod/${user.info.id}`,
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            },
            data: {type: user.info.isModerator ? "unmod" : "mod"}
        }).then(res => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));

            let status = user.info.isModerator ? "Unmodded" : "Modded";

            const Notification = () => (
                <div>
                    {status} user!
                </div>
            );

            setTimeout(() => {
                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);

            user.info.isModerator = !user.info.isModerator;
            profileDispatch({ type: UPDATE_USER, payload: user });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            setTimeout(() => {
                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        });
    }

    function handleBoost(type){
        axios({
            method: 'post',
            url: API_URL + `/moderation/boost/${user.info.id}`,
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            },
            data: {
                type: type
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));

            let status;
            if(type == "remove"){
                status = "Unboosted"
            } else if(type == "30 days"){
                status = "Added 30 day boost to"
            } else if(type == "infinite"){
                status = "Added infinite boost to"
            }

            const Notification = () => (
                <div>
                    {status} user!
                </div>
            );

            setTimeout(() => {
                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);

            user.info.boosted = !user.info.boosted;
            profileDispatch({ type: UPDATE_USER, payload: user });
        }).catch(err => {
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            setTimeout(() => {
                toast.error(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800);
        });
    }

    function handleSuppress(){
        if(!props.auth.isAuthenticated) return;

        axios({
            url: API_URL + `/moderation/${manager.suppressed ? "unsuppress" : "suppress"}/user/` + user.info.id,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        }).then(res => {
            setManager(manager => ({
                ...manager,
                suppressed: !manager.suppressed,
                showOptions: false,
            }));
            user.info.suppressed = !user.info.suppressed;
            const Notification = () => (
                <div>
                    {manager.suppressed ? "Unsuppressed" : "Suppressed"} user!
                </div>
            );

            toast(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            setManager(manager => ({
                ...manager,
                showOptions: false,
            }));
            
            const Notification = () => (
                <div>
                    There was an error!
                </div>
            );

            toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    function handleMessage(){
        if(!props.auth.isAuthenticated) return;
        store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: user.info.id});
        history.push(`/chat/${user.info.id}`);
    }

    if(user === null){
        return (
            <div className={profileStyles.header}>
                <div className={profileStyles.cover}>
                    <BannerLoader />
                    <div className={profileStyles.profileImage}>
                        <ProfileLoader />
                    </div>
                </div>
                <div className={profileStyles.main}>
                    <div className={profileStyles.info} />
                </div>
            </div>
        );
    }

    // Set banner to redux value for if update
    if(props.auth.isAuthenticated && props.auth.user !== null && props.auth.user.id === user.id){
        user.info.banner = props.auth.user.info.banner;
    }

    let bannerStyles = {};
    if(user.info.banner === null){
        bannerStyles = {
            height: "200px"
        }
    }

    return (
        <div className={profileStyles.header + (props.video ? ' ' + profileStyles.videoHeader : '')}>
            {manager.reporting &&
            <ReportPost type="user" post={user} cancel={() => setManager({...manager, reporting: false})} />
            }
            <div className={profileStyles.cover} style={bannerStyles}>
                {
                    <img src={`${API_URL}/social/banner/${user.info.id}`}
                         onLoad={() => {
                             setManager({...manager, bannerLoaded: true})
                         }}
                         onError={() => {
                            setManager({...manager, bannerLoaded: false})
                         }}
                         className={!manager.bannerLoaded ? hide : ''} alt="profile banner"/>
                }
                {
                    (!manager.bannerLoaded && user.info.banner !== null) && <BannerLoader />
                }
                <div className={profileStyles.profileImage}>
                    {
                        !manager.avatarLoaded && <ProfileLoader />
                    }
                    <img src={`${API_URL}/social/avatar/${user.info.id}`}
                         onLoad={() => { setManager({ ...manager, avatarLoaded: true }) }}
                         className={!manager.avatarLoaded ? hide : ''} alt="header" />
                </div>
                {
                    props.auth.user !== null && props.auth.user.info.username === user.info.username &&
                    <ChangeHeader />
                }
            </div>
            <div className={profileStyles.main}>
                <div className={profileStyles.info}>
                    <div className={profileStyles.username}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h3 className={usernameTheme()} style={{color: user.info.username_color}}>{props.username}</h3>
                        </div>
                        <Markers user={user}/>
                        {
                            props.auth.user !== null && props.auth.user.info.username !== user.info.username &&
                            <div className={profileStyles.actions}>
                                <EllipsisIcon height={16}
                                              onClick={() => {
                                                  setManager({
                                                      ...manager, showOptions: true
                                                  })}
                                              }/>
                                {
                                    (manager.showOptions && !isMobile()) &&
                                    <div className={actionStyles.actionsMenu} ref={optionsRef}>
                                        <div className={actionStyles.title}>
                                            <GearIcon height={16} />
                                            <p>Options</p>
                                        </div>
                                        {user.privacy.message != false && props.auth.isAuthenticated && !user.info.blocking && !user.info.blocked &&
                                            <div className={actionStyles.option} onClick={handleMessage}>
                                                Message
                                            </div>
                                        }
                                        {
                                            !user.info.blocking &&
                                            <div className={actionStyles.option} onClick={handleBlock}>
                                                Block
                                            </div>
                                        }
                                        {
                                            user.info.blocking &&
                                            <div className={actionStyles.option} onClick={handleBlock}>
                                                Unblock
                                            </div>
                                        }
                                        <div className={actionStyles.option} onClick={handleReport}>
                                            Report
                                        </div>
                                        {props.auth.user.info.isModerator && user.info.banned &&
                                            <div className={actionStyles.option} onClick={handleBan}>
                                                [MOD] Unban
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && !user.info.banned &&
                                            <div className={actionStyles.option} onClick={handleBan}>
                                                [MOD] Ban
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && !user.info.isModerator &&
                                            <div className={actionStyles.option} onClick={handleMod}>
                                                [MOD] Make mod
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && user.info.isModerator &&
                                            <div className={actionStyles.option} onClick={handleMod}>
                                                [MOD] Remove mod
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && user.info.boosted &&
                                            <div className={actionStyles.option} onClick={() => handleBoost("remove")}>
                                                [MOD] Remove boost
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && !user.info.boosted &&
                                            <div className={actionStyles.option} onClick={() => handleBoost("30 days")}>
                                                [MOD] Boost (30 days)
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && !user.info.boosted &&
                                            <div className={actionStyles.option} onClick={() => handleBoost("infinite")}>
                                                [MOD] Boost (infinite)
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && !user.info.suppressed &&
                                            <div className={actionStyles.option} onClick={handleSuppress}>
                                                [MOD] Suppress
                                            </div>
                                        }
                                        {props.auth.user.info.isModerator && user.info.suppressed &&
                                            <div className={actionStyles.option} onClick={handleSuppress}>
                                                [MOD] Unsuppress
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    (manager.showOptions && isMobile()) &&
                                    <div className={mobileOptionsStyles.container} onClick={() => {
                                        setManager({
                                            ...manager, showOptions: false
                                        })}
                                    }>
                                        <div className={mobileOptionsStyles.options}>
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                {user.privacy.message != false && props.auth.isAuthenticated && !user.info.blocking && !user.info.blocked &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleMessage}>
                                                        Message
                                                    </div>
                                                }
                                                {
                                                    !user.info.blocking &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleBlock}>
                                                        Block
                                                    </div>
                                                }
                                                {
                                                    user.info.blocking &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleBlock}>
                                                        Unblock
                                                    </div>
                                                }
                                                <div className={mobileOptionsStyles.option} onClick={handleReport}>
                                                    Report
                                                </div>
                                                {props.auth.user.info.isModerator && user.info.banned &&
                                                <div className={mobileOptionsStyles.option} onClick={handleBan}>
                                                    [MOD] Unban
                                                </div>
                                                }
                                                {props.auth.user.info.isModerator && !user.info.banned &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleBan}>
                                                        [MOD] Ban
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && user.info.isModerator &&
                                                <div className={mobileOptionsStyles.option} onClick={handleMod}>
                                                    [MOD] Remove mod
                                                </div>
                                                }
                                                {props.auth.user.info.isModerator && !user.info.isModerator &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleMod}>
                                                        [MOD] Make mod
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && user.info.boosted &&
                                                    <div className={mobileOptionsStyles.option} onClick={() => handleBoost("remove")}>
                                                        [MOD] Remove boost
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && !user.info.boosted &&
                                                    <div className={mobileOptionsStyles.option} onClick={() => handleBoost("30 days")}>
                                                        [MOD] Boost (30 days)
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && !user.info.boosted &&
                                                    <div className={mobileOptionsStyles.option} onClick={() => handleBoost("infinite")}>
                                                        [MOD] Boost (infinite)
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && !user.info.suppressed &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleSuppress}>
                                                        [MOD] Suppress
                                                    </div>
                                                }
                                                {props.auth.user.info.isModerator && user.info.suppressed &&
                                                    <div className={mobileOptionsStyles.option} onClick={handleSuppress}>
                                                        [MOD] Unsuppress
                                                    </div>
                                                }
                                            </div>
                                            <div className={mobileOptionsStyles.optionGroup}>
                                                <div className={mobileOptionsStyles.option}>
                                                    <p>Cancel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            user.info.followed_by && <label>Follows you</label>
                        }
                        {
                            user.info.blocking && <label>Blocked</label>
                        }
                    </div>
                    {
                        user.info.location !== null &&
                        <div className={profileStyles.location}>
                            <LocationIcon height={16} />
                            <span>{user.info.location}</span>
                        </div>
                    }
                    {
                        user.info.suppressed === true &&
                        <div className={profileStyles.location}>
                            <LockIcon width={16}/>
                            <span>User is suppressed.</span>
                        </div>
                    }
                    {
                        user.info.join_date !== null &&
                        <div className={profileStyles.location}>
                            <ClockIcon width={16} />
                            <span>
                                Joined {
                                    formatDistanceToNowStrict(
                                        (user.info.join_date < Date.now() / 1000 ? user.info.join_date * 1000 : user.info.join_date),
                                        {addSuffix: true}
                                    )
                                }
                            </span>
                        </div>
                    }
                    {
                        user.info.biography !== null &&
                        <div className={profileStyles.biography}>
                            <span>{user.info.biography}</span>
                        </div>
                    }
                    {
                        width <= MAX_MOBILE_WIDTH &&
                        <div className={layoutStyles.flex + ' ' + layoutStyles.flexColumn + ' ' + layoutStyles.mT1 + ' ' + layoutStyles.mB1}>
                            <FollowButton
                                user={user}
                                followCallback={(status) => {
                                    if(status == "followed"){
                                        user.info.following = true;
                                    } else if(status == "requested"){
                                        user.info.following = false;
                                        user.info.requested = true;
                                    }
                                    profileDispatch({ type: UPDATE_USER, payload: user });
                                }}
                                unfollowCallback={() => {
                                    user.info.following = false;
                                    profileDispatch({ type: UPDATE_USER, payload: user });
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className={navStyles.nav + ' ' + layoutStyles.border0}>
                <div className={navStyles.pages}>
                    {
                        props.video === true &&
                        <div onClick={() => { history.push(`/channel/${props.username}`) }}
                             className={navStyles.page}>
                            <label>
                                Videos
                            </label>
                            {
                                (props.routeName === "profile") &&
                                <span className={navStyles.active}/>
                            }
                        </div>
                    }
                    {
                        !props.video &&
                        <div onClick={() => { history.push(`/user/${props.username}`) }}
                             className={navStyles.page}>
                            <label>
                            {user.privacy.feed === false && <LockIcon height={14} />}Feed
                            </label>
                            {
                                (props.routeName === "profile") &&
                                <span className={navStyles.active}/>
                            }
                        </div>
                    }
                    {
                        // (user.privacy.following === true || (props.auth.isAuthenticated && user.id === props.auth.user.id)) &&
                        <div onClick={() => { history.push(`${props.video === true ? '/channel' : ''}/user/${props.username}/following`) }}
                             className={navStyles.page}>
                            <label>
                                {user.privacy.following === false && <LockIcon height={14} />}
                                {user.info.following_count} Following
                            </label>
                            {
                                (props.routeName === "following") &&
                                <span className={navStyles.active}/>
                            }
                        </div>
                    }
                    <div onClick={() => { history.push(`${props.video === true ? '/channel' : ''}/user/${props.username}/followers`) }}
                         className={navStyles.page}>
                        {
                            user.privacy.follower_count === true && (!props.auth.isAuthenticated || user.id !== props.auth.user.info.id) &&
                            <label>
                                {numberToString(user.info.follower_count)} Follower{user.info.follower_count !== 1 ? "s" : ""}
                            </label>
                        }
                        {
                            user.privacy.follower_count === false && (!props.auth.isAuthenticated || user.id !== props.auth.user.info.id) &&
                            <label>
                                <LockIcon height={14} /> Followers
                            </label>
                        }
                        {
                            (props.auth.isAuthenticated && user.id === props.auth.user.info.id) &&
                            <label>
                                {numberToString(props.auth.user.info.follower_count)} Follower{props.auth.user.info.follower_count !== 1 ? "s" : ""}
                            </label>
                        }
                        {
                            (props.routeName === "followers") &&
                            <span className={navStyles.active}/>
                        }
                    </div>
                    {
                        // (user.privacy.likes === true || (props.auth.isAuthenticated && user.info.id === props.auth.user.info.id)) &&
                        <div onClick={() => { history.push(`${props.video === true ? '/channel' : ''}/user/${props.username}/likes`) }}
                             className={navStyles.page}>
                            <label>
                                {user.privacy.likes === false && <LockIcon height={14} />}Likes
                            </label>
                            {
                                (props.routeName === "likes") &&
                                <span className={navStyles.active}/>
                            }
                        </div>
                    }

                    {/* <div onClick={() => { history.push(`${props.video === true ? '/channel' : ''}/user/${props.username}/about`) }}
                         className={navStyles.page}>
                        <label>
                            About
                        </label>
                        {
                            (props.routeName === "about") &&
                            <span className={navStyles.active}/>
                        }
                    </div> */}

                    <div className={mL + ' ' + profileStyles.follow}>
                        {
                            width > MAX_MOBILE_WIDTH &&
                            <FollowButton
                                user={user}
                                followCallback={(status) => {
                                    if(status == "followed"){
                                        user.info.following = true;
                                    } else if(status == "requested"){
                                        user.info.following = false;
                                        user.info.requested = true;
                                    }
                                }}
                                unfollowCallback={() => {
                                    user.info.following = false;
                                    profileDispatch({ type: UPDATE_USER, payload: user });
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(ProfileContainer);
