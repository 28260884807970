// @flow

import React, {useEffect, useRef} from "react";
import { connect } from "react-redux";

import sidebarStyles from "../../../../../../../../css/layout/social/nav/sidebar.css";
import layoutStyles from "../../../../../../../../css/layout.css";
import formStyles from "../../../../../../../../css/form.css";

import FrownIcon from "../../../../../../../../assets/svg/icons/frown.svg";
import RocketIcon from "../../../../../../../../assets/svg/icons/rocket.svg";

import {UserType} from "../../../../../../../../util/types/UserTypes";

function Badges(props) {
    function nameSlice(name){
        if(name.length > 12){
            return name.slice(0, 12) + "...";
        } else {
            return name;
        }
    }

    function getBadges(){
        let badges = [];
        if(props.user.info.boosted == true){
            badges.push(
                <div className={layoutStyles.grey}>
                    <div className={formStyles.alert}>
                        <RocketIcon width="18"/>
                        <span style={{color: props.user.info.username_color}}>
                            {nameSlice(props.user.info.username)} is boosted!
                        </span>
                    </div>
                </div>
            )
        }

        if(badges.length == 0){
            badges.push(
                <div className={layoutStyles.grey}>
                    <div className={formStyles.alert}>
                        <FrownIcon width="18"/>
                        {props.user.info.username} has no badges.
                    </div>
                </div>
            )
        }

        return badges;
    }
    return [
        (
            <div key="badges-title" className={sidebarStyles.sidebarItem + ' ' + sidebarStyles.sidebarTitle}>
                <label>Badges</label>
            </div>
        ),
        (
            <div key="badges" className={sidebarStyles.sidebarObject}>
                {getBadges()}
            </div>
        )
    ]
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

Badges.propTypes = {
    user: UserType.isRequired,
}

export default connect(mapStateToProps)(Badges);
