import React, { useState, useEffect, useRef } from "react";
import {connect} from "react-redux";

import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";
import NewPostForm from "./QuickPostForm";
import NewPostFooter from "./QuickPostFooter";
import ContentLoader from "../../../../../../../util/components/ContentLoader";

import store from "../../../../../../../store";
import { getToken } from "../../../../../../../reducers/auth/actions";
import fetchCaptcha from "../../../../../../../util/captcha";

import axios from "axios";
import isMobile from "is-mobile";
import { API_URL, BODY_LENGTH } from "../../../../../../../util/constants";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";

import postModalStyles from "../../../../../../../css/layout/social/post-modal.css";
import postStyles from "../../../../../../../css/components/post.css";
import layoutStyles from "../../../../../../../css/layout.css";
import formStyles from "../../../../../../../css/form.css";
import {mB1} from "../../../../../../../css/layout.css";

function NewPost(props) {
    function handleUpgrade() { }

    function handleSecurityCode(e) {
        let value = e.target.value;

        setState(state => ({
            ...state,
            securityCode: value.toLowerCase()
        }))
    }

    function checkEnter(e) {
        if (e.key === "Enter") {
            handleSubmit();
        }
    }

    function CaptchaPlaceholder() {
        return(
            <ContentLoader
                width="100%"
                height="135"
                viewBox="0 0 100 135"
                preserveAspectRatio="none"
            >
                <rect x="0" y="0" width="100%" height="135" />
            </ContentLoader>
        )
    }

    const [state, setState] = useState({
        submitting: false,
        error: null,
        error_message: null,
        showCaptcha: false,
        captchaURL: null,
        captchaId: null,
        captchaLoading: true,
        securityCode: "",
        phase: 0
    });

    const [post, setPost] = useState({
        id: 0,
        reblog: null,
        author: props.auth.user,
        timestamp: new Date(),
        attachments: [],
        title: "",
        body: "",
        tags: [],

        favorites_count: 69,
        reblogs_count: 69,
        replies_count: 69,
       
    })

    const securityCodeRef = useRef();

    useEffect(() => {
        if(state.phase == 1 && state.showCaptcha){
            loadCaptcha();
        }
    }, [state.error, state.phase, state.showCaptcha])

    const {width} = useWindowDimensions();

    function changeBody(text){
        setPost(post => ({ ...post, body: text }))
    }

    function loadCaptcha() {
        fetchCaptcha().then(data => {
            setState(state => ({
                ...state,
                captchaURL: data.captcha,
                captchaId: data.id,
            }))
        });
    }

    async function handleSubmit() {
        if(props.auth.user.info.boosted == false && state.phase == 0){
            setState(state => ({ ...state, phase: 1, showCaptcha: true }));
            return;
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Auth-Token": await store.dispatch(getToken()),
            }
        };

        const data = new FormData();

        if(post.title.length > 0){
            data.append('title', post.title);
        }

        if(post.body.length > 0){
            data.append('body', post.body);
        }

        if(state.securityCode.length > 0){
            data.append('security_id', state.captchaId);
            data.append('security_code', state.securityCode);
        }

        post.tags.forEach(tag => {
            data.append('tags[]', tag);
        });

        // data.append('nsfw', manager.nsfw);
        // data.append('private', manager.private);
        // data.append('followers_only', manager.followersOnly);

        axios
            .post(API_URL + "/post", data, config)
            .then(res => {
                const Notification = () => (
                    <div>
                        Post made! <Link to={`/post/${res.data.data.post.id}`}>Click to view</Link>
                    </div>
                );

                props.onSubmit();

                toast(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            })
            .catch(err => {
                console.warn(err)
                if(err.response && err.response.data.errors && err.response.data.errors.content){
                    setState(state => ({ ...state, error: "CONTENT", submitting: false, showCaptcha: false }));
                    return;
                }

                if(err.response && err.response.data.errors && err.response.data.errors.captcha){
                    setState(state => ({ ...state, error: "WRONG_SECURITY_CODE", submitting: false, showCaptcha: true }));
                    loadCaptcha();
                    return;
                }

                if(err.response && err.response.data.errors && err.response.data.errors.storage){
                    setState(state => ({ ...state, error: "STORAGE", submitting: false, showCaptcha: false }));
                    return;
                }
                
                if(err.response && err.response.data.errors && err.response.data.errors.file_count){
                    setState(state => ({ ...state, error: "FILE_COUNT", error_message: err.response.data.errors.file_count, submitting: false, showCaptcha: false }));
                    return;
                }

                if(!err.response || err.response.status >= 300){
                    const Notification = () => (
                        <div>
                            {err.response.data.error ? err.response.data.error : "There was an error!"}
                        </div>
                    );

                    toast.error(<Notification />, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                setState(state => ({ ...state, submitting: false }));
            });
    }
    
    return (
        <div>
            {state.showCaptcha &&
            <div className={postStyles.post + ' ' + (props.grid ? (' ' + postStyles.grid) : (width > 800 ? mB1 : ""))} >
                <div className={postModalStyles.content}>
                    <div className={layoutStyles.m1}>
                        <div className={formStyles.formGroup}>
                            {
                                state.captchaURL &&
                                <img
                                    alt="captcha"
                                    className={layoutStyles.wF + ' ' + (state.captchaLoading ? layoutStyles.hide : '')}
                                    onLoad={() => { setState(state => ({ ...state, captchaLoading: false })) }}
                                    src={state.captchaURL}
                                />
                            }
                            {
                                (!state.captchaURL || state.captchaLoading) && <CaptchaPlaceholder />
                            }
                        </div>
                        <div className={formStyles.formGroup}>
                            <div className={formStyles.alert}>
                                <div>
                                    <p>Case insensitive.</p>
                                    {!isMobile() &&
                                        <p>You can <a onClick={handleUpgrade} className={layoutStyles.pointer}>upgrade your account</a> to remove captchas and ads.</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={formStyles.formGroup}>
                            {
                                state.error === "WRONG_SECURITY_CODE" && <label className={formStyles.invalidLabel}>Incorrect security code.</label>
                            }
                            <input
                                type="text"
                                ref={securityCodeRef}
                                onChange={handleSecurityCode}
                                onKeyPress={checkEnter}
                                value={state.securityCode}
                                disabled={state.submitting}
                                maxLength={4}
                                className={
                                    formStyles.input + ' ' +
                                    (state.error === "WRONG_SECURITY_CODE" ? formStyles.invalidInput : '')
                                }
                                placeholder="Security code"
                            />
                        </div>
                        <button
                            className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + layoutStyles.mL}
                            onClick={handleSubmit}>
                            Create Post
                        </button>
                    </div>
                </div>
            </div>
            }

            {!state.showCaptcha &&
            <div className={postStyles.post + ' ' + (props.grid ? (' ' + postStyles.grid) : (width > 800 ? mB1 : ""))} >
                <div style={{paddingLeft: "1rem", paddingTop: "0.5rem"}}>
                    <h4>Quickpost</h4>
                </div>
                <NewPostForm 
                    post={post}
                    changeBody={changeBody}
                />
                <NewPostFooter
                    disabled={state.submitting || post.body.length === 0 || post.body.length > BODY_LENGTH}
                    handleSubmit={handleSubmit}
                />
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(NewPost);