// @flow

import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";

import cardStyles from "../../../../css/layout/social/settings/card.css";
import formStyles from "../../../../css/form.css";
import layoutStyles from "../../../../css/layout.css";

import KeyIcon from "../../../../assets/svg/icons/key.svg";
import GnuPGIcon from "../../../../assets/svg/logos/gnupg.svg";

import Storage from "../../layout/parts/settings/account/Storage";
import TwoFactorEmail from "../../layout/parts/settings/twofactor/TwoFactorEmail";
import ChangeOrCurrentAccount from "../../layout/parts/settings/account/ChangeOrCurrentAccount";
import ConfirmEmail from "../../layout/parts/settings/account/ConfirmEmail";

import {AccountProvider} from "../../layout/parts/settings/account/context";
import { toast } from "react-toastify";
import axios from "axios";
import store from "../../../../store";
import { getRefreshToken, getToken } from "../../../../reducers/auth/actions";
import { API_URL } from "../../../../util/constants";

function Account(props) {

  let [sessionCount, setSessionCount] = useState(0)

  if(props.auth.user === null){
    return null;
  }

  async function endOtherSessions(){
    const config = {
      headers: {
          "Content-Type": "multipart/form-data",
          "Auth-Token": await store.dispatch(getToken()),
          "Refresh-Token": await store.dispatch(getRefreshToken()),
      }
    };

    axios.post(API_URL + "/auth/sessionLogout", config)
    .then(res => {

      const Notification = () => (
        <div>
            All other sessions have been logged out.
        </div>
      );

      toast(<Notification />, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    })
    .catch(err => {
        const Notification = () => (
          <div>
              There was an error!
          </div>
        );

        toast.error(<Notification />, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    )
  }

  useEffect(() => {
    let config = {
      headers: {
        "Auth-Token": store.dispatch(getToken()),
      }
    };
    axios.get(API_URL + "/@me/sessionCount", config)
    .then(res => {
      setSessionCount(res.data.data.count);
    }
    )
  }, []);

  return (
      <AccountProvider>
        <div>
          <h3>Account</h3>
          <div className={layoutStyles.tbRowM + " " + layoutStyles.mB1}>
            <div className={layoutStyles.tbCol12}>
              <div className={cardStyles.card}>
                <div className={cardStyles.cardBody}>
                  <ChangeOrCurrentAccount username={props.auth.user.info.username}
                                          email={(props.auth.user.info.email === null ? "" : props.auth.user.info.email)} />
                  {props.auth.user.confirm_email !== null && <hr />}
                  <ConfirmEmail />
                  <hr />
                  <div className={layoutStyles.tbRowM + " " + layoutStyles.mB1 + " " + layoutStyles.mT1}>
                    <div className={layoutStyles.tbCol12}>
                      <div className={cardStyles.card + " " + layoutStyles.mT1}>
                        <div className={cardStyles.cardBody}>
                          <h4 className={layoutStyles.mN}>Login Sessions</h4>
                        </div>
                        <div className={cardStyles.cardFooter}>
                          <div className={layoutStyles.mR1}>
                            {sessionCount === 0 ?
                              <h3>Getting session count...</h3>
                              :
                              <h3>{sessionCount} login session{sessionCount === 1 ? "" : "s"}</h3>
                            }
                            <br/>
                            <button className={formStyles.button + " " + formStyles.buttonSecondary + " " + layoutStyles.mL}
                            onClick={endOtherSessions}>Log out all sessions except this one</button>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <Storage />
                  <hr />
                  <div className={layoutStyles.tbRowM + " " + layoutStyles.mB1 + " " + layoutStyles.mT1}>
                    <div className={layoutStyles.tbCol12}>
                      <h4 className={layoutStyles.mN}>Two Factor Options</h4>
                      <div className={cardStyles.card + " " + layoutStyles.mT1}>
                        <TwoFactorEmail />
                        <div className={cardStyles.cardFooter}>
                          <div className={layoutStyles.mR1}>
                            <GnuPGIcon width="35" />
                          </div>
                          <div>
                            <h4 className={layoutStyles.mN}>PGP Key</h4>
                            {/*Enter your PGP key and decrypt a code..*/}
                            <p className={formStyles.small + " " + formStyles.muted}>
                              * Coming soon
                            </p>
                          </div>
                          <button className={formStyles.button + " " + layoutStyles.mL} disabled={true}>Select</button>
                        </div>
                        <div className={cardStyles.cardFooter}>
                          <div className={layoutStyles.mR1}>
                            <KeyIcon width="35" />
                          </div>
                          <div>
                            <h4 className={layoutStyles.mN}>FreeOPT Authenticator</h4>
                            {/*Enter a code or scan a QR.*/}
                            <p className={formStyles.small + " " + formStyles.muted}>
                              * Coming soon
                            </p>
                          </div>
                          <button className={formStyles.button + " " + layoutStyles.mL} disabled={true}>Select</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccountProvider>
  );
}

const mapStateToProps = state => {
  const { auth } = state;
  return { auth: auth };
};

export default connect(mapStateToProps)(Account);
