import React, { useEffect, useState } from "react";
import { batch, connect } from "react-redux";

import messageStyles from "../../../../css/layout/chat/messages.css";
import searchStyles from "../../../../css/components/search.css";

import isMobile from "is-mobile";
import Message from "./Message";
import InputBox from "./InputBox";
import store from "../../../../store";

import { API_URL } from "../../../../util/constants";

import axios from "axios";

import { format } from "date-fns";

import TimesIcon from "../../../../assets/svg/icons/times.svg"

function ChatHistory(props) {
    const mobile = isMobile();
    
    const [lastMessageID, setLastMessageID] = useState(null);
    const [typing, setTyping] = useState(false);
    const [enableSnapToBottom, setEnableSnapToBottom] = useState(false);
    
    useEffect(() => {
        if(props.chat.history.length > 0 && lastMessageID !== props.chat.history[props.chat.history.length - 1].id) {
            const history = document.getElementById("history");
            history.scrollTop = history.scrollHeight
        }

        if(props.chat.history.length > 0){
            setLastMessageID(props.chat.history[props.chat.history.length - 1].id);
        }
    }, [props.chat.history]);

    useEffect(() => {
        setEnableSnapToBottom(false)

        const typingInterval = setInterval(() => {
            checkTypingStatus()
        }, 2000)

        return () => clearInterval(typingInterval)
    }, [props.chat.typingIDs, props.chat.currentChannel]);

    function checkTypingStatus(){
        if(!props.chat.typingIDs[props.chat.currentChannel]){
            return
        }
        if(props.chat.typingIDs[props.chat.currentChannel] < Date.now()){
            setTyping(false)
        } else{
            if(!typing){
                setTyping(true)
            }
        }
    }

    let canFetch = false;
    function onHistoryScroll(e) {
        if(e.target.scrollTop === 0 && canFetch){
            try {
                if(props.chat.performingJump) {
                    props.getCertainHistory(props.getFirstLast(), "before");
                } else {
                    props.fetchPreviousMessages(true);
                }
            } catch (e) {
                console.log(e)
            }
        } else if (props.chat.performingJump && canFetch) {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if(bottom) {
                props.getCertainHistory(props.getFirstLast(false), "after");
            }
        } 
        
        else{
            canFetch = true;
        }

        const scroll = e.target.scrollTop - e.target.scrollHeight + e.target.clientHeight
        if(scroll > -2000){
            if(enableSnapToBottom){
                setEnableSnapToBottom(false)
            }
        } else{
            if(!enableSnapToBottom){
                setEnableSnapToBottom(true)
            }
        }
    }

    function keyPress(e, attachment){
        const textContent = document.getElementById("chat-text-input").value
        const trimmedContent = textContent.trim()

        if(e.key !== "Enter"){
            try{
                props.sendIsTyping()
            } catch{}
                //
            return
        }

        if(attachment == null){
            if(!trimmedContent || trimmedContent.length > 1000){
                return
            }
        }

        return sendMessage(trimmedContent, attachment)
    }

    function sendMessage(trimmedContent, attachment){
        if(!attachment && trimmedContent.length > 1000){
            return
        }

        const temp_id = generateTempID()

        let payload = {
            type: "message",
            sender: props.auth.user.info.id,
            recipient: props.chat.currentChannel,
            content: trimmedContent,
            temp_id: temp_id,
            id: props.auth.user.info.id,
            refresh_token: props.auth.refreshToken,
            parent: props.chat.replyingTo ? props.chat.replyingTo : null, 
            attachments: attachment != null ? [] : null
        }

        let message = {
            type: "message",
            recipient: props.chat.currentChannel,
            content: trimmedContent,
            picture_url: `${API_URL}/social/avatar/${props.auth.user.info.id}`,
            username: props.auth.user.info.username,
            mine: true,
            sent: false,
            temp_id: temp_id,
            parent: props.chat.replyingTo ? props.chat.replyingTo : null,
            timestamp: new Date()
        }

        if(attachment != null){
            const formData = new FormData()
            formData.append("file", attachment)

            return axios.post(API_URL + "/chat/attachment", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Auth-Token": props.auth.token
                }
            }).then(res => {
                payload.attachments.push({
                    sha256: res.data.data.sha256,
                    code: res.data.data.code,
                    ext: res.data.data.ext,
                })
                message.attachments = [{
                    file: {
                        url: `${API_URL}/social/${res.data.data.sha256}${res.data.data.ext}`
                    }
                }]
                batch(() => {
                    store.dispatch({ type: "SET_REPLY_ID", payload: null });
                    store.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: [...props.chat.history, message] });
                });
                props.sendMessage(payload)
                return true
            }).catch(err => {
                console.log(err)
                return false
            })
        } else{
            batch(() => {
                store.dispatch({ type: "SET_REPLY_ID", payload: null });
                store.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: [...props.chat.history, message] });
            });
            props.sendMessage(payload)
            return true
        }
    }

    function generateTempID(length=24) {
        const characters = '0123456789';
        
        let token = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            token += characters[randomIndex];
        }
        
        return token;
    }

    function handleDelete(id){
        props.handleDelete(id)
    }

    function handleMessageEdit(id, editedMessage, setMessage, setMessageEdited) {
        const data = {
            "id": id,
            "message": editedMessage,
        };
    
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        };
    
        axios.put(`${API_URL}/chat/edit`, data, config)
        .then(response => {
            setMessage(editedMessage);
            setMessageEdited(true);
        })
        .catch(error => {
            console.error("Error editing message.");
        });
    }

    function handleReply(id){
        store.dispatch({ type: "SET_REPLY_ID", payload: id });   
    }

    function removeReplyUI(){
        store.dispatch({ type: "SET_REPLY_ID", payload: null });  
    }

    function shortenTextforReply(text){
        const shorten = mobile ? 10 : 50
        if(text.length > shorten){
            return text.slice(0, shorten) + "..."
        } else {
            return text
        }
    }

    function handleParentContent(id, callback) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        };
    
        axios.get(`${API_URL}/chat/message/${id}`, config)
        .then(response => {
            if(response.data.content == "" && response.data.attachments) {
                response.data.content = "(Attachement)"
            }
            callback(({ message: shortenTextforReply(response.data.content), picture_url: `${API_URL}/social/avatar/${response.data.sender}` }));
        })
        .catch(error => {
            callback({ message: null, picture_url: null });
        });
    }
    
    function adjustEditTextHeight(editingTextAreaRef) {
        const textarea = editingTextAreaRef;
        textarea.style.height = 'auto';
        let scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${scrollHeight}px`;
    }

    function handleReplyJump(id) {
        batch(() => {
            store.dispatch({ type: "SET_JUMPED_MESSAGE_ID", payload: id });
            store.dispatch({ type: "SET_PERFORMING_JUMP", payload: true });
        });
        // --> to useEffect
    }

    function snapToBottom(){
        setEnableSnapToBottom(false)
        const history = document.getElementById("history");
        history.scrollTop = history.scrollHeight
    }

    useEffect(() => {
        if(props.chat.performingJump) {
            let messageElement = document.getElementById(props.chat.jumpedMessageID)
            if(messageElement != null) {
                props.highlightAndScrollToElement(messageElement)
                store.dispatch({ type: "SET_PERFORMING_JUMP", payload: false });
            } else if (messageElement == null) {
                store.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: [] });    
                props.getCertainHistory(props.chat.jumpedMessageID, "batch")
            }
        }
    }, [props.chat.performingJump, props.chat.jumpedMessageID]);

    return (
        <div
            style={mobile ? { width: "100%", border: "none" } : {width: "100%", height: "calc(100vh - 65px)"}}
            className={`${messageStyles.container} ${mobile && messageStyles.containerMobile}`}>
            <div className={messageStyles.historyDivContainer}>
                <div
                    id="history"
                    style={{ overflowY: "scroll", flexGrow: 1 }}
                    onScroll={(e) => onHistoryScroll(e)}>
                    {props.chat.history != null &&
                        props.chat.history.map((message, index) => {
                            let showProfilePicture = false;
                            if(!props.chat.bubblesLayout) {
                                const isDifferentSender = index === 0 || props.chat.history[index - 1].mine !== message.mine;
                                const isMoreThan15Minutes = index === 0 || (new Date(message.timestamp) - new Date(props.chat.history[index - 1].timestamp)) > 15 * 60 * 1000;
                                showProfilePicture = isDifferentSender || isMoreThan15Minutes;
                            }
                            
                            // MediaWrapper expects `attachments` in a specific format
                            
                            if(message.attachments != null && message.attachments.length > 0 && !message.attachments[0].file){
                                message.attachments =
                                [{
                                    file: {
                                        url: `${API_URL}/social/${message.attachments[0].sha256}${message.attachments[0].ext}`
                                    }
                                }]
                            }

                            const showDivider = index > 0 &&
                            new Date(message.timestamp) - new Date(props.chat.history[index - 1].timestamp) > 24 * 60 * 60 * 1000;
                            
                            return (
                                <div key={index} className={`${props.chat.bubblesLayout ? (mobile ? messageStyles.bubblesLayoutMobile : messageStyles.bubblesLayoutDesktop) : ''}`}>
                                    <>
                                    {showDivider &&
                                        <div className={messageStyles.dividerFlex} >
                                            <div className={messageStyles.dividerLine}></div>
                                            <p className={messageStyles.dividerDate}>
                                                {format(message.timestamp, "do MMMM yyyy")}
                                            </p>
                                            <div className={messageStyles.dividerLine}></div>
                                        </div>
                                    }
                                    <Message
                                        id={message.id ? message.id : message.temp_id}
                                        key={message.id ? message.id : message.temp_id}
                                        sent={message.sent}
                                        failed={message.failed}
                                        mine={message.mine}
                                        deleted={message.deleted}
                                        message={message.content}
                                        picture_url={message.picture_url}
                                        user={message.mine ? props.auth.user : props.user}
                                        attachments={message.attachments}
                                        timestamp={message.timestamp}
                                        handleDelete={handleDelete}
                                        handleReply={handleReply}
                                        handleMessageEdit={handleMessageEdit}
                                        parent={message.parent}
                                        replyUsername={message.parent ? message.mine ? props.user.info.username : props.auth.user.info.username : null}
                                        handleParentContent={handleParentContent}
                                        bubblesLayout={props.chat.bubblesLayout}
                                        adjustEditTextHeight={adjustEditTextHeight}
                                        showProfilePicture={message.parent ? true : showProfilePicture}
                                        edited={message.edited}
                                        handleReplyJump={handleReplyJump}
                                        shortenText={shortenTextforReply}
                                    />
                                    </>
                                </div>
                            );
                        })
                    }
                </div>
                {props.chat.replyingTo &&
                    <div className={mobile ? messageStyles.replyingBarMobile : messageStyles.replyingBar}
                        onClick={removeReplyUI}>
                        <div>Replying to {props.user.info.username}</div>
                        <div><TimesIcon width={"20px"} height={"20px"} /></div>
                    </div>
                } 
                {enableSnapToBottom &&
                    <div className={mobile ? searchStyles.snapToBottomMobile : searchStyles.snapToBottom}
                        onClick={snapToBottom}>
                        Snap to bottom
                    </div>
                }               
                <div className={messageStyles.inputContainer} style={{marginTop: (enableSnapToBottom || props.chat.replyingTo) ? "-15px" : ""}}>
                    <InputBox keyPress={keyPress} sendMessage={sendMessage} user={props.user} />
                </div>
                <p className={messageStyles.typing} style={{opacity: typing ? 1 : 0}}>{props.user.info.username} is typing...</p>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
});

export default connect(mapStateToProps)(ChatHistory);