// @flow

import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {connect} from "react-redux";

import postStyles from "../../../../../../../../css/components/post.css";

import LoadingIcon from "../../../../../../../../assets/loading/dots.svg";

import WaveformComponent from "../../../../../../../../util/components/WaveformComponent";
import {API_URL} from "../../../../../../../../util/constants";
import PlyrAudioComponent from "../../../../../../../../util/components/PlyrAudioComponent";

import WaveSurfer from "wavesurfer.js";

const Audio = (props) => {
    const audioRef = useRef(null);

    let [manager, setManager] = useState({
        wave: null,
        large: false,
    });

    useEffect(() => {
        if(props.file.file.size > 100000000){
            setManager(manager => ({
                ...manager,
                large: true,
            }));
            return;
        }


        const data = new FormData();
        data.append('file', props.file.file);

        const wavesurfer = WaveSurfer.create({
            container: audioRef.current,
            waveColor: "#999",
            progressColor: "#555",
        });
        
        wavesurfer.on("ready", async () => {
            const waveformData = await wavesurfer.exportPCM(512, 0, true);
            setManager((manager) => ({
            ...manager,
            wave: JSON.parse(waveformData),
            }));
            wavesurfer.destroy();
        });
        
        wavesurfer.load(props.file.blob);

    }, [props.file.blob])

    return (
        <div className={postStyles.audio}>
            <audio style={{display: "none"}} ref={audioRef} src={props.file.blob} controls />
            {
                manager.wave !== null &&
                <div style={{width: "90%"}}>
                    <WaveformComponent id={props.file.id} options={{
                        track: props.file.blob,
                        wave: manager.wave
                    }}/>
                </div>
            }
            {
                manager.large &&
                <PlyrAudioComponent sources={{
                    type: 'audio',
                    sources: [
                        {
                            src: props.file.blob,
                        },
                    ],
                }}/>
            }
            {
                manager.wave === null && !manager.large &&
                <div className={postStyles.loading}>
                    <LoadingIcon height={64} />
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(Audio);
