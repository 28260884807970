// @flow

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import postStyles from "../../../../../../css/components/post.css";
import {mB1} from "../../../../../../css/layout.css";

import PinIcon from "../../../../../../assets/svg/icons/thumbtack.svg";
import LockIcon from "../../../../../../assets/svg/icons/lock.svg";
import ReblogIcon from "../../../../../../assets/svg/icons/reblog.svg";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";

import {makeGetPosts} from "../../../../../../reducers/social/selectors";
import {PostType} from "../../../../../../util/types/PostTypes";
import {UserType} from "../../../../../../util/types/UserTypes";
import useWindowDimensions from "../../../../../../util/hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { formatDistanceToNowStrict } from "date-fns";

function DefaultPost(props) {
    const {width} = useWindowDimensions();

    if(props.post == null){
        return null;
    }

    if(props.post.attachments == null){
        return null
    }

    if(props.post.author == null){
        return null
    }
    
    let key = props.post.id
    if(props.post.reblog != null){
        key = `reblog-${props.post.reblog.id}`
    }
    if(props.post.promoted){
        // This promotion may have already appeared in the feed.
        // To prevent key conflicts, we add a random number to the key.
        key = `${key}-${Math.floor(Math.random() * 1000)}`
    }

    return (
        <div className={postStyles.post + ' ' + (props.grid ? (' ' + postStyles.grid) : (width > 800 ? mB1 : ""))}
        style={props.isReblog ? {width: "90%"} : {}}
        key={props.post.reblog ? `reblog-${props.post.reblog.id}` : props.post.id}
        id={props.post.id}>
            {
                props.post.pinned && props.showPin &&
                <div className={postStyles.pinned}>
                    <PinIcon height={14} /> <p>Pinned post by {props.post.author.info.username}</p>
                </div>
            }
            {
                props.post.visibility === "private" &&
                <div className={postStyles.pinned}>
                    <LockIcon height={14} /> <p>Private post</p>
                </div>
            }
            {
                props.post.visibility === "followers" &&
                <div className={postStyles.pinned}>
                    <LockIcon height={14} /> <p>Followers only post</p>
                </div>
            }
            {
                props.post.promoted &&
                <div className={postStyles.pinned}>
                    <PinIcon height={14} /> <p>Promoted post</p>
                </div>
            }

            {/* Reblog with comment */}
            {props.post.reblog != null && (props.post.body != null || props.post.attachments.length > 0) &&
            <div>
                <Header
                    isReblog={props.isReblog}
                    isOnPost={true}
                    post={props.post}
                    previewProfile={props.previewProfile} />
                <Body post={props.post}
                    truncate={props.truncate}
                    profile={props.profile}
                    previewProfile={props.previewProfile}
                    profileUser={props.profileUser}
                    isReblog={props.isReblog}
                    />
                <Footer post={props.post} grid={props.grid} />
            </div>
            }

            {/* Reblog */}
            {props.post.reblog != null && props.post.body == null && props.post.attachments.length === 0 &&
            <div>
                <div id={`reblog-${props.post.reblog.id}`}
                    style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                    <ReblogIcon height={14} style={{marginLeft: "15px", color: "darkgray"}} />
                    <p style={{margin: "0px", padding: "0p", marginLeft: "5px", color: "darkgray"}}>Reblogged by <Link to={`/user/${props.post.author.info.username}`}>{props.post.author.info.username}</Link> {formatDistanceToNowStrict(new Date(props.post.timestamp), { addSuffix: true })} </p>
                </div>
                <Header
                    isReblog={props.isReblog}
                    isOnPost={true}
                    post={props.post.reblog}
                    previewProfile={props.previewProfile} />
                <Body post={props.post.reblog}
                    truncate={props.truncate}
                    profile={props.profile}
                    previewProfile={props.previewProfile}
                    profileUser={props.profileUser}
                    isReblog={props.isReblog}
                    />
                <Footer post={props.post.reblog} grid={props.grid} />
            </div>
            }

            {/* Normal post */}
            {props.post.reblog == null &&
            <div>
                <Header
                    isReblog={props.isReblog}
                    isOnPost={true}
                    post={props.post}
                    previewProfile={props.previewProfile} />
                <Body post={props.post}
                    truncate={props.truncate}
                    profile={props.profile}
                    previewProfile={props.previewProfile}
                    profileUser={props.profileUser}
                    isReblog={props.isReblog}
                    />
                <Footer post={props.post} grid={props.grid} />
            </div>
            }
        </div>
    );
}

DefaultPost.propTypes = {
    postId: PropTypes.string.isRequired,
    post: PostType,
    profile: PropTypes.bool,
    profileUser: UserType,
    truncate: PropTypes.bool,
    grid: PropTypes.bool,
    previewProfile: PropTypes.bool,
};

DefaultPost.defaultProps = {
    profile: false,
    truncate: true,
    grid: false,
    previewProfile: false,
}

const mapStateToProps = () => {
    const getPosts = makeGetPosts();
    return (state, props) => {

        let post = getPosts(state, props);
        if(post == null){
            post = props.post;
        }

        return { post: post };
    };
};

export default connect(mapStateToProps)(DefaultPost);
