// @flow

import React, {useState} from "react";

import postStyles from "../../../../../../../../css/components/post.css";

import ContentLoader from "../../../../../../../../util/components/ContentLoader";

const Image = (props) => {
    let [manager, setManager] = useState({
        loading: true
    });

    function Placeholder({ height="100%", width="100%" }) {
        return(
            <ContentLoader
                width={width}
                height={height}
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
            >
                <rect x="0" y="0" width={width} height={height} />
            </ContentLoader>
        );
    }

    function onLoad() {
        setManager({ loading: false });
        try{
            props.onLoad();
        } catch(e) {}
    }

    return (
        <div className={postStyles.image} style={props.isThumbnail && {width: "100%"}}>
            {manager.loading && <Placeholder height={props.height} width={props.width} /> }
            <img style={{maxHeight: `${props.height}px`, maxWidth: `${props.width}px`, objectFit: "contain", position: "relative", overflow: "hidden", borderRadius: props.borderRadius, display: (manager.loading ? "none" : "block")}}
                onClick={() => { props.setSlide(props.pos) }}
                onLoad={() => onLoad()}
                src={props.url || props.image.file.url}
            />
        </div>
    );
};

export default Image;
