import React, {useState} from "react";

import postStyles from "../../../../../../../css/components/post.css";
import formStyles from "../../../../../../../css/form.css";
import layoutStyles from "../../../../../../../css/layout.css";

import { ManageProvider } from "../../../post/manage/context";
import PostModal from "../../../post/manage/PostModal";

function NewPostFooter(props) {
    const [openModal, setOpenModal] = useState(false);

    function openAdvanced(){
        setOpenModal(true);
    }

    function closeAdvanced(){
        setOpenModal(false);
    }

    return (
        <div className={postStyles.footer} style={{display: "flex", alignItems: "center"}}>
            {openModal &&
            <ManageProvider>
                <PostModal closeModal={() => closeAdvanced()} />
            </ManageProvider>
            }
            <button className={formStyles.button} onClick={openAdvanced}>
                Advanced
            </button>
            {props.disabled ?
            <button className={formStyles.button + ' ' + formStyles.buttonDisabled + ' ' + layoutStyles.mL}>
                Create Post
            </button>
            :
            <button className={formStyles.button + ' ' + formStyles.buttonPrimary + ' ' + layoutStyles.mL} onClick={props.handleSubmit}>
                Create Post
            </button>
            }
        </div>
    )
}

export default NewPostFooter;