import React, {useState} from "react";
import ContentLoader from "../../../../util/components/ContentLoader";
import MediaWrapper from "../../layout/parts/social/post/media/MediaWrapper";

function Attachment(props){
    const [loaded, setLoaded] = useState(false)

    function onLoad(){
        setLoaded(true)
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: !props.mine && "flex-start",
            marginLeft: props.mine && "flex-end",
        }}>
            <MediaWrapper
                onLoad={onLoad}
                attachments={[{file: {url: props.url}}]}
                height={loaded ? "500" : "0"}
                borderRadius="5px"
                center={false}
            />
            {!loaded &&
            <div style={{height: "500px", width: "300px"}}>
                <ContentLoader
                width="300px"
                height="500px"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                >
                    <rect x="0" y="0" width="300px" height="500px" />
                </ContentLoader>
            </div>
            }
        </div>
    )
}

export default Attachment;