import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import useWindowDimensions from '../../../../../util/hooks/useWindowDimensions';

import postStyles from '../../../../../css/components/post.css';

import isMobile from 'is-mobile';

function Ad(props){
    return(null)

    const {width} = useWindowDimensions()
    const mobile = isMobile()
        
    useEffect(() => {
        try{
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }catch(e){
            console.log(e)
        }
    }, []);

    let style;

    if(props.grid){
        style = {
            marginBottom: "15px",
            maxWidth: "400px",
            minWidth: "400px",
            maxHeight: "250px",
            minHeight: "100px",
        }
    } else{
        style = {
            marginBottom: "15px",
            maxWidth: props.maxWidth ? props.maxWidth : `${width - 10}px`,
            minWidth: props.minWidth ? props.minWidth : mobile ? `${width-10}px` : "600px",
            maxHeight: props.maxHeight ? props.maxHeight : "100%",
            minHeight: props.minHeight ? props.minHeight : "100px",
        }
    }

    // style.border = "1px solid red"

    return (
    <div
        className={postStyles.post + " " + props.grid ? postStyles.grid : ""}
        style={style}
        >
        <ins className="adsbygoogle"
        style={{
            display: "block",
            width: style.minWidth,
            height: style.minHeight
        }}
        data-ad-client="ca-pub-9365032194420637"
        data-ad-slot="5144740188"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
    )

    // <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9365032194420637"
    //     crossorigin="anonymous"></script>
    // <!-- Horizontal Responsive Test -->
    // <ins class="adsbygoogle"
    //     style="display:block"
    //     data-ad-client="ca-pub-9365032194420637"
    //     data-ad-slot="5144740188"
    //     data-ad-format="auto"
    //     data-full-width-responsive="true"></ins>
    // <script>
    //     (adsbygoogle = window.adsbygoogle || []).push({});
    // </script>

    return (null)
    let source = "https://web.adblade.com/js/ads/async/show.js";
    let script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";

    useEffect(() => {
        script.src = source;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    let id = props.theme.theme == "dark" ? `47817-2656829556` : `47820-4212003736`

    return(
        <div style={{height: "fit-content", width: "fit-content"}}>
            <ins className="adbladeads" data-cid={id} data-host="web.adblade.com" data-tag-type="4" data-protocol="http"></ins>
        </div>
    )
}

const mapStateToProps = state => {
    const { social, theme, auth } = state;
    return { social: social, theme: theme, auth: auth };
};

export default connect(mapStateToProps)(Ad);