import React, { useState } from "react";
import { connect } from "react-redux";

import store from "../../../../store";

import { chip, chipText } from './ViewSettings.css'

function ViewSettings(props){
    const [grid, setGrid] = useState(props.social.grid)
    const [vidsOnly, setVidsOnly] = useState(props.social.vidsOnly)

    function handleGrid(){
        setGrid(!grid)
        store.dispatch({type: "SET_GRID", payload: !grid}); // Invert it again because `grid` won't be updated until the next call to `setGrid`.
    }

    function handleVids(){
        setVidsOnly(!vidsOnly)
        store.dispatch({type: "SET_VIDSONLY", payload: !vidsOnly});
        store.dispatch({type: "RESET_FEED"});
    }

    return(
        <div style={{paddingLeft: "5px", paddingRight: "5px", display: "flex", alignItems: "center", gap: "15px"}}>
            <div className={chip} onClick={handleGrid}>
                <span className={chipText}>
                    {grid ? "Grid" : "List"}
                </span>
            </div>
            <div className={chip} onClick={handleVids}>
                <span className={chipText}>
                    {vidsOnly ? "Videos" : "All"}
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { social } = state;
    return { social: social };
}

export default connect(mapStateToProps)(ViewSettings);