// @flow

import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";

import mainStyles, {divider, postContainer} from "../../../../../css/layout/social/container/main.css";
import {flex, mB1, mT1} from "../../../../../css/layout.css";

import Container from "../../../layout/parts/social/feed/FeedContainer";
import DefaultPost from "../../../layout/parts/social/post/DefaultPost";

import store from "../../../../../store";
import {fetchPosts} from "../../../../../reducers/social/actions";
import {formatBreakDate} from "../../../../../util/date";
import PeriodDropdown from "../../../layout/parts/social/feed/components/PeriodDropdown";
import Error from "../../../layout/parts/social/feed/components/Error";
import Empty from "../../../layout/parts/social/feed/components/Empty";
import Loading from "../../../layout/parts/social/feed/components/Loading";
import formStyles from "../../../../../css/form.css";
import useWindowDimensions from "../../../../../util/hooks/useWindowDimensions";

import Switch from "../../../layout/parts/switch/Switch";
import Masonry from 'react-masonry-component';

import Ad from "../ad/PostAd";
import FixedImageAd from "../ad/FixedImageAd";

function Trending(props) {
    const gridRef = useRef();
    const masonryRef = useRef();
    const { width } = useWindowDimensions();
    
    useEffect(() => {
        store.dispatch({type: "RESET_FEED"});
    }, [props.social.vidsOnly])

    function fetch() {
        let params = {};

        params.offset = props.social.offset;
        params.period = props.social.period;
        {props.social.vidsOnly && (params.type = "video")}

        store.dispatch(fetchPosts("/post/feed/trending", params, "posts"));
    }

    let lastDate = null;
    let posts = [];
    let postCount = 0
    let boosted = false;
    try{
        boosted = props.auth.user.info.boosted
    } catch(e){}
    props.social.posts.map((post, i) => {
        postCount++;
        // if(!grid && lastDate !== formatBreakDate(post.timestamp)){
        //     lastDate = formatBreakDate(post.timestamp);

        //     posts.push(
        //         <div key={formatBreakDate(post.timestamp) + "-" + post.id} className={divider + (width > 800 ? ' ' + mB1 : "")}>
        //             {formatBreakDate(post.timestamp)}
        //         </div>
        //     );
        // }
        if (!boosted) {
            if(postCount % 25 === 0 && postCount !== 0){
                posts.push(<Ad key={i + "-ad"} grid={props.social.grid}/>);
            }
        }

        if(postCount == 3 || postCount % 15 == 0){
            posts.push(<FixedImageAd />)
            posts.push(<div style={{height: "10px"}}></div>)
        }

        posts.push(<DefaultPost key={post.id} grid={props.social.grid} postId={post.id}/>);
    });

    return (
        <Container fetchPosts={fetch}>
            <main ref={gridRef}>
            <div style={{marginBottom: "10px"}}>
                
            </div>
            {(!props.social.loading || props.social.error) ?
                <div style={{gap: "10px"}} className={flex + ' ' + mainStyles.optionsContainer}>
                    <PeriodDropdown />
                </div>
            :""}
            {props.social.grid ?
                <Masonry ref={(c) => { masonryRef.current = c; }} options={{gutter: 5}} enableResizableChildren={true} updateOnEachImageLoad={true}>
                    {posts}
                </Masonry>
            :
                <div className={postContainer}>
                    {posts}
                </div>
            }
            <div className={postContainer}>
                {
                    props.social.error &&
                    <div className={mT1}>
                        <Error />
                    </div>
                }
                {
                    !props.social.loading && !props.social.error && props.social.posts.length === 0 &&
                    <div className={mT1}>
                        <Empty />
                    </div>
                }
                {
                    props.social.loading && !props.social.reachedEnd &&
                    <div className={mT1}>
                        <Loading />
                    </div>
                }
                {
                    props.social.reachedEnd &&
                    <div className={formStyles.alert + ' ' + mT1}>
                        You have reached the end!
                    </div>
                }
            </div>
        </main>
        </Container>
    );
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(Trending);
