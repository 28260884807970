// @flow

import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import postStyles from "../../../../../../../css/components/post.css";
import {button} from "../../../../../../../css/form.css";
import {mL} from "../../../../../../../css/layout.css";

import EyeIcon from "../../../../../../../assets/svg/icons/eyeSlash.svg";
import ReplyIcon from "../../../../../../../assets/svg/icons/comments.svg";
import EyeSlashIcon from "../../../../../../../assets/svg/icons/eyeSlash.svg";

import FavoriteButton from "./footer/FavoriteButton";
import ReblogButton from "./footer/ReblogButton";
import RepliesContainer from "./footer/RepliesContainer";
import CommentsModal from "./footer/CommentsModal";

import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";

import history from "../../../../../../../util/history";
import isMobile from "is-mobile";
import Info from "./header/Info";

import { Link } from "react-router-dom";

function Footer(props) {
    let [manager, setManager] = useState({
        viewReplies: false,
        commentModal: false,
        post: props.post
    });

    const { width } = useWindowDimensions();

    let post = props.post.reblog !== null && (props.post.body === null && props.post.attachments.length === 0)
        ? props.post.reblog : props.post;

    if(post.author == null){
        return null
    }

    function viewReplies() {
        setManager(manager => ({
            ...manager,
            viewReplies: !manager.viewReplies
        }));
    }

    function viewCommentModal() {
        setManager(manager => ({
            ...manager,
            commentModal: !manager.commentModal
        }));
    }

    function handleClick(e){
        return // TODO: Temporarily disabled, add to settings.
        if(isMobile()) return;
        if(e.target.nodeName.toLowerCase() != "div") return
        history.push(`/post/${post.id}`)
    }

    return (
        <div className={postStyles.footer}
        onClick={(e) => handleClick(e)} key={`footer-${props.post.id}`}>
            <div className={postStyles.interactions}>
                <FavoriteButton post={props.post}/>
                {!post.reblog && !post.private && !post.followers_only ?
                <ReblogButton post={post}/>
                :""}
                {   (post.author.privacy == null || post.author.privacy.reply == true || post.author.privacy.reply == null) &&
                    (width > 1000) &&
                    <button className={button + ' ' + postStyles.interaction  + ' ' + mL + ' ' + (manager.viewReplies ? postStyles.view : '')}
                            onClick={viewReplies}>
                        {manager.viewReplies ? <EyeIcon height="16" /> : <ReplyIcon height="16" />}
                        <span>{post.replies_count}</span>
                    </button>
                }
                {   (post.author.privacy == null || post.author.privacy.reply == true || post.author.privacy.reply == null) &&
                    (width <= 1000) &&
                    <button className={button + ' ' + postStyles.interaction  + ' ' + mL} onClick={viewCommentModal}>
                        <ReplyIcon height="16" />
                        <span>{post.replies_count}</span>
                    </button>
                }
            </div>
            {
                manager.viewReplies &&
                <RepliesContainer post={post} />
            }
            {
                manager.commentModal &&
                <CommentsModal post={post} close={viewCommentModal} />
            }
            {props.post.suppressed &&
            <div style={{marginTop: "10px", padding: "15px", border: "1px solid pink", borderRadius: "10px", textAlign: "center"}}>
                <div style={{display: "flex", alignmentBaseline: "central", justifyContent: "center"}}>
                    <EyeSlashIcon height="26" width="26" style={{height: "26", width: "26"}} />
                    <p style={{margin: 0, padding: 0, marginLeft: "10px"}}>This post may have limited visibility.</p>
                </div>
                <p>Users will still see this post by visiting the author's profile.</p>
                <p><Link to="/support/pages/suppression">Learn more</Link></p>
            </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

Footer.propTypes = {
    grid: PropTypes.bool,
}

Footer.defaultProps = {
    grid: false,
}

export default connect(mapStateToProps)(Footer);
