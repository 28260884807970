// @flow

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { mT1 } from "../../../../../../../../css/layout.css";
import postStyles from "../../../../../../../../css/components/post.css";

import LoadingIcon from "../../../../../../../../assets/loading/dots.svg";

import WaveformComponent from "../../../../../../../../util/components/WaveformComponent";
import { API_URL } from "../../../../../../../../util/constants";
import PlyrAudioComponent from "../../../../../../../../util/components/PlyrAudioComponent";

import WaveSurfer from "wavesurfer.js";

const Audio = (props) => {
  const audioRef = useRef(null);
  
  const [manager, setManager] = useState({
    wave: null,
    large: false,
    error: false,
  });

  useEffect(() => {
    if (props.file.file.file_size > 0.1) {
      setManager((manager) => ({
        ...manager,
        large: true,
      }));

      return;
    }

    const wavesurfer = WaveSurfer.create({
        container: audioRef.current,
        waveColor: "#999",
        progressColor: "#555",
    });
    
    wavesurfer.on("ready", async () => {
        const waveformData = await wavesurfer.exportPCM(512, 0, true);
        setManager((manager) => ({
        ...manager,
        wave: JSON.parse(waveformData),
        }));
        wavesurfer.destroy();
    });
    
    wavesurfer.load(props.file.file.url);

  }, [props.file.file.url]);

    useEffect(() => {
        // console.log("waveform", manager.wave)
    }, [manager.wave])

  return (
    <div className={postStyles.audio + " " + mT1}>
      <audio style={{display: "none"}} ref={audioRef} src={props.file.file.url} controls />
      {manager.wave !== null && manager.error !== true && (
        <WaveformComponent
          id={props.file.id}
          options={{
            track: props.file.file.url,
            wave: manager.wave,
          }}
        />
      )}
      {manager.wave === null && manager.error !== true && !manager.large && (
        <div className={postStyles.loading}>
          <LoadingIcon height={64} />
        </div>
      )}
      {(manager.error === true || manager.large) && (
        <PlyrAudioComponent
          sources={{
            type: "audio",
            sources: [
              {
                src: props.file.file.url,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default Audio;
