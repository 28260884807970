// @flow

import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import {flex, contentWrapper} from "../../../../../../css/layout.css";
import mainStyles from "../../../../../../css/layout/main.css";

import Cover from "../../../../../../assets/graphics/social/cover.svg";

import TopNav from "../nav/top/FeedTopNav";
import HomeNav from "../nav/left/HomeNav";

import history from "../../../../../../util/history";
import useWindowDimensions from "../../../../../../util/hooks/useWindowDimensions";
import {MAX_MOBILE_WIDTH} from "../../../../../../util/constants";

function MainContainer(props) {

    const {width} = useWindowDimensions();
    const isDesktop = width > MAX_MOBILE_WIDTH;

    return (
        <div className={contentWrapper}>
            {isDesktop && !props.minimal && !props.isDesktopChat && <HomeNav />}
            <div className={mainStyles.container}>
                <div className={mainStyles.wrapper}>
                    {props.isDesktopChat || props.mobileChat ?
                        <TopNav isDesktopChat={props.isDesktopChat} mobileChat={props.mobileChat} chatUser={props.chatUser} />
                    :
                        <TopNav />
                    }
                    <div className={flex + " " + mainStyles.container}>
                        {
                            (!history.location.pathname.startsWith("/search/") && props.cover) &&
                            <div className={mainStyles.cover} style={props.isDesktopChat ? { marginLeft: '310px' } : {}}>
                                <Cover />
                            </div>
                        }
                        <div className={mainStyles.bottomContent}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

MainContainer.propTypes = {
    cover: PropTypes.bool,
}

MainContainer.defaultProps = {
    cover: true,
}

const mapStateToProps = state => {
    return state.chat ? { chat: state.chat } : {};
};

export default connect(mapStateToProps)(MainContainer);
