// @flow

import React, {useEffect, useState} from "react";

import { tier } from "../../../../../../css/layout/social/settings/pages/upgrade.css";
import formStyles from "../../../../../../css/form.css";
import layoutStyles from "../../../../../../css/layout.css";

import BoostGraphic from "../../../../../../assets/graphics/rocket.svg";

import Financials from "../../../../pages/settings/Financials";

import {LAYER, useLayerDispatch} from "../../../layer/context";
import {Link} from "react-router-dom";
import {PAYPAL_CANCEL_URL} from "../../../../../../util/constants";
import store from "../../../../../../store";
import { getToken } from "../../../../../../reducers/auth/actions";

import axios from "axios";
import { API_URL } from "../../../../../../util/constants";
import {toast} from "react-toastify";

import { timeTillTime } from "../../../../../../util/date";
import BoostTier from "./BoostTier";
import BoostPrivileges from "./BoostPrivileges";

function Purchased(props) {
    const dispatchLayer = useLayerDispatch();

    const [manager, setManager] = useState({
        warning: false,
        cancelWarning: false,
        subscription: false,
        timeLeft: 0,
        timeToCharge: 0,
    })

    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        };

        axios.get(API_URL + `/market/checkSubscription`, config)
            .then(function (res) {
                if(res.data && res.data.data.subscription != null){
                    setManager((manager) => ({
                        ...manager,
                        loading: false,
                        subscription: res.data.data.subscription,
                        timeLeft: res.data.data.timeLeft,
                        timeToCharge: res.data.data.timeToCharge,
                    }));
                }else{
                    setManager((manager) => ({
                        ...manager,
                        loading: false,
                        error: true
                    }));
                }
            })
    }, [])

    function handleFinancialSection() {
        dispatchLayer({ type: LAYER, payload: <Financials /> });
    }

    function handleWarning() {
        setManager(manager => ({
            ...manager,
            warning: !manager.warning,
        }))
    }

    function handleCancelWarning() {
        setManager(manager => ({
            ...manager,
            cancelWarning: !manager.cancelWarning,
        }))
    }

    function cancelBoostSubscription(){
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": store.dispatch(getToken())
            }
        };

        axios.post(API_URL + `/market/product/boost/cancel`, {}, config)
            .then(function (res) {
                const Notification = () => (
                    <div>
                        Your boost subscription has been canceled.
                    </div>
                );
                toast.info(<Notification />, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setManager((manager) => ({
                    ...manager,
                    loading: false,
                    error: true
                }))
            })
            .catch(function (err) {
                const Notification = () => (
                    <div>
                        Something went wrong.
                    </div>
                );

                toast.error(<Notification/>, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setManager((manager) => ({
                    ...manager,
                    loading: false,
                    error: true
                }));
            });
    }

    function timestampToDate(unixTimestamp) {
        const date = new Date(unixTimestamp * 1000);
        const year = date.getFullYear();
        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
                            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const month = monthNames[date.getMonth()];
        const day = ("0" + date.getDate()).slice(-2);
        return `${day}-${month}-${year}`;
    }
      

    if(manager.warning){
        return (
            <div className={tier}>
                <h2>Modify Boost</h2>
                <div className={layoutStyles.wF}>
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        <div>
                            <p>
                                If you are using PayPal, <Link target="_blank" to={PAYPAL_CANCEL_URL}>cancel your existing subscription</Link>.
                            </p>
                            <hr />
                            <p>
                                If you are using a card or cryptocurrency, cancel your plan in the financial section.
                            </p>
                        </div>
                    </div>
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        <p>
                            After your plan is canceled you can select a new boost plan.
                        </p>
                    </div>
                </div>
                <div className={layoutStyles.flex + ' ' + layoutStyles.wF + ' ' + layoutStyles.mT1}>
                    <button className={formStyles.button + ' ' + layoutStyles.flexGrow} onClick={handleFinancialSection}>
                        Cancel
                    </button>
                    <button className={formStyles.button + ' ' + layoutStyles.flexGrow + ' ' + layoutStyles.mL1} onClick={handleWarning}>
                        Back
                    </button>
                </div>
            </div>
        );
    }

    if(manager.cancelWarning){
        return (
            <div className={tier}>
                <h2>Cancel Boost</h2>
                <div className={layoutStyles.wF}>
                    {/* <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        <div>
                            <p>
                                To cancel your plan go to the financial section and choose your plan. Then click cancel under the payment method.
                            </p>
                        </div>
                    </div> */}
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        <p> {/* TODO: get the storage limit from the server or a config*/}
                            After your subscription plan is cancelled and your Boost runs out, any storage over the free tier limit will be deleted after 2 weeks. You will not be able to subscribe to Boost again until you are under the free tier limit.
                        </p>
                    </div>
                </div>
                <div className={layoutStyles.flex + ' ' + layoutStyles.wF + ' ' + layoutStyles.mT1}>
                    <button className={formStyles.button + ' ' + formStyles.buttonSecondary + ' ' + layoutStyles.flexGrow} onClick={() => cancelBoostSubscription()}>
                        Cancel Boost subscription
                    </button>
                    <button className={formStyles.button + ' ' + layoutStyles.flexGrow + ' ' + layoutStyles.mL1} onClick={handleCancelWarning}>
                        Back
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={tier}>
            <h2>Boost</h2>
            <label>{props.price} / month</label>
            <BoostPrivileges/>
            <div className={layoutStyles.wF + ' ' + layoutStyles.flex + ' ' + layoutStyles.justifyContentCenter + ' ' + layoutStyles.mT1}>
                <BoostGraphic style={{width: "150px"}} />
            </div>
            <div className={layoutStyles.wF}>
                <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                    Thank you for purchasing Tiblur Boost!
                </div>
            </div>
            <div className={layoutStyles.flex + ' ' + layoutStyles.wF + ' ' + layoutStyles.mT1}>
                {/* <button className={formStyles.button + ' ' + layoutStyles.flexGrow} onClick={handleWarning}>
                    Modify Plan
                </button> */}

                {manager.subscription == true &&
                <div className={layoutStyles.wF}>
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        You have an active subscription for Tiblur Boost. {manager.timeToCharge != 0 ? <><br/>Next charge is in {timeTillTime(manager.timeToCharge * 1000)} <br/> ({timestampToDate(manager.timeToCharge)})</> : ""}.
                    </div>
                    <br/>
                    <button className={formStyles.button + ' ' + formStyles.buttonSecondary + ' ' + layoutStyles.flexGrow + ' ' + layoutStyles.mL1} onClick={handleCancelWarning}>
                        Cancel Boost subscription
                    </button>
                </div>
                }
                {manager.subscription != true &&
                <div className={layoutStyles.wF}>
                    {manager.timeLeft && manager.timeLeft != true &&
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        No active subscription. {manager.timeLeft ? <><br/>Your Boost will run out after {timeTillTime(manager.timeLeft * 1000)}<br/>({timestampToDate(manager.timeLeft)}).</> : ""}
                    </div>
                    }
                    {manager.timeLeft && manager.timeLeft == true &&
                    <div className={formStyles.alert + ' ' + layoutStyles.mT1}>
                        You have infinite boost.
                    </div>
                    }
                </div>
                }
            </div>
            <div className={layoutStyles.mT1}>
                <p>
                    <small>To see billing information/to cancel see the financial section.</small>
                </p>
            </div>
        </div>
    );
}

export default Purchased;
