// @flow

import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";

import cardStyles from "../../../../css/layout/social/settings/card.css";
import formStyles from "../../../../css/form.css";
import layoutStyles from "../../../../css/layout.css";

import KeyIcon from "../../../../assets/svg/icons/key.svg";
import GnuPGIcon from "../../../../assets/svg/logos/gnupg.svg";

import Storage from "../../layout/parts/settings/account/Storage";
import TwoFactorEmail from "../../layout/parts/settings/twofactor/TwoFactorEmail";
import ChangeOrCurrentAccount from "../../layout/parts/settings/account/ChangeOrCurrentAccount";
import ConfirmEmail from "../../layout/parts/settings/account/ConfirmEmail";

import {AccountProvider} from "../../layout/parts/settings/account/context";
import { toast } from "react-toastify";
import axios from "axios";
import store from "../../../../store";
import { getRefreshToken, getToken, logout } from "../../../../reducers/auth/actions";
import { API_URL } from "../../../../util/constants";
import history from "../../../../util/history";

function Delete(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    if(props.auth.user === null){
        return null;
    }

    function handleDelete() {
        let config = {
            headers: {
                "Auth-Token": store.dispatch(getToken()),
            }
        };
        axios.delete(API_URL + "/@me/deleteAccount", config)
        .then(async res => {
            store.dispatch(logout());

            // sleep for 1 second to allow the logout to complete
            await new Promise(r => setTimeout(r, 1000));

            history.push("/");
            window.location.reload();
        }).catch(err => {
            const Notification = () => (
                <div>
                  There was an error deleting your account.
                </div>
            );
            setTimeout(() => {
              toast.error(<Notification />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }, 300);
        })
    }

    function handleShowModal() {
        setShowDeleteModal(true);
    }

    return (
        <AccountProvider>
            <div>
            <h3>Account Deletion</h3>
                <div className={layoutStyles.tbRowM + " " + layoutStyles.mB1}>
                    <div className={layoutStyles.tbCol12}>
                        <div className={cardStyles.card}>
                            <div className={cardStyles.cardBody}>
                                <h4 className={layoutStyles.mN}>Delete Account</h4>
                                <p className={layoutStyles.mT1 + " " + layoutStyles.mB1}>
                                    Deleting your account will remove all of your data from our servers.
                                    This includes your profile, posts, comments, and any other data associated with your account.
                                    This action is irreversible.
                                </p>
                            </div>
                            {!showDeleteModal ?
                            <div className={cardStyles.cardFooter}>
                                <button onClick={() => handleShowModal()} className={formStyles.button + " " + formStyles.buttonSecondary}>Delete Account</button>
                            </div>
                            :
                            <div>
                            <div className={cardStyles.cardFooter}>
                                <p className={layoutStyles.mT1 + " " + layoutStyles.mB1}>
                                    Are you sure you want to delete your account?
                                </p>
                            </div>
                            <div className={cardStyles.cardFooter}>
                                <button style={{marginRight: "25px"}} onClick={() => setShowDeleteModal(false)} className={formStyles.button}>Cancel</button>
                                <button onClick={() => handleDelete()} className={formStyles.button + " " + formStyles.buttonSecondary}>Confirm Delete</button>
                            </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AccountProvider>
    );
}

const mapStateToProps = state => {
  const { auth } = state;
  return { auth: auth };
};

export default connect(mapStateToProps)(Delete);
