// @flow

import {
  RESET_FEED,
  UPDATE_PERIOD,
  UPDATE_TYPE,
  UPDATE_SORT,
  UPDATE_POSTS,
  CREATE_ERROR,
  STOP_LOADING,
  UPDATE_OFFSET, HIT_LIMIT, START_LOADING,
  UPDATE_PREVIEW_SHOW, UPDATE_PREVIEW_USER_ID, UPDATE_PREVIEW_USER, UPDATE_PREVIEW_POSTS
} from "./constants";

import {PAST_WEEK} from "../../util/constants";

const initialState = {
  offset: 0,
  loading: true,
  loadingTop: false,
  error: false,
  reachedEnd: false,
  posts: [],
  skipTo: null,
  useSkipTo: false,
  sort: "newest",
  type: "all",
  period: PAST_WEEK,
  previewProfile: {
    show: false,
    userId: null,
    user: null,
    posts: [],
  },
  grid: localStorage.getItem("grid") === "true",
  vidsOnly: localStorage.getItem("vidsOnly") === "true",
  hideReblogs: localStorage.getItem("hideReblogs") === "true",
  openPostModal: false,
  topLockFetch: true,
  lastTopFetch: 0
};

function auth(state = initialState, action) {
  switch (action.type) {
    case "SET_LAST_TOP_FETCH":
      return {
        ...state,
        lastTopFetch: action.payload
      }
    case "SET_SKIP_TO":
      return {
        ...state,
        skipTo: action.payload
      }
    case "SET_USE_SKIP_TO":
      return {
        ...state,
        useSkipTo: action.payload
      }
    case "SET_POST_MODAL":
      return {
        ...state,
        openPostModal: action.payload
      }
    case "SET_HIDE_REBLOGS":
      localStorage.setItem("hideReblogs", action.payload);
      return {
        ...state,
        hideReblogs: action.payload,
    }
    case "SET_GRID":
      localStorage.setItem("grid", action.payload);
      return {
        ...state,
        grid: action.payload,
    }
    case "SET_VIDSONLY":
      localStorage.setItem("vidsOnly", action.payload);
      return {
        ...state,
        vidsOnly: action.payload,
    }
    case UPDATE_PREVIEW_SHOW:
      return {
        ...state,
        previewProfile: {
          ...state.previewProfile,
          show: action.payload
        }
      }
    case UPDATE_PREVIEW_USER_ID:
      return {
        ...state,
        previewProfile: {
          ...state.previewProfile,
          userId: action.payload
        }
      }
    case UPDATE_PREVIEW_USER:
      return {
        ...state,
        previewProfile: {
          ...state.previewProfile,
          user: action.payload
        }
      }
    case UPDATE_PREVIEW_POSTS:
      return {
        ...state,
        previewProfile: {
          ...state.previewProfile,
          posts: action.payload
        }
      }
    case RESET_FEED:
      return {
        ...state,
        offset: 0,
        loading: true,
        error: false,
        reachedEnd: false,
        posts: [],
      };
    case UPDATE_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    case UPDATE_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case UPDATE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case UPDATE_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case "START_LOADING_TOP":
      return {
        ...state,
        loadingTop: true,
      };
    case "STOP_LOADING_TOP":
      return {
        ...state,
        loadingTop: false,
      };
    case "SET_TOP_LOCK_FETCH":
      return {
        ...state,
        topLockFetch: action.payload,
      };
    case CREATE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case UPDATE_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case HIT_LIMIT:
      return {
        ...state,
        reachedEnd: true,
      };
    default:
      return state;
  }
}

export default auth;
