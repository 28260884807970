// @flow

import React, { useState } from "react";
import PlayIcon from "../../../../../../../../assets/svg/icons/play.svg"

import postStyles from "../../../../../../../../css/components/post.css";
import formStyles from "../../../../../../../../css/form.css";
import layoutStyles from "../../../../../../../../css/layout.css";

import PlyrComponent from "../../../../../../../../util/components/PlyrComponent";

import { API_URL } from "../../../../../../../../util/constants";
import Image from "../image/Image";

const Video = (props) => {
  const [showThumbnail, setShowThumbnail] = useState(props.thumbnailUrl != null)

  let file = props.file

  try{
      const url = file.file.url;
      const fileName = url.substring(url.lastIndexOf('/')+1);
      const newUrl = `${API_URL}/social/${fileName}`;
      file.file.url = newUrl;
  } catch(e) {
    console.log('error in Video.js:', e, file)
  }

let sources
// set initial source
if (file.available_transcoding) {
  // still in use somewhere
  console.log('setting default source', file.available_transcoding.h)
  sources = [{ src: file.file.url, size: file.available_transcoding.h }]
} else
if (file.available_transcodes) {
  // for socials
  sources = [{ src: file.file.url, size: file.available_transcodes.h }]
  //console.log('in socials:', sources, props.file)
} else
if (file.file.height) {
  sources = [{ src: file.file.url, size: file.file.height }]
} else {
  // console.log('no height in props:', props)
  sources = [{ src: file.file.url }]
}

// set additional sources
if (file && (file.available_transcoding || file.available_transcodes)) {
  console.log('detected additional video sources in', file)

  // keep transcode URLs relative to file.url
  let base = 'http://192.168.253.60:9000/seed/'
  if (!window.location.href.match(/192\.168/)) {
    const parts = file.file.url.split('/')
    parts.pop()
    base = parts.join('/') + '/'
  }

  // fix up formats
  if (file.available_transcoding) { // this has to be first
    const transCodes = file.available_transcoding.alts.map(t => { return { src: base + t.path, size: t.res } })
    console.log('setting source', transCodes)
    sources = [...transCodes, { src: file.file.url, size: file.available_transcoding.h }]
  } else
  if (file.available_transcodes) { // this is sometimes set without data
    const transCodes = file.available_transcodes.alts.map(t => { return { src: base + t.path, size: t.res } })
    sources = [...transCodes, { src: file.file.url, size: file.available_transcodes.h }]
  }
  console.log('setting available sources', sources)
}


    if(showThumbnail){
        return (
          <div onClick={() => setShowThumbnail(false)} style={{width: "100%"}}>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
              <Image url={props.thumbnailUrl} setSlide={() => {}} isThumbnail={true}/>
            </div>
            <PlayIcon style={{cursor: "pointer", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} width={48} />
          </div>
        );
    }

    return (
        <div className={postStyles.video}>
            {
                (
                    file.file.url.split('.').pop() !== "webm" ||
                    document.createElement('video').canPlayType('video/webm')
                ) &&
                <PlyrComponent autoplay={props.thumbnailUrl != null} sources={{
                    type: 'video',
                    sources: sources,
                }}/>
            }
            {
                (
                    file.file.url.split('.').pop() === "webm" &&
                    !(!!document.createElement('video').canPlayType('video/webm'))
                ) &&
                <div className={formStyles.alert + ' ' + layoutStyles.m1}>
                    Your browser does not support webm files.
                </div>
            }
        </div>
    );
};

Video.defaultProps = {
    autoplay: false,
}

export default Video;
