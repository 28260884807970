import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import store from "../../../../../../../store";

import postModalStyles from "../../../../../../../css/layout/social/post-modal.css";
import postStyles from "../../../../../../../css/components/post.css";
import formStyles from "../../../../../../../css/form.css";

import layoutStyles, {mTN, pBN} from "../../../../../../../css/layout.css";

import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { API_URL } from "../../../../../../../util/constants";

import ReactTooltip from "react-tooltip";

import { BODY_LENGTH } from "../../../../../../../util/constants";

function NewPostForm(props) {
    let [manager, setManager] = useState({
        title: "",
        submitting: false,
        renderedPost: ""
    })

    function HTMLStringToReactComponents(htmlString) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const elements = Array.from(htmlDoc.body.children);

        const allowedElements = ["a", "br", "b", "i", "u", "s", "strong", "em", "p", "h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "li", "blockquote", "code", "pre", "img", "div", "span", "hr"]
        const allowedAttributes = ["href", "src", "class", "id", "alt", "title", "target", "rel"]
        return elements.map((element, index) => {
            if(allowedElements.indexOf(element.tagName.toLowerCase()) === -1) return (<></>)
            if(element.tagName === "BR"){
                return <br key={index} />
            }

            if(element.tagName === "A"){
                try{
                    let url = new URL(element.href);
                    let url_no_protocol = url.hostname + url.pathname
                    if(url_no_protocol.startsWith("www.")){
                        url_no_protocol = url_no_protocol.substring(4)
                    }

                    let is_youtube = false
                    let yt_video_id;
                    for(let entry of ["www.youtube.com", "youtube.com", "youtu.be"]){
                        if(url_no_protocol.startsWith(entry)){
                            is_youtube = true
                            if(url_no_protocol.startsWith("youtu.be")){
                                yt_video_id = url_no_protocol.split("/")[1]
                            } else {
                                yt_video_id = url.searchParams.get("v")
                            }
                            break
                        }
                    }

                    let is_twitter = false
                    if(!url_no_protocol.startsWith("twitter.com/hashtag")){
                        for(let url of ["twitter.com", "t.co", "x.com", "mobile.twitter.com"]){
                            if(url_no_protocol.startsWith(url)){
                                is_twitter = true
                                break
                            }
                        }
                    }

                    return(
                        <span>
                            <a
                            className="link"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                let hostname = new URL(element.href).hostname
                                if(hostname.startsWith("www.")){
                                    hostname = hostname.substring(4)
                                }
                                if(hostname === window.location.hostname){
                                    history.push(new URL(element.href).pathname)
                                } else{
                                    window.open(element.href)
                                }
                            }}
                            key={index}
                            to={element.href}>
                                <span data-tip data-for={`url-${element.href}`} style={{color: "inherit"}}>
                                    {element.textContent}
                                </span>
                                
                                <ReactTooltip id={`url-${element.href}`} place="bottom" type="dark" effect="solid">
                                    <span>{element.href}</span>
                                </ReactTooltip>
                                
                                {is_youtube && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="560"
                                    height="315"
                                    style={{border: "2px solid var(--link-color)", borderRadius: "5px", maxWidth: "100%"}}
                                    src={`https://www.youtube.com/embed/${yt_video_id}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                                }
                                {is_twitter && manager.allowedEmbeds.includes(element.href) &&
                                    <iframe
                                    width="550"
                                    height="285"
                                    style={{borderRadius: "5px", maxWidth: "100%"}}
                                    src={`${API_URL}/post/twitter-embed?url=${element.href}`}
                                    ></iframe>
                                }
                            </a>
                            {(is_youtube || is_twitter) && !manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => allowEmbed(element.href)}
                                > (embed)</span>
                            }
                            {(is_youtube || is_twitter) && manager.allowedEmbeds.includes(element.href) &&
                                <span style={{color: "pink", cursor: "pointer"}}
                                    onClick={() => disallowEmbed(element.href)}
                                > (unembed)</span>
                            }
                            {mobile && !is_youtube && !is_twitter &&
                               <span style={{color: "pink"}}> ({url_no_protocol})</span>
                            }
                        </span>
                    )
                }
                catch(e){
                    return(
                        <span>
                            <a
                            className="link"
                            onClick={() => window.open(element.href)}
                            style={{
                                cursor: "pointer"
                            }}
                            key={index}>
                                {element.textContent}
                            </a>
                            <span style={{color: "pink"}}> ({element.href})</span>
                        </span>
                    )
                }
            }

            const elementProps = {};
            Array.from(element.attributes).forEach((attribute) => {
                if(allowedAttributes.indexOf(attribute.name) === -1) return
                elementProps[attribute.name] = attribute.value;
            });

            if(["ol", "ul"].indexOf(element.tagName.toLowerCase()) !== -1){
                const childComponents = Array.from(element.children).map((childElement, childIndex) => {
                    if(childElement.tagName.toLowerCase() === 'li'){
                        return HTMLStringToReactComponents(childElement.outerHTML);
                    } else{
                        return null;
                    }
                });

                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.filter(child => child !== null),
                );
            }

            if(element.tagName.toLowerCase() === 'img'){
                return null
            }

            if(element.textContent == null || element.textContent === ''){
                return React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps, dangerouslySetInnerHTML: { __html: element.innerHTML } },
                );
            }

            const childNodes = Array.from(element.childNodes);
            const childComponents = childNodes.map((childNode, childIndex) => {
                if(childNode.nodeType === Node.TEXT_NODE){
                    const words = childNode.textContent.split(' ');
                    return words.map((word, wordIndex) => {
                        if(word.startsWith('@') && word.length > 1){
                            return <Link key={wordIndex} to={`/user/${word.substring(1)}`}>{word} </Link>;
                        } else{
                            return <span key={wordIndex}>{word} </span>;
                        }
                    });
                } else if(childNode.nodeType === Node.ELEMENT_NODE && childNode.tagName.toLowerCase() === 'a'){
                    return HTMLStringToReactComponents(childNode.outerHTML)
                } else {
                    return HTMLStringToReactComponents(childNode.outerHTML);
                }
            });

            return (
                React.createElement(
                    element.tagName.toLowerCase(),
                    { key: index, ...elementProps },
                    childComponents.flat(),
                )
            );
        });
    }

    useEffect(() => {
        if(!post.body){ return }
        let render = HTMLStringToReactComponents(post.body)

        setManager(manager => ({
            ...manager,
            renderedPost: render
        }))
    }, [manager.showAll, manager.allowedEmbeds])

    let post = props.post;

    function handleBodyChange(html){
        if(html.length > BODY_LENGTH){
            // handle error
            return
        }

        props.changeBody(html);
        console.log("changed body")

        setManager({
            ...manager,
            body: html,
        })
    }

    return (
        <div>
            <main>
                <div className={postStyles.body + ' ' + (post.attachments.length > 0 ? mTN : '') + ' ' + (post.tags.length > 0 ? pBN : '')}
                    style={{ "padding": "0.5rem", "paddingLeft": "1rem", overflowWrap: "anywhere" }}>

                    <div className={postModalStyles.body}>
                        <ReactQuill
                            id="quickpost-quill"
                            onChange={handleBodyChange}
                            value={post.body}
                            className={layoutStyles.flex + ' ' + layoutStyles.flexColumn + ' ' + layoutStyles.flexGrow}
                            theme="snow"
                            disabled={false}
                            formats={["bold", "italic", "underline", "link", "header", "strike", "list"]}
                            placeholder="What's on your mind?"
                            maxLength={5000}
                        />
                    </div>

                </div>
            </main>
        </div>
    )
}

const mapStateToProps = () => {
    return { auth: store.getState().auth };
}

export default connect(mapStateToProps)(NewPostForm);