import { useState, useEffect, useRef } from 'react';

// Custom hook for detecting long press
/* Usage
    const longPressEvents = useLongPress(() => {
        callbackfunc()
    }, 200)
    <div {...longPressEvents} >
*/

function useLongPress(callback, ms = 500) {
  const [startTime, setStartTime] = useState(null);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const startLongPress = () => {
    setStartTime(new Date());
    timerRef.current = setTimeout(() => {
      callback();
    }, ms);
  };

  const stopLongPress = () => {
    clearTimeout(timerRef.current);
    setStartTime(null);
  };

  return {
    onMouseDown: startLongPress,
    onMouseUp: stopLongPress,
    onMouseLeave: stopLongPress,
    onTouchStart: startLongPress,
    onTouchEnd: stopLongPress,
  };
}

export default useLongPress;