// @flow

import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";

import {mB1, mT1, mL} from "../../../../css/layout.css";
import {divider} from "../../../../css/layout/social/container/main.css";
import formStyles from "../../../../css/form.css";
import profileStyles from "../../../../css/layout/social/profile.css";

import Loading from "../../layout/parts/social/feed/components/Loading";
import Error from "../../layout/parts/social/feed/components/Error";
import Empty from "../../layout/parts/social/feed/components/Empty";
import DefaultPost from "../../layout/parts/social/post/DefaultPost";

import {BOTTOM_LOAD_HEIGHT} from "../../../../util/constants";
import {START_LOADING} from "../../../../reducers/social/constants";
import {useProfileState} from "../../layout/parts/profile/context";
import history from "../../../../util/history";
import useWindowDimensions from "../../../../util/hooks/useWindowDimensions";
import {fetchPosts, reset} from "../../../../reducers/social/actions";
import {formatBreakDate} from "../../../../util/date";
import store from "../../../../store";

import Switch from "../../layout/parts/switch/Switch";
import Masonry from 'react-masonry-component';

import Ad from "../social/ad/PostAd";

import BannedGraphic from "../../../../assets/graphics/banned.svg";

function Profile(props) {
    const masonryRef = useRef();
    
    const { container, user, error } = useProfileState();
    const { width } = useWindowDimensions();
    
    const urlParams = new URLSearchParams(window.location.search);
    
    let input = null;
    if(urlParams.get('q') !== null){
        input = decodeURIComponent(urlParams.get('q'));
    }

    const [manager, setManager] = useState({
        unblockPosts: false,
        query: input,
    });

    useEffect(() => {
        const unlisten = history.listen( location =>  {
            handleHistory(location);
            store.dispatch(reset());
        });

        return () => {
            unlisten();
        }
    });

    useEffect(() => {
        store.dispatch(reset());
    }, [manager.query]);

    useEffect(() => {
        if(props.social.offset === 0 && user !== null){
            container.current.scrollTop = 0;
            fetch();
        }
    }, [props.social.offset, user]);

    useEffect(() => {
        if(container === null) return;

        container.current.addEventListener('scroll', trackScroll);

        return () => {
            if(container !== null && container.current !== null){
                container.current.removeEventListener('scroll', trackScroll);
            }
        };
    }, [props.social.loading, props.social.reachedEnd, props.social.error]);

    const trackScroll = (e) => {
        if(
            (e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight < BOTTOM_LOAD_HEIGHT
                && !props.social.loading && !props.social.reachedEnd && !props.social.error)
        ) {
            store.dispatch({ type: START_LOADING });
            fetch();
        }
    };

    function handleHistory(location) {
        const urlParams = new URLSearchParams(location.search);

        setManager(manager => ({
            ...manager,
            query: urlParams.get('q'),
        }))
    }

    function fetch() {
        let params = {};

        params.offset = props.social.offset;

        if(manager.query !== null){
            params.q = manager.query;
        }
        {props.social.vidsOnly && (params.type = "video")}

        store.dispatch(fetchPosts(`/users/profile/${props.match.params.username}/feed`, params, "posts"));
    }

    let lastDate = null;
    let posts = [];
    let postCount = 0
    let boosted = false;
    try{
        boosted = props.auth.user.info.boosted
    } catch(e){}
    props.social.posts.map((post, i) => {
        postCount++;
        // if(!grid && lastDate !== formatBreakDate(post.timestamp)){
        //     lastDate = formatBreakDate(post.timestamp);

        //     posts.push(
        //         <div key={formatBreakDate(post.timestamp) + "-" + post.id} className={divider + (width > 800 ? ' ' + mB1 : "")}>
        //             {formatBreakDate(post.timestamp)}
        //         </div>
        //     );
        // }
        if(!boosted){
            if(postCount == 3){
                posts.push(<Ad key={i + "-ad"} grid={props.social.grid}/>);
            }
            if(postCount % 15 === 0 && postCount !== 0){
                posts.push(<Ad key={i + "-ad"} grid={props.social.grid}/>);
            }
        }
        posts.push(
            <DefaultPost
            key={post.id}
            grid={props.social.grid}
            postId={post.id}
            showPin={true}
        />);
    });

    if(user != null && user.info.banned){
        return (
            <div className={profileStyles.container}>
                <div className={formStyles.alert}>
                    This user has been banned.
                </div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "25px"}}>
                    <BannedGraphic width="80%"/>
                </div>
            </div>
        )
    }

    if(user !== null && user.info.blocked){
        return (
            <div className={profileStyles.container}>
                <div className={formStyles.alert}>
                    You have been blocked by this user.
                </div>
            </div>
        )
    }

    if(user !== null && user.privacy.feed == false){
        return (
            <div className={profileStyles.container}>
                <div className={formStyles.alert}>
                    This profile's feed is private.
                </div>
            </div>
        )
    }

    if((user !== null && user.info.blocking) && !manager.unblockPosts){
        return (
            <div className={profileStyles.container}>
                <div className={formStyles.alert}>
                    You have blocked this user.
                    <button className={formStyles.button + ' ' + mL}
                            onClick={() => { setManager({ ...manager, unblockPosts: true }) }}>
                        View Posts
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
        {props.social.grid ?
            <Masonry ref={(c) => { masonryRef.current = c; }} options={{gutter: 5}} enableResizableChildren={true} updateOnEachImageLoad={true}>
                {posts}
            </Masonry>
        :""
        }
        <div className={profileStyles.container}>
            {
                props.social.error && !error &&
                <div className={mT1}>
                    <Error />
                </div>
            }
            {
                !props.social.loading && !props.social.error && props.social.posts.length === 0 &&
                <div className={mT1}>
                    <Empty body="There are no posts here." />
                </div>
            }
            {!props.social.grid ?
            posts 
            :""}
            {
                props.social.loading && !props.social.reachedEnd &&
                <div className={mT1}>
                    <Loading />
                </div>
            }
            {
                props.social.reachedEnd &&
                <div className={formStyles.alert + ' ' + mT1}>
                    You have reached the end!
                </div>
            }
        </div>
        </>
    );
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(Profile);
