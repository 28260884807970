// @flow

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import supportStyles from "../../../../../css/layout/support/support.css";
import cardStyles from "../../../../../css/components/card.css";

import Container from "../../../layout/parts/support/Container";

import {SUPPORT_URL} from "../../../../../util/constants";

function Rules(props) {
    return (
        <Container>
            <div className={supportStyles.body}>
                <p><Link to="/register">Go to signup</Link></p>
                <p><Link to="/social/trending">Go to Trending</Link></p>
                <div className={cardStyles.card}>

                    <div className={cardStyles.cardHeader}>
                        End User License Agreement
                    </div>
                    <div className={cardStyles.cardBody}>
                    <p style={{marginTop: "10px"}}>Please read this End-User License Agreement (EULA) carefully before accessing or using the Tiblur app or website operated by Tiblur ("we," "us," or "our").</p>

                    <p style={{marginTop: "10px"}}>By accessing or using the Service, you agree to be bound by the terms and conditions of this EULA. If you do not agree to all the terms and conditions of this agreement, you must not use the Service.</p>
                    <ol>
                        <li>Acceptable Content:</li>
                            You will not upload or post content that is generally unacceptable or repulsive. There is no tolerance for objectionable content or abusive users.

                        <li>NSFW Content:</li>
                            Legal NSFW content is allowed, provided that it is marked as NSFW, and does not violate any laws, such as copyright.
                            NSFW content includes content with nudity, sexual content, a sexually suggestive tone, or could be seen as sexual. For example, pornography is explicit, but artistic works like the Vitruvian Man are not considered NSFW. We reserve the right to mark any post as NSFW.

                        <li>Age Restriction:</li>
                            You will immediately leave this website and stop accessing this website if you are under the age of 18.

                        <li>Compliance with Laws:</li>
                            You agree to not upload any data or "post" anything that breaks United States laws or local laws.

                        <li>Copyright:</li>
                            Do not upload copyright data without permission.

                        <li>Spam and Misuse:</li>
                            You agree not to spam or flood the service, impersonate anyone, or harrass anyone.

                        <li>Legal Compliance:</li>
                            Do not break United States law or local law in the United States.

                        <li>Exploits:</li>
                            If you have found an exploit for Tiblur, contact us for a potential reward instead of using it.

                        <li>By using this Service, you acknowledge that you have read and understood this EULA and agree to abide by its terms and conditions. If you do not agree to these terms, please refrain from using the Service.</li>
                    </ol>
                    <p>Last revised: 09-AUG-2023</p>
                    <p>Tiblur</p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth };
};

export default connect(mapStateToProps)(Rules);
