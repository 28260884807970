import React from "react";
import { Link } from "react-router-dom";

import {isMobile} from 'is-mobile'

import Top from "../../../layout/parts/support/nav/Top";

import SettingsImage from "./Settings.png";
import SearchImage from "./Search.png"
import SearchOptionsImage from "./SearchOptions.png"
import NotificationsImage from "./Notifications.png"
import InfoImage from "./Info.png"

function Tutorial(){
    return(
        <div style={{textAlign: "justify", marginLeft: "auto", marginRight: "auto", maxWidth: isMobile() ? "95%" : "70%", paddingBottom: "200px"}}>
            <Top/>
            <p><Link to="/register">Go to signup</Link></p>
            <p><Link to="/social/trending">Go to Trending</Link></p>
            <h1>Tiblur Tutorial</h1>
            <p>Welcome to Tiblur! Here’s how to get started!</p>
            <div style={{marginTop: "15px"}}>
                <p>First, make sure to click the gear icon to view the settings page.</p>
                <p>The settings page contains privacy, appearance, finances, upgrades, blocks, and more.</p>
                <img src={SettingsImage} alt="Settings page" style={{width: "50%"}}/>
            </div>
            <div style={{marginTop: "15px"}}>
                <ul>
                    <li>The appearance tab will allow you to customize your profile. You can change your profile picture, username color (if you have boost), your profile theme, and your bio in this section.</li>
                    <li>The account section allows you to change your password, monitor your data usage, log out of multiple sessions, and set up 2fa (coming soon). </li>
                    <li>The blocked section allows you to manage who you have blocked.</li>
                    <li>The privacy section allows you to adjust who can follow you and what information people can see. You can always make individual posts private so only you can see them. </li>
                    <li>Note: Followers only mode is the equivalent of private mode. Each follower will have to submit a request to be added.</li>
                    <li>The financial section keeps track of all boost purchases.</li>
                    <li>The upgrade section directs you to the boost subscription page.</li>
                    <li>If you decide to delete your account the process is instant.</li>
                </ul>
            </div>
            <h3>Okay so you’ve browsed the settings, you’ve bought boost ;), and you’re all set to start following your friends. </h3>
            <div style={{marginTop: "15px"}}>
                <h4>Using the search engine</h4>
                <img src={SearchImage} alt="Search page" style={{width: "50%"}}/>
                <p>The search engine currently produces results for 5 terms and three users. We are going to shorten it to three search terms and three profiles in the future. Once you have found the person you want to follow simply click their profile. If you want to follow them just click the blue button on their profile. Their posts will now show up in your feed.</p>
                <p style={{marginTop: "15px"}}>Note: On profiles there is an option to search the entire site or just the profile for an individual post.</p>
                <img src={SearchOptionsImage} alt="Search page" style={{width: "50%"}}/>
            </div>
            <div style={{marginTop: "15px"}}>
                <h4>Feed:</h4>
                <p>The feed will only show posts from individuals that you follow. There is no (For You) section that tracks what you are browsing. Tiblur does not put content packages together for you.</p>

                <h4 style={{marginTop: "15px"}}>Trending:</h4>
                <p>The trending page displays the top posts for the past 24 hours. You can adjust the time period of the trending page to filter your results. </p>
                <p>Note: If you block someone their posts will not show up here.</p>

                <h4 style={{marginTop: "15px"}}>Newest</h4>
                <p>The newest page displays the most recent posts throughout the site.</p>
                <p>Note: If you block someone their posts will not show up here.</p>

                <h4 style={{marginTop: "15px"}}>Lists:</h4>
                <p>The list section allows you to add your favorite posts to a private folder so that you can go back to look at it later. You can even put music playlists in here. </p>

                <h4>Analytics:</h4>
                <p>The analytics section allows you to see how many times your posts are being viewed as well as your profile. You can even track how they are finding your posts whether it is through search, newest, trending, or in Feed.</p>
        
                <h4 style={{marginTop: "15px"}}>Notifications:</h4>
                <p>Your notifications are broken down into two sections. The first section is  interactions from users to your posts/profile. The second section is requests. You can accept or reject any follow requests.</p>

                <img src={NotificationsImage} alt="Notifications page" style={{width: "50%"}}/>

                <h4 style={{marginTop: "15px"}}>Grid layout:</h4>

                <p>Grid layout allows you to change the style of viewing throughout the site. You can view multiple posts at a time rather than one. (Coming soon to mobile)</p>

                <h4 style={{marginTop: "15px"}}>Videos only: </h4>

                <p>This layout allows you to filter out all posts and only watch videos. </p>

                <h4 style={{marginTop: "15px"}}>Info Button:</h4>
                <img src={InfoImage} alt="Info button" style={{width: "10%"}}/>
                <p>The info button will take you to our support page. Here you can view our TOS, rules, learn about us, and email support for help.</p>
                
                <h4 style={{marginTop: "15px"}}>Megaphone Button:</h4>

                <p>The megaphone button is our announcements page. </p>

                <p>Now that you understand most of Tiblur. It’s time to make your first post!</p>

                <h4 style={{marginTop: "15px"}}>Post Button:</h4>

                <p>You can post video, images, music, or files. The posting limit is currently 100Mb however we are looking to expand that with more revenue. </p>

                <p>You can also create polls to gather data. </p>

                <p>You can post 1 video at a time, and up to 10 images at a time. Images can be rotated and reformatted for users to look at. </p>

                <p>Simply double tap images on mobile to make them appear full screen. </p>

                <h4 style={{marginTop: "15px"}}>Comment Button:</h4>

                <p>To leave a comment simply click the comment button on the bottom right of a post. From there you can reply to anyone in the comment section. Just look for the looping arrow to the right. </p>

                <h4 style={{marginTop: "15px"}}>Reblog Button:</h4>
                <p>If you want to reblog a post simply hit the reblog button between the like button and the comment button. You can reblog the post and add a comment about it altogether.</p>
            </div>
        </div>
    )
}

export default Tutorial;