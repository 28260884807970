// @flow

import React, {useEffect, useRef, useState} from "react";
import {batch, connect} from "react-redux";

import {divider, postContainer} from "../../../../../css/layout/social/container/main.css";
import layoutStyles, {mB1, mT1} from "../../../../../css/layout.css";
// import formStyles from "../../../../../css/form.css";

import Container from "../../../layout/parts/social/feed/FeedContainer";
import DefaultPost from "../../../layout/parts/social/post/DefaultPost";
// import VideoPost from "../../../layout/parts/social/post/VideoPost";

import store from "../../../../../store";
import {fetchPosts, fetchTopPostsAction} from "../../../../../reducers/social/actions";
import {formatBreakDate} from "../../../../../util/date";
import Error from "../../../layout/parts/social/feed/components/Error";
import Empty from "../../../layout/parts/social/feed/components/Empty";
import Loading from "../../../layout/parts/social/feed/components/Loading";
import useWindowDimensions from "../../../../../util/hooks/useWindowDimensions";
import Masonry from 'react-masonry-component';
import Ad from "../ad/PostAd";
import LitePost from "../../../layout/parts/social/post/LitePost";
import NewPostAd from "../ad/NewPostAd";

import QuickPostButton from "../../../layout/parts/social/post/quickpost/QuickPostButton";
import FixedImageAd from "../ad/FixedImageAd";

function Newest(props) {
    const gridRef = useRef();
    const masonryRef = useRef();
    const { width } = useWindowDimensions();

    function fetch(){
        let params = {};
        if(props.social.skipTo !== null && props.social.useSkipTo === true){
            params.skipTo = props.social.skipTo;
        } else{
            params.offset = props.social.offset;
        }
        {props.social.vidsOnly && (params.type = "video")}
        {props.social.hideReblogs && (params.hideReblogs = true)}

        if(props.social.useSkipTo && props.social.skipTo != null){
            store.dispatch(fetchPosts("/post/feed/newest", params, "posts", props.social.skipTo));
        } else{
            store.dispatch(fetchPosts("/post/feed/newest", params, "posts"));
        }

        if(props.social.skipTo != null && props.social.useSkipTo == true){
            store.dispatch({type: "SET_SKIP_TO", payload: null})
            store.dispatch({type: "SET_USE_SKIP_TO", payload: null})
        }
    }
    
    function fetchTopPosts(){
        if(props.social.offset <= 21){ 
            store.dispatch({type: "STOP_LOADING_TOP"})
            return
        }

        if(props.social.posts.length === 0){
            return;
        }

        let params = {
            useTopPost: true
        };
        {props.social.vidsOnly && (params.type = "video")}

        store.dispatch(fetchTopPostsAction("/post/feed/newest", params, "posts"));
    }

    let lastDate = null;
    let posts = [];
    let postCount = 0
    let boosted = false;
    try{
        boosted = props.auth.user.info.boosted
    } catch(e){}
    props.social.posts.map((post, i) => {
        postCount++;
        // if(!grid && lastDate !== formatBreakDate(post.timestamp)){
        //     lastDate = formatBreakDate(post.timestamp);

        //     posts.push(
        //         <div key={formatBreakDate(post.timestamp) + "-" + post.id} className={divider + (width > 800 ? ' ' + mB1 : "")}>
        //             {formatBreakDate(post.timestamp)}
        //         </div>
        //     );
        // }

        if(!boosted){
            if(postCount % 25 === 0 && postCount !== 0){
                posts.push(<Ad key={i + "-ad"} grid={props.social.grid}/>);
            }
        }

        if(postCount == 3 || postCount % 15 == 0){
            posts.push(<FixedImageAd />)
            posts.push(<div style={{height: "10px"}}></div>)
        }

        posts.push(<DefaultPost key={post.id} grid={props.social.grid} postId={post.id}/>);
    });

    return (
        <Container fetchPosts={fetch} fetchTopPosts={fetchTopPosts}>
            <main ref={gridRef}>
                <div className={postContainer}>
                    {
                        props.social.error &&
                        <div className={mT1}>
                            <Error />
                        </div>
                    }
                </div>
                <div className={postContainer}>
                    {props.social.loadingTop &&
                        <Loading />
                    }
                </div>
                {props.social.grid ?
                <Masonry ref={(c) => { masonryRef.current = c; }} options={{gutter: 5}} enableResizableChildren={true} updateOnEachImageLoad={true}>
                    {posts}
                </Masonry>
                :
                <div className={postContainer}>
                    {props.auth.user != null &&
                        <QuickPostButton />
                    }
                    <div style={{height: "15px"}}></div>
                    {posts}
                </div>
                }
                <div className={postContainer}>
                    {
                        props.social.error &&
                        <div className={mT1}>
                            <Error />
                        </div>
                    }
                    {
                        !props.social.loading && !props.social.error && props.social.posts.length === 0 &&
                        <div className={mT1}>
                            <Empty />
                        </div>
                    }
                    {
                        props.social.loading && !props.social.reachedEnd &&
                        <div className={mT1}>
                            <Loading />
                        </div>
                    }
                    {
                        props.social.reachedEnd &&
                        <div className={layoutStyles.flex + ' ' + layoutStyles.flexColumn + ' ' + layoutStyles.justifyContentCenter + ' ' + layoutStyles.mT3 + ' ' + layoutStyles.mB2}>
                            <hr className={layoutStyles.wF} />
                            <p>You have reached the end!</p>
                        </div>
                    }
                </div>
            </main>
        </Container>
    );
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(Newest);
