// @flow

import React, {useState, useRef, useEffect} from "react";
import ReactTooltip from "react-tooltip";
import { connect, batch } from "react-redux";

import axios from "axios";

import {icon} from "../../../../../../../css/layout/social/nav/top.css";
import {flex, mL, mR1, mR2} from "../../../../../../../css/layout.css";

import searchStyles from "../../../../../../../css/components/search.css";

import HelpIcon from "../../../../../../../assets/svg/icons/info.svg";
import ArrowLeft from "../../../../../../../assets/svg/icons/arrowLeft.svg";
import ArrowRight from "../../../../../../../assets/svg/icons/arrowRight.svg";
import SearchIcon from "../../../../../../../assets/svg/icons/search.svg";
import LeftChatLayout from "../../../../../../../assets/svg/icons/bars.svg";
import BubblesChatLayout from "../../../../../../../assets/svg/icons/chat.svg";
import TimesIcon from "../../../../../../../assets/svg/icons/times.svg";

import history from "../../../../../../../util/history";
import outsideClick from "../../../../../../../util/components/outsideClick";
import {MAX_MOBILE_WIDTH, API_URL} from "../../../../../../../util/constants";
import useWindowDimensions from "../../../../../../../util/hooks/useWindowDimensions";

import ProfilePicture from "../../../../../layout/parts/user/ProfilePicture"

import store from "../../../../../../../store";

import { formatDistanceToNowStrict } from "date-fns";

function Actions(props) {
    const {width} = useWindowDimensions();

    const chatSearchRef = useRef(null)

    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [isChatSearchFocused, setIsChatSearchFocused] = useState(false);

    const handleOutsideClick = () => {
        setIsChatSearchFocused(false);
     };

    function handleJumpToMessage(messageId) {
        setIsChatSearchFocused(false)
        batch(() => {
            store.dispatch({ type: "SET_JUMPED_MESSAGE_ID", payload: messageId });
            store.dispatch({ type: "SET_PERFORMING_JUMP", payload: true });
        });    
        // --> to ChatHistory useEffect
    }
    
    function handleSearch(offset = 0) {
        const data = {
            "channel": props.chat.currentChannel,
            "search": searchValue,
            "offset": offset,
        };
    
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        };

        axios.post(`${API_URL}/chat/search`, data, config)
            .then(response => {
                if (response.data.data.messages && response.data.data.messages.length > 0) {
                    setLoadMore(true)
                    if (offset !== 0) {    
                        setSearchResults(prevSearchResults => [...prevSearchResults, ...response.data.data.messages]);
                    } else {
                        setSearchResults(response.data.data.messages);
                    }
                } else {
                    if(offset == 0) {                    
                        setSearchResults(null);
                    }
                    setLoadMore(false)
                }
            })
            .catch(error => {
                setSearchResults(null);
                console.log(error);
            });
    }

    useEffect(() => {
        if (!isChatSearchFocused) {
            setSearchValue("");
            setSearchResults([]);
        }
    }, [isChatSearchFocused])

    if(!props.auth.isAuthenticated){
      return (
          <div className={flex + " " + mL}>
              <div data-tip data-for="help" onClick={() => { history.push("/support") }} className={icon + " " + (width <= MAX_MOBILE_WIDTH ? mR1 : mR2)}>
                  <HelpIcon width={28} />
              </div>
              <ReactTooltip id="help" place="bottom" type="dark" effect="solid">
                  <span>Help/Info</span>
              </ReactTooltip>
          </div>
      )
    }

    outsideClick(chatSearchRef, handleOutsideClick);

    if(props.mobileChat) {
        return (
            <div className={flex + " " + mL}>                               
                <div ref={chatSearchRef} className={searchStyles.container}>
                    <div className={searchStyles.search}>
                        {!isChatSearchFocused && 
                            <div className={searchStyles.mobileChatSearchIcon} onClick={() => setIsChatSearchFocused(true)}>
                                <SearchIcon width={20} height={20} />
                            </div>
                        }          
                        {isChatSearchFocused &&                             
                            <TimesIcon
                                height={20}
                                width={20}
                                onClick={() => {setIsChatSearchFocused(false)}}
                                className={searchStyles.mobileChatSearchClearButton}
                                style={{marginRight: "2px"}}
                            /> 
                        }
                    </div>
                    
                    {isChatSearchFocused && (
                        <div 
                            className={searchStyles.mobileDMsearchResultsContainer}
                        >
                            <div style={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
                                <input
                                    className={searchStyles.mobileChatSearchInput}
                                    placeholder={props.chatUser !== null ? `Search in @${props.chatUser.info.username}` : "Search..."}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onFocus={() => setIsChatSearchFocused(true)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    style={{ paddingRight: "40px" }}
                                />
                                <button className={searchStyles.mobileChatSearchButton} onClick={() => handleSearch()}>
                                    <div className={searchStyles.mobileChatSearchIcon}>
                                        <SearchIcon width={20} height={20} />
                                    </div>
                                </button>
                            </div>              

                            {searchResults == null ? 
                                <div className={searchStyles.nothingFound}>
                                    <h4>Nothing found...</h4>
                                </div>
                            : 
                            <>    
                                {searchResults.map((message, index) => {
                                    const isSender = props.chatUser.info.id === message.sender;
                                    const user = isSender ? props.chatUser : props.auth.user;
                                    const username = isSender ? props.chatUser.info.username : props.auth.user.info.username    
                                    return (
                                        <div 
                                            onMouseDown={() => handleJumpToMessage(message.id)} 
                                            key={index} 
                                            className={searchStyles.mobileDMsearchResultItem}
                                        >
                                            <div className={searchStyles.DMsearchFlexColumnStart}>
                                                <div className={searchStyles.DMsearchFlexBetween}>
                                                    <div className={searchStyles.DMsearchFlexRowCenter}>
                                                        <ProfilePicture small={true} user={user}/>
                                                        <h4 className={searchStyles.marginLeft4}>{username}</h4>
                                                        {message.timestamp !== undefined && (
                                                            <h6 className={searchStyles.DMsearchDate}>{formatDistanceToNowStrict(message.timestamp, { addSuffix: true })}</h6>
                                                        )}
                                                    </div>
                                                    <button onMouseDown={() => handleJumpToMessage(message.id)}
                                                        className={searchStyles.jumpButton}>
                                                        Jump
                                                    </button>
                                                </div>
                                                <div className={searchStyles.DMsearchResultsText}>
                                                    {message.content}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {searchResults.length !== 0 && loadMore &&
                                    <div onClick={() => {handleSearch((searchResults[searchResults.length - 1].offset)+1)}} className={searchStyles.loadMore}>
                                        <h4>Load more...</h4>
                                    </div>
                                }
                            </>
                            }

                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div className={flex + " " + mL}>                               
                {props.chatUser !== null && 
                <>
                    <div ref={chatSearchRef} className={searchStyles.container}>
                        <div className={searchStyles.search} style={{ width: isChatSearchFocused ? "550px" : "225px" }}>
                            <div className={searchStyles.searchIcon}>
                                <SearchIcon width={20} height={20} />
                            </div>
                            <input
                                className={searchStyles.input}
                                placeholder={props.chatUser !== null ? isChatSearchFocused ? `Search in @${props.chatUser.info.username}` : "Search..." : "Search..."}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onFocus={() => setIsChatSearchFocused(true)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                    handleSearch();
                                    }
                            }}
                            />
                            {isChatSearchFocused &&                             
                                <TimesIcon
                                    height={20}
                                    width={20}
                                    onClick={() => {setIsChatSearchFocused(false)}}
                                    className={searchStyles.clearButton}
                                    style={{marginRight: "2px"}}
                                /> 
                            }
                        </div>
                        
                        {isChatSearchFocused && (
                            <div 
                                className={searchStyles.DMsearchResultsContainer}
                            >
                                {searchResults == null ? 
                                    <div className={searchStyles.nothingFound}>
                                        <h4>Nothing found...</h4>
                                    </div>
                                : 
                                <>    
                                    {searchResults.map((message, index) => {
                                        const isSender = props.chatUser.info.id === message.sender;
                                        const user = isSender ? props.chatUser : props.auth.user;
                                        const username = isSender ? props.chatUser.info.username : props.auth.user.info.username    
                                        return (
                                            <div 
                                                onMouseDown={() => handleJumpToMessage(message.id)} 
                                                key={index} 
                                                className={searchStyles.DMsearchResultItem}
                                            >
                                                <div className={searchStyles.DMsearchFlexColumnStart}>
                                                    <div className={searchStyles.DMsearchFlexBetween}>
                                                        <div className={searchStyles.DMsearchFlexRowCenter}>
                                                            <ProfilePicture small={true} user={user} />
                                                            <h4 className={searchStyles.marginLeft4}>{username}</h4>
                                                            {message.timestamp !== undefined && (
                                                                <h6 className={searchStyles.DMsearchDate}>{formatDistanceToNowStrict(message.timestamp, { addSuffix: true })}</h6>
                                                            )}
                                                        </div>
                                                        <button onMouseDown={() => handleJumpToMessage(message.id)}
                                                            className={searchStyles.jumpButton}>
                                                            Jump
                                                        </button>
                                                    </div>
                                                    <div className={searchStyles.DMsearchResultsText}>
                                                        {message.content}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {searchResults.length !== 0 && loadMore &&
                                        <div onClick={() => {handleSearch((searchResults[searchResults.length - 1].offset)+1)}} className={searchStyles.loadMore}>
                                            <h4>Load more...</h4>
                                        </div>
                                    }
                                </>
                                }
        
                            </div>
                        )}
                    </div>                 
                    {width > 768 &&
                    <>
                      <div data-tip data-for="toggleLayout" onClick={() => { store.dispatch({type: "TOGGLE_CHAT_LAYOUT"}) }} className={icon + " " + (width <= MAX_MOBILE_WIDTH ? mR1 : mR2)}>
                      {props.chat.bubblesLayout ?     
                          <LeftChatLayout width={28} /> 
                      : 
                          <BubblesChatLayout width={28} />
                      }
                      </div>
                      <ReactTooltip id="toggleLayout" place="bottom" type="dark" effect="solid">
                          {props.chat.bubblesLayout ? <span>Compact</span> : <span>Bubbles</span>}
                      </ReactTooltip>
                      
                      <div data-tip data-for="toggleUserProfile" onClick={() => { store.dispatch({type: "TOGGLE_PERSON_PREVIEW"}) }} className={icon + " " + (width <= MAX_MOBILE_WIDTH ? mR1 : mR2)}>
                          {props.chat.personPreview ? 
                              <ArrowRight width={28} /> 
                          : 
                              <ArrowLeft width={28} />
                          }
                      </div>
                      <ReactTooltip id="toggleUserProfile" place="bottom" type="dark" effect="solid">
                          {props.chat.personPreview ? <span>Hide User Profile</span> : <span>Show User Profile</span>}
                      </ReactTooltip>   
                    </>               
                    }

                </>
                }
                
                <div data-tip data-for="help" onClick={() => { history.push("/support") }} className={icon + " " + (width <= MAX_MOBILE_WIDTH ? mR1 : mR2)}>
                    <HelpIcon width={28} />
                </div>
                <ReactTooltip id="help" place="bottom" type="dark" effect="solid">
                    <span>Help/Info</span>
                </ReactTooltip>

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth: auth, chat: state.chat };
};

export default connect(mapStateToProps)(Actions);
