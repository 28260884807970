import React from "react";

function TiblurAd(props) {
    return (
        <div style={{width: "250px", height: "250px", border: "1px solid white"}}>
            <p>Example advert</p>
        </div>
    )
}

export default TiblurAd;