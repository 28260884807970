// @flow

import React from "react";

import navStyles from "../../../../../../css/components/tabs-nav.css";

import history from "../../../../../../util/history";

function ModerationNav(props) {

    let params = new URLSearchParams(history.location.search);

    return (
        <div className={navStyles.nav}>
            <div className={navStyles.pages}>
                <div onClick={() => { history.push(`/moderation`) }}
                     className={navStyles.page}>
                    <label>
                        Reports
                    </label>
                    {
                        params.get('tab') === null &&
                        <span className={navStyles.active}/>
                    }
                </div>
                <div onClick={() => { history.push(`/moderation?tab=deletions`) }}
                     className={navStyles.page}>
                    <label>
                        Your Deletions
                    </label>
                    {
                        params.get('tab') == "deletions" &&
                        <span className={navStyles.active}/>
                    }
                </div>
                <div onClick={() => { history.push(`/moderation?tab=alldeletions`) }}
                     className={navStyles.page}>
                    <label>
                        All Deletions
                    </label>
                    {
                        params.get('tab') == "alldeletions" &&
                        <span className={navStyles.active}/>
                    }
                </div>
                <div onClick={() => { history.push(`/moderation?tab=logs`) }}
                     className={navStyles.page}>
                    <label>
                        Logs
                    </label>
                    {
                        params.get('tab') === 'logs' &&
                        <span className={navStyles.active}/>
                    }
                </div>
                <div onClick={() => { history.push(`/moderation?tab=permissions`) }}
                     className={navStyles.page}>
                    <label>
                        Permissions
                    </label>
                    {
                        params.get('tab') === 'permissions' &&
                        <span className={navStyles.active}/>
                    }
                </div>
                <div onClick={() => { history.push(`/moderation?tab=sitestats`) }}
                     className={navStyles.page}>
                    <label>
                        Site Stats
                    </label>
                    {
                        params.get('tab') === 'sitestats' &&
                        <span className={navStyles.active}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default ModerationNav;
