import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';

import { API_URL, BANDWIDTH_SOCKET, RESOURCES_URL } from '../../../../util/constants';

import DefaultPost from "../../../../components/desktop/layout/parts/social/post/DefaultPost";

import {postContainer} from "../../../../css/layout/social/container/main.css"

import formStyles from "../../../../css/form.css"
import Reply from '../../layout/parts/social/post/components/footer/Reply';
import { Link } from 'react-router-dom';
import FeedContainer from '../../layout/parts/social/feed/FeedContainer';

import Switch from "../../../../components/desktop/layout/parts/switch/Switch";
import history from '../../../../util/history';
import ModerationNav from '../../layout/parts/social/nav/ModerationNav';
import ProfilePicture from '../../layout/parts/user/ProfilePicture';
import Fetcher from '../../layout/parts/social/feed/Fetcher';

import divider from "../../../../css/layout/social/container/main.css"
import isMobile from 'is-mobile';
import MainContainer from '../../layout/parts/social/container/MainContainer';

// TODO: manage token properly
// TODO: Live update reports to handle multiple moderators

function Box(props){
    let symbol;
    let numberColor;
    if(props.number > 0){
        symbol = "+";
        numberColor = "#15de97";
    } else if(props.number < 0){
        // symbol = "-";
        numberColor = "#da133e";
    } else{
        symbol = "";
        numberColor = "#7286e9";
    }

    return(
        <div style={{border: props.thin == true ? "1px solid whitesmoke" : "2px solid whitesmoke", borderRadius: "5px", padding: "10px", maxWidth: "fit-content"}}>
            <h3>{props.title}</h3>
            <h4 style={{color: numberColor}}>{symbol}{props.number}</h4>
        </div>
    )
}

function Container(props){
    return(
        <div style={{border: "2px solid whitesmoke", borderRadius: "5px", padding: "10px", maxWidth: "fit-content"}}>
            <h3>{props.title}</h3>
            {props.children.map(child => {
                return (
                    <p>{child.title}: {child.value}</p>
                )
            })}
        </div>
    )
}

function Moderation(props){
    let [manager, setManager] = useState({
        reports: [],
        trash: [],
        alltrash: [],
        siteStats: null,
        systemStats: {},
        containerStats: {}
    })

    let [admins, setAdmins] = useState([])
    let [moderators, setModerators] = useState([])

    let [modPermissions, setModPermissions] = useState({
            suppressPost: false,
            deletePost: false,
            fullDeletePost: false,
            deleteComment: false,
            banUser: false,
            suppressUser: false,
            setModerator: false,
            setBoost: false,
            viewLogs: false,
            viewSiteStats: false,
            viewAllDeletions: false
    })

    let [logs, setLogs] = useState([])
    let [endOfLogs, setEndOfLogs] = useState(false)

    let [controls, setControls] = useState({
        visitors: {
            videoDownloadMaxBandwidth: 800
        },
        plebs: {
            videoDownloadMaxBandwidth: 800,
            videoUploadMaxBandwidth: 250,
            postPerMinuteLimit: 10,
            postCooldownSecs: 10,
            replyPerMinuteLimit: 10,
            replyCooldownSecs: 10
        },
        boosters: {
            videoDownloadMaxBandwidth: 800,
            videoUploadMaxBandwidth: 250,
            postPerMinuteLimit: 10,
            postCooldownSecs: 10,
            replyPerMinuteLimit: 10,
            replyCooldownSecs: 10
        }
    })

    let [bandwidthHistory, setBandwidthHistory] = useState({
        fiveminute: null,
        hour: null,
        day: null,
        month: null
    })
    let [currentBandwidth, setCurrentBandwidth] = useState("")

    let [controlButtonText, setControlButtonText] = useState("Save")

    const mobile = isMobile();
    let params = new URLSearchParams(history.location.search);

    useEffect(() => {
        getModPermissions()
    }, [])

    useEffect(() => {
        let loop = null;
        let params = new URLSearchParams(history.location.search);
        if(params.get("tab") == null){
            getReports()
        } else if(params.get("tab") == "permissions"){
            getModPermissions()
            getModerators()
            getAdmins()
        } else if(params.get("tab") == "sitestats"){
            getSiteStats()
            getContainerStats()
            getSystemStats()
            getBandwidthStats()
            getControlValues()
            startBandwidthSocket()

            loop = setInterval(() => {
                reloadResources()
            }, 10_000)

        } else if(params.get("tab") == "logs"){
            getLogs()
        } else if(params.get("tab") == "deletions"){
            getTrash()
        } else if(params.get("tab") == "alldeletions"){
            getAllTrash()
        }

        if(params.get("tab") != "sitestats"){
            clearInterval(loop)
        }

        return () => {
            clearInterval(loop)
        }
    }, [history.location])

    function getReports(){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(API_URL + '/moderation/reports', 
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                setManager({...manager, reports: res.data.data.reports})
            })
            .catch(err => {})
        })
    }

    function getModerators(){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(API_URL + '/moderation/moderators', 
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                setModerators(res.data.data.moderators)
            })
            .catch(err => {})
        })
    }

    function getAdmins(){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(API_URL + '/moderation/admins', 
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                setAdmins(res.data.data.admins)
            })
            .catch(err => {})
        })
    }

    function getSiteStats(){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(API_URL + '/moderation/site-stats', 
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                setManager({...manager, siteStats: res.data.data})
            })
            .catch(err => {})
        })
    }

    function getContainerStats(){
        axios
        .get(RESOURCES_URL + '/containers')
        .then(res => {
            setManager((manager) => {
                return {...manager, containerStats: res.data.data}
            })
        }).catch(err => {})
    }

    function getSystemStats(){
        axios
        .get(RESOURCES_URL + '/system')
        .then(res => {
            setManager((manager) => {
                return {...manager, systemStats: res.data.data}
            })
        }).catch(err => {})
    }

    function getBandwidthStats(){
        axios
        .get(RESOURCES_URL + '/bandwidth-history')
        .then(res => {
            setBandwidthHistory({
                fiveminute: res.data.data.fiveminute,
                hour: res.data.data.hour,
                day: res.data.data.day,
                month: res.data.data.month
            })
        }).catch(err => {})
    }

    async function getControlValues(){
        // rate limits
        let plebPostPerMinuteLimit;
        let plebPostCooldownSecs;
        let plebReplyPerMinuteLimit;
        let plebReplyCooldownSecs;

        let boosterPostPerMinuteLimit;
        let boosterPostCooldownSecs;
        let boosterReplyPerMinuteLimit;
        let boosterReplyCooldownSecs;

        try {
            const result = await axios.get(API_URL + "/post/rate-limits")

            plebPostPerMinuteLimit = result.data.data.plebs.posts.perMinute
            plebPostCooldownSecs = result.data.data.plebs.posts.cooldownSecs
            plebReplyPerMinuteLimit = result.data.data.plebs.replies.perMinute
            plebReplyCooldownSecs = result.data.data.plebs.replies.cooldownSecs

            boosterPostPerMinuteLimit = result.data.data.boosters.posts.perMinute
            boosterPostCooldownSecs = result.data.data.boosters.posts.cooldownSecs
            boosterReplyPerMinuteLimit = result.data.data.boosters.replies.perMinute
            boosterReplyCooldownSecs = result.data.data.boosters.replies.cooldownSecs
        } catch(err){}

        // bandwidth
        let visitorVideoDownloadMaxBandwidth;
        let plebVideoDownloadMaxBandwidth;
        let boosterVideoDownloadMaxBandwidth;

        try{
            const result = await axios.get(API_URL + '/social/bandwidth', { headers: { "Content-Type": "application/json" } })
            visitorVideoDownloadMaxBandwidth = result.data.data.visitorVideoDownloadBandwidthKb
            plebVideoDownloadMaxBandwidth = result.data.data.plebVideoDownloadBandwidthKb
            boosterVideoDownloadMaxBandwidth = result.data.data.boosterVideoDownloadBandwidthKb
            console.log(`Pleb: ${plebVideoDownloadMaxBandwidth}, Booster: ${boosterVideoDownloadMaxBandwidth}`)
        } catch(err){}

        setControls({
            ...controls,
            visitors: {
                ...controls.visitors,
                videoDownloadMaxBandwidth: visitorVideoDownloadMaxBandwidth || 1000
            },
            plebs: {
                ...controls.plebs,
                videoDownloadMaxBandwidth: plebVideoDownloadMaxBandwidth != undefined ? plebVideoDownloadMaxBandwidth : 1000,
                postPerMinuteLimit: plebPostPerMinuteLimit != undefined ? plebPostPerMinuteLimit : 10,
                postCooldownSecs: plebPostCooldownSecs != undefined ? plebPostCooldownSecs : 5,
                replyPerMinuteLimit: plebReplyPerMinuteLimit != undefined ? plebReplyPerMinuteLimit : 10,
                replyCooldownSecs: plebReplyCooldownSecs != undefined ? plebReplyCooldownSecs : 5
            },
            boosters: {
                ...controls.boosters,
                videoDownloadMaxBandwidth: boosterVideoDownloadMaxBandwidth != undefined ? boosterVideoDownloadMaxBandwidth : 1000,
                postPerMinuteLimit: boosterPostPerMinuteLimit != undefined ? boosterPostPerMinuteLimit : 10,
                postCooldownSecs: boosterPostCooldownSecs != undefined ? boosterPostCooldownSecs : 5,
                replyPerMinuteLimit: boosterReplyPerMinuteLimit != undefined ? boosterReplyPerMinuteLimit : 10,
                replyCooldownSecs: boosterReplyCooldownSecs != undefined ? boosterReplyCooldownSecs : 5
            }
        })
    }

    function reloadResources(){
        getContainerStats()
        getSystemStats()
        getBandwidthStats()
    }

    function startBandwidthSocket(){
        const login = {
            type: "login",
            token: props.auth.token
        }
        let client = new WebSocket(`${BANDWIDTH_SOCKET}`)
        client.onopen = () => {
            client.send(JSON.stringify(login))
        }
        client.onmessage = (message) => {
            const data = JSON.parse(message.data)
            const up = data.tx.ratestring
            const down = data.rx.ratestring
            setCurrentBandwidth(`${up} / ${down}`)
        }
    }

    function getLogs(){
        setManager({...manager, fetchingLogs: true})
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(`${API_URL}/moderation/logs?offset=${logs.length}`,
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                if(res.data.data.logs.length == 0){
                    setEndOfLogs(true)
                } else{
                    setLogs([...logs, ...res.data.data.logs])
                }
            })
            .catch(err => {})
        })
    }

    function getTrash(){
        setManager({...manager, fetchingLogs: true})
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(`${API_URL}/moderation/trash`,
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                for(let post of res.data.data.posts){
                    post.type = "post"
                }
                setManager({...manager, trash: res.data.data.posts})
            })
            .catch(err => {})
        })
    }

    function getAllTrash(){
        setManager({...manager, fetchingLogs: true})
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(`${API_URL}/moderation/all-trash`,
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                for(let post of res.data.data.posts){
                    post.type = "post"
                }
                setManager({...manager, alltrash: res.data.data.posts})
            })
            .catch(err => {})
        })
    }

    function markOK(report){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.post(API_URL + `/moderation/markOK/${report.type == "user" ? report.info.id : report.id}`, {},
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            setManager({
                ...manager,
                reports: manager.reports.filter(r => r.id != report.id),
                trash: manager.trash.filter(r =>r.id != report.id),
                alltrash: manager.alltrash.filter(r =>r.id != report.id)
            })
        })
    }

    function markDeletePost(report){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.post(API_URL + `/moderation/markDeletePost/${report.id}`, {},
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            setManager({...manager, reports: manager.reports.filter(r => r.id != report.id)})
        })

    }

    function deletePost(report){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.post(`${API_URL}/moderation/${report.type == "post" ? "deletePost" : "deleteReply"}/${report.parent_id ? report.parent_id + "/" : ""}${report.id}`, {},
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            setManager({
                ...manager,
                reports: manager.reports.filter(r => r.id != report.id),
                trash: manager.trash.filter(r =>r.id != report.id),
                alltrash: manager.alltrash.filter(r =>r.id != report.id)
            })
        })
    }

    function ban(report){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.post(API_URL + `/moderation/ban/${report.type == "user" ? report.info.id : report.id}`, {type: "ban"},
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            setManager({...manager, reports: manager.reports.filter(r => r.id != report.id)})
        })
    }

    function suppress(report){
        if(report.type == "post"){
            axios
            .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
            .then(res => {
                axios.post(API_URL + `/moderation/suppress/post/${report.id}`, {},
                {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
                setManager({...manager, reports: manager.reports.filter(r => r.id != report.id)})
            })
        } else if(report.type == "user"){
            axios
            .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
            .then(res => {
                axios.post(API_URL + `/moderation/suppress/user/${report.info.id}`, {},
                {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
                setManager({...manager, reports: manager.reports.filter(r => r.id != report.id)})
            })
        }
    }

    function getModPermissions(){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.get(API_URL + '/moderation/get-mod-permissions', 
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                if(res.data.data.permissions != null){
                    setModPermissions(res.data.data.permissions)
                }
            })
        })
    }

    function setModeratorPermission(key, value){
        axios
        .post(API_URL + "/auth/token/refresh", {refresh_token: props.auth.refreshToken}, {"Content-Type": "application/json"})
        .then(res => {
            axios.post(API_URL + '/moderation/set-mod-permission', {key: key, value: !value},
            {headers: {"Content-Type": "application/json", "Auth-Token": res.data.data.token}})
            .then(res => {
                setModPermissions({...modPermissions, [key]: !value})
            })
        })
    }

    function setControl(group, key, value){
        const valueInt = parseInt(value)
        setControls({ ...controls, [group]: { ...controls[group], [key]: valueInt } })
    }

    async function saveControls(){
        setControlButtonText("Saving...")

        await axios.patch(API_URL + '/social/bandwidth', {
            visitorBandwidthKb: controls.visitors.videoDownloadMaxBandwidth,
            plebBandwidthKb: controls.plebs.videoDownloadMaxBandwidth,
            boosterBandwidthKb: controls.boosters.videoDownloadMaxBandwidth
        }, {headers: {"Content-Type": "application/json", "Auth-Token": props.auth.token}})

        await axios.patch(API_URL + '/post/rate-limits', {
            plebPostsPerMinute: controls.plebs.postPerMinuteLimit,
            plebPostCooldown: controls.plebs.postCooldownSecs,
            plebRepliesPerMinute: controls.plebs.replyPerMinuteLimit,
            plebReplyCooldown: controls.plebs.replyCooldownSecs,

            boosterPostsPerMinute: controls.boosters.postPerMinuteLimit,
            boosterPostCooldown: controls.boosters.postCooldownSecs,
            boosterRepliesPerMinute: controls.boosters.replyPerMinuteLimit,
            boosterReplyCooldown: controls.boosters.replyCooldownSecs
        }, {headers: {"Content-Type": "application/json", "Auth-Token": props.auth.token}})

        // await axios.patch(API_URL + '/post/posts-per-minute', {
        //     plebPostsPerMinute: controls.plebs.postPerMinuteLimit,
        //     plebRepliesPerMinute: controls.plebs.replyPerMinuteLimit,
        //     boosterPostsPerMinute: controls.boosters.postPerMinuteLimit,
        //     boosterRepliesPerMinute: controls.boosters.replyPerMinuteLimit
        // }, {headers: {"Content-Type": "application/json", "Auth-Token": props.auth.token}})

        // await axios.patch(API_URL + '/post/post-cooldown', {
        //     plebPostCooldown: controls.plebs.postCooldownSecs,
        //     plebReplyCooldown: controls.plebs.replyCooldownSecs,
        //     boosterPostCooldown: controls.boosters.postCooldownSecs,
        //     boosterReplyCooldown: controls.boosters.replyCooldownSecs
        // }, {headers: {"Content-Type": "application/json", "Auth-Token": props.auth.token}})

        setControlButtonText("Saved!")
    }

    return(
        <MainContainer fetchPosts={() => {}}>
        <div style={{maxHeight: "100%", width: "100%", overflowX: "scroll"}}>
            <ModerationNav />
            {params.get("tab") == null &&
            manager.reports.map(report => {
                if(report.type == "post"){
                return (
                    <div>
                        <div className={postContainer}>
                            <DefaultPost
                            post={report}
                            auth={props.auth}
                            postId={report.id}
                            />
                        </div>
                        <p style={{textAlign: "center", marginBottom: "10px", padding: "0"}}>
                            {report.count} report{report.count != 1 && "s"}
                        </p>
                        <div style={{display: "flex", justifyContent: "center", gap: "25px", marginBottom: "25px"}}>
                            <button className={formStyles.button + ' ' + formStyles.buttonPrimary} onClick={() => markOK(report)}>Mark OK</button>
                            <button className={formStyles.button + ' ' + formStyles.buttonSecondary} style={{backgroundColor: "orange"}} onClick={() => suppress(report)}>Suppress</button>
                            <button className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => markDeletePost(report)}>Delete</button>
                        </div>
                        <hr style={{borderTop: "1px solid gray", width: "100%"}} />
                    </div>
                )
                }
                else if(report.type == "user"){
                return(
                    <div style={{marginTop: "10px", marginBottom: "10px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <a href={`/user/${report.info.username}`} target="_blank"><h3>{report.info.username} ({report.count} report{report.count != 1 && "s"})</h3></a>
                        <p>Click to open profile in new tab.</p>
                        <div style={{display: "flex", justifyContent: "center", gap: "25px", marginTop: "25px"}}>
                            <button className={formStyles.button + ' ' + formStyles.buttonPrimary} onClick={() => markOK(report)}>Mark OK</button>
                            <button className={formStyles.button + ' ' + formStyles.buttonSecondary} style={{backgroundColor: "orange"}} onClick={() => suppress(report)}>Suppress</button>
                            <button className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => ban(report)}>Ban</button>
                        </div>
                    </div>
                )
                }
                else if(report.type == "reply"){
                return(
                    <div style={{borderTop: "1px solid white", borderBottom: "1px solid white"}}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p style={{textAlign: "center"}}>{report.count} report{report.count != 1 && "s"}</p>
                            <Reply
                                reply={report}
                                auth={props.auth}
                                isReported={true}
                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "center", gap: "25px", marginBottom: "25px"}}>
                            <button className={formStyles.button + ' ' + formStyles.buttonPrimary} onClick={() => markOK(report)}>Mark OK</button>
                            <button className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => deletePost(report)}>Delete</button>
                        </div>
                    </div>
                )
                }
            })
            }
            {params.get("tab") == null && manager.reports.length == 0 &&
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1 style={{textAlign: "center", marginTop: "15%"}}>No reports</h1>
            </div>
            }

            {params.get("tab") == "deletions" &&
            <div>
                {manager.trash.map(post => {
                    return (
                    <div>
                        <div className={postContainer}>
                            <DefaultPost
                            post={post}
                            auth={props.auth}
                            postId={post.id}
                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "center", gap: "25px", marginBottom: "25px"}}>
                            <button className={formStyles.button + ' ' + formStyles.buttonPrimary} onClick={() => markOK(post)}>Restore Post</button>
                            {(props.auth.user.info.isAdmin || modPermissions.fullDeletePost) &&
                               <button className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => deletePost(post)}>Fully Delete</button>
                            }
                        </div>
                        <hr style={{borderTop: "1px solid gray", width: "100%"}} />
                    </div>
                    )
                })}
            </div>
            }

            {params.get("tab") == "deletions" && manager.trash.length == 0 &&
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1 style={{textAlign: "center", marginTop: "15%"}}>No deletions</h1>
            </div>
            }

            {params.get("tab") == "alldeletions" && manager.alltrash.length == 0 &&
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1 style={{textAlign: "center", marginTop: "15%"}}>No deletions</h1>
            </div>
            }

            {params.get("tab") == "alldeletions" &&
                manager.alltrash.map(post => {
                    return (
                    <div>
                        <div className={postContainer}>
                            <DefaultPost
                            post={post}
                            auth={props.auth}
                            postId={post.id}
                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "center", gap: "25px", marginBottom: "25px"}}>
                            <button className={formStyles.button + ' ' + formStyles.buttonPrimary} onClick={() => markOK(post)}>Restore Post</button>
                            {(props.auth.user.info.isAdmin || modPermissions.fullDeletePost) &&
                                <button className={formStyles.button + ' ' + formStyles.buttonSecondary} onClick={() => deletePost(post)}>Fully Delete</button>
                            }
                        </div>
                        <hr style={{borderTop: "1px solid gray", width: "100%"}} />
                    </div>
                    )
                })
            }

            {params.get("tab") == "permissions" && modPermissions != null &&
            <div style={{width: "fit-content", padding: "5px", marginTop: "50px", marginLeft: "50px"}}>
                <div style={{display: "flex"}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p>Posts</p>
                        {Switch("Un/Suppress posts", (value) => setModeratorPermission("suppressPost", !value), modPermissions.suppressPost)}
                        {Switch("Mark Delete posts", (value) => setModeratorPermission("deletePost", !value), modPermissions.deletePost)}
                        {Switch("Fully delete posts", (value) => setModeratorPermission("fullDeletePost", !value), modPermissions.fullDeletePost)}
                        {Switch("Delete comments", (value) => setModeratorPermission("deleteComment", !value), modPermissions.deleteComment)}

                        <p style={{marginTop: "10px"}}>Users</p>
                        {Switch("Un/Ban users", (value) => setModeratorPermission("banUser", !value), modPermissions.banUser)}
                        {Switch("Suppress users", (value) => setModeratorPermission("suppressUser", !value), modPermissions.suppressUser)}
                        {Switch("Add/Remove moderators", (value) => setModeratorPermission("setModerator", !value), modPermissions.setModerator)}
                        
                        <p style={{marginTop: "10px"}}>Boost</p>
                        {Switch("Add/Remove Boost", (value) => setModeratorPermission("setBoost", !value), modPermissions.setBoost)}

                        <p style={{marginTop: "10px"}}>Other</p>
                        {Switch("View logs", (value) => setModeratorPermission("viewLogs", !value), modPermissions.viewLogs)}
                        {Switch("View site stats", (value) => setModeratorPermission("viewSiteStats", !value), modPermissions.viewSiteStats)}
                        {Switch("View All Deletions", (value) => setModeratorPermission("viewAllDeletions", !value), modPermissions.viewAllDeletions)}
                    </div>
                    <div style={{marginLeft: "15px"}}>
                        <h4>Moderators:</h4>
                        {moderators.map((moderator, i) => {
                            return(
                                <p key={`mod-${i}`}><Link to={`/user/${moderator}`}>{moderator}</Link></p>
                            )
                        })}
                        <h4>Admins:</h4>
                        {admins.map((admin, i) => {
                            return(
                                <p key={`admin-${i}`}><Link to={`/user/${admin}`}>{admin}</Link></p>
                            )
                        })}
                    </div>
                </div>
            </div>
            }

            {params.get("tab") == "sitestats" && manager.siteStats != null &&
            <div style={{width: "100%", padding: "5px", marginTop: "50px"}}>

                {/* CONTROLS */}
                <div style={{border: "2px solid whitesmoke", borderRadius: "10px", padding: "15px"}}>
                    <h3>Controls</h3>
                    <br/>
                    <div style={{display: "flex", flexDirection: mobile ? "column" : "row", gap: "25px"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <h4>Visitors (no account)</h4>
                            <p>Video download max (for client)</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} type="range" min={100} max={2000} step={100} value={controls.visitors.videoDownloadMaxBandwidth} onChange={(e) => setControl("visitors", "videoDownloadMaxBandwidth", e.target.value)} />
                                <p>{controls.visitors.videoDownloadMaxBandwidth}kb/s</p>
                            </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column"}}>
                            <h4>Plebs</h4>
                            <p>Video download max (for client)</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} type="range" min={100} max={2000} step={100} value={controls.plebs.videoDownloadMaxBandwidth} onChange={(e) => setControl("plebs", "videoDownloadMaxBandwidth", e.target.value)} />
                                <p>{controls.plebs.videoDownloadMaxBandwidth}kb/s</p>
                            </div>
                            <p>Post Per Minute Max</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.plebs.postPerMinuteLimit} type="range" min={0} max={25} step={1} onChange={(e) => setControl("plebs", "postPerMinuteLimit", e.target.value)} />
                                {controls.plebs.postPerMinuteLimit === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.plebs.postPerMinuteLimit}/m</p>
                                }
                            </div>
                            <p>Post Cooldown Seconds</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.plebs.postCooldownSecs} type="range" min={0} max={120} step={5} onChange={(e) => setControl("plebs", "postCooldownSecs", e.target.value)} />
                                {controls.plebs.postCooldownSecs === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.plebs.postCooldownSecs}/m</p>
                                }
                            </div>
                            <p>Reply Per Minute Max</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.plebs.replyPerMinuteLimit} type="range" min={0} max={25} step={1} defaultValue={10} onChange={(e) => setControl("plebs", "replyPerMinuteLimit", e.target.value)} />
                                {controls.plebs.replyPerMinuteLimit === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.plebs.replyPerMinuteLimit}/m</p>
                                }
                            </div>
                            <p>Reply Cooldown Seconds</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.plebs.replyCooldownSecs} type="range" min={0} max={25} step={1} defaultValue={10} onChange={(e) => setControl("plebs", "replyCooldownSecs", e.target.value)} />
                                {controls.plebs.replyCooldownSecs === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.plebs.replyCooldownSecs}/m</p>
                                }
                            </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column"}}>
                            <h4>Boosters</h4>
                            <p>Video download max (for client)</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.boosters.videoDownloadMaxBandwidth} type="range" min={100} max={2000} step={100} onChange={(e) => setControl("boosters", "videoDownloadMaxBandwidth", e.target.value)} />
                                <p>{controls.boosters.videoDownloadMaxBandwidth}kb/s</p>
                            </div>
                            <p>Post Per Minute Max</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.boosters.postPerMinuteLimit} type="range" min={0} max={25} step={1} onChange={(e) => setControl("boosters", "postPerMinuteLimit", e.target.value)} />
                                {controls.boosters.postPerMinuteLimit === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.boosters.postPerMinuteLimit}/m</p>
                                }
                            </div>
                            <p>Post Cooldown Seconds</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.boosters.postCooldownSecs} type="range" min={0} max={120} step={5} onChange={(e) => setControl("boosters", "postCooldownSecs", e.target.value)} />
                                {controls.boosters.postCooldownSecs === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.boosters.postCooldownSecs}/m</p>
                                }
                            </div>
                            <p>Reply Per Minute Max</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.boosters.replyPerMinuteLimit} type="range" min={0} max={25} step={1} defaultValue={10} onChange={(e) => setControl("boosters", "replyPerMinuteLimit", e.target.value)} />
                                {controls.boosters.replyPerMinuteLimit === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.boosters.replyPerMinuteLimit}/m</p>
                                }
                            </div>
                            <p>Reply Cooldown Seconds</p>
                            <div style={{display: "flex", gap: "15px"}}>
                                <input style={{minWidth: "200px"}} value={controls.boosters.replyCooldownSecs} type="range" min={0} max={25} step={1} defaultValue={10} onChange={(e) => setControl("boosters", "replyCooldownSecs", e.target.value)} />
                                {controls.boosters.replyCooldownSecs === 0 ?
                                <p>Uncapped</p>
                                :
                                <p>{controls.boosters.replyCooldownSecs}/m</p>
                                }
                            </div>
                        </div>
                    </div>

                    <button
                        style={{marginTop: "10px"}}
                        className={formStyles.button + ' ' + formStyles.buttonPrimary}
                        onClick={() => saveControls()}>
                            {controlButtonText}
                    </button>
                </div>

                <br/>
                <div style={{height: "10px"}}></div>

                {/* MACHINE RESOURCES */}
                <div style={{border: "2px solid whitesmoke", borderRadius: "10px", padding: "15px"}}>
                    <h3>Resources</h3>
                    <br/>
                    <div style={{display: "grid", gridTemplateColumns: `repeat(${mobile ? 2 : 4}, 1fr)`, gap: "15px"}}>
                        {/* <Box title={"Bandwidth Up/Down"} number={"257kb / 177kb"} /> */}
                        {manager.systemStats["freeMem"] == null ?
                        <p>Loading...</p>
                        :
                        <>
                            <Box thin={true} title={"CPU Usage"} number={`${(manager.systemStats["cpuUsage"])}%`} />
                            <Box thin={true} title={"Used Memory"} number={`${(manager.systemStats["totalMem"] - manager.systemStats["freeMem"]).toFixed(0)}mb`} />
                            <Box thin={true} title={"Free Memory"} number={`${manager.systemStats["freeMem"].toFixed(0)}mb`} />
                            <Box thin={true} title={"Total Memory"} number={`${manager.systemStats["totalMem"].toFixed(0)}mb`} />
                        </>
                        }
                    </div>
                </div>

                {/* BANDWIDTH */}
                <br/>
                <div style={{border: "2px solid whitesmoke", borderRadius: "10px", padding: "15px"}}>
                    <h3>Bandwidth Usage</h3>
                    <br/>
                    <div style={{display: "grid", gridTemplateColumns: `repeat(${mobile ? 2 : 4}, 1fr)`, gap: "15px"}}>
                        {bandwidthHistory.fiveminute == null ?
                        <p>Loading...</p>
                        :
                        <>
                            <Box thin={true} title={"Up/Down (LIVE)"}
                                number={currentBandwidth}
                            />
                            <Box thin={true} title={"Up/Down (5m)"}
                                number={`${(bandwidthHistory.fiveminute.rx / 1024).toFixed(2)}kb / ${(bandwidthHistory.fiveminute.tx / 1024).toFixed(2)}kb`}
                            />
                            <Box thin={true} title={"Up/Down (1h)"}
                                number={`${(bandwidthHistory.hour.rx / 1024).toFixed(2)}kb / ${(bandwidthHistory.hour.tx / 1024).toFixed(2)}kb`}
                            />
                            <Box thin={true} title={"Up/Down (1d)"}
                                number={`${(bandwidthHistory.day.rx / 1024).toFixed(2)}kb / ${(bandwidthHistory.day.tx / 1024).toFixed(2)}kb`}
                            />
                            <Box thin={true} title={"Up/Down (30d)"}
                                number={`${(bandwidthHistory.month.rx / 1024).toFixed(2)}kb / ${(bandwidthHistory.month.tx / 1024).toFixed(2)}kb`}
                            />
                        </>
                        }
                    </div>
                </div>

                <br/>

                {/* REQUESTS */}
                <div style={{border: "2px solid whitesmoke", borderRadius: "10px", padding: "15px"}}>
                    <h3>Requests</h3>
                    <br/>
                    <div style={{display: "grid", gridTemplateColumns: `repeat(${mobile ? 2 : 4}, 1fr)`, gap: "15px"}}>
                        <Box thin={true} title="Unique Reqs (1h)" number={manager.siteStats.uCountHour} />
                        <Box thin={true} title="Unique Reqs (1d)" number={manager.siteStats.uCountDay} />
                        <Box thin={true} title="Unique Reqs (7d)" number={manager.siteStats.uCountWeek} />
                        <Box thin={true} title="Unique Reqs (30d)" number={manager.siteStats.uCountMonth} />

                        <Box thin={true} title="Unique Post Reqs (1h)" number={manager.siteStats.pCountHour} />
                        <Box thin={true} title="Unique Post Reqs (1d)" number={manager.siteStats.pCountDay} />
                        <Box thin={true} title="Unique Post Reqs (7d)" number={manager.siteStats.pCountWeek} />
                        <Box thin={true} title="Unique Post Reqs (30d)" number={manager.siteStats.pCountMonth} />
                    </div>
                </div>

                <br/>

                {/* CONTAINERS */}
                <br/>
                <h3>Container stats</h3>
                <br/>
                {Object.keys(manager.containerStats).length == 0 &&
                    <h4>Loading...</h4>
                }
                <div style={{display: "grid", gridTemplateColumns: `repeat(${mobile ? 2 : 4}, 1fr)`, gap: "15px"}}>
                    {Object.keys(manager.containerStats).map((key, i) => 
                        <Container
                            title={key}
                            children={
                                Object.keys(manager.containerStats[key]).map((k, j) => {
                                    return {title: k, value: manager.containerStats[key][k]}
                                })
                            }
                        />
                    )}
                </div>

            </div>
            }

            {params.get("tab") == "logs" && logs != null &&
            <div style={{width: "fit-content", padding: "5px", marginLeft: "auto", marginRight: "auto"}}>
            <h3>Logs:</h3>
            {logs.map((log, i) => {
                const date = new Date(log.timestamp);
                return(
                    // <div key={`log-${i}`} style={{borderTop: "1px solid white", borderBottom: "1px solid white", marginLeft: "auto", marginRight: "auto"}}>
                    <div key={`log-${i}`} style={{marginLeft: "auto", marginRight: "auto"}}>
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "5px", marginBottom: "5px"}}>
                                <ProfilePicture small={true} user={log.moderator} />
                                <Link to={`/user/${log.moderator.info.username}`} style={{marginLeft: "15px"}}>
                                    <h2>{log.moderator.info.username}</h2>
                                </Link>
                            </div>
                            <p>{log.action}</p>
                            {log.type == "post" &&
                                <Link to={`/post/${log.target}`}><p style={{color: "lightblue"}}>{log.target}</p></Link>
                            }
                            {log.type == "user" &&
                                <Link to={`/user/${log.target}`}><p style={{color: "lightblue"}}>{log.target}</p></Link>
                            }
                            {!log.type &&
                                <p>{log.target}</p>
                            }
                            <p>{date.toLocaleTimeString('en-GB')} {date.toLocaleDateString('en-GB')}</p>
                        </div>
                        <hr style={{borderTop: "1px solid gray", width: "100%"}} />
                    </div>
                )
            }
            )}

            <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                {endOfLogs ?
                <p>End of logs</p>
                :
                <button className={formStyles.button} onClick={() => getLogs()}>
                    Load more
                </button>
                }
            </div>
            </div>
            }
        </div>
        </MainContainer>
    )
}

const mapStateToProps = state => {
    const { auth, social } = state;
    return { auth: auth, social: social };
};

export default connect(mapStateToProps)(Moderation);
