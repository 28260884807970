import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import history from '../../../../../util/history'

import arrowLeft from '../../../../../assets/svg/arrowLeft.png';
import arrowLeftLight from '../../../../../assets/svg/arrowLeftLight.png';

import store from '../../../../../store';
import { useProfileState } from '../profile/context';
import { func } from 'prop-types';

function BackArrow(props){
    let user;
    try{
        user = useProfileState().user;
    } catch(e){}

    if(!history.entries){
        history.entries = []
    }

    useEffect(() => {
        history.entries.push({
            url: window.location.pathname,
        })
        store.dispatch({type: "SET_TOP_LOCK_FETCH", payload: false})
    }, [window.location.pathname])

    useEffect(() => {
        if(window.location.pathname !== "/social/newest"){ return }
        if(props.social.posts.length === 0){ return }

        let middle = Math.floor(props.social.posts.length / 2)
        store.dispatch({type: "SET_SKIP_TO", payload: props.social.posts[middle].id})
    }, [props.social.posts])

    function previous() {
        if (history.entries.length > 1) {
            store.dispatch({ type: "SET_USE_SKIP_TO", payload: true });
            let toGo = history.entries[history.entries.length - 2].url;
            history.entries.pop();
            history.entries.pop();
            if(toGo.startsWith("/chat/") && toGo !== "/chat/"){
                toGo = "/chat/";
                history.push(toGo);
            } else{
                history.push(toGo);
            }
        }
    }

    function mobilePrevious() {
        store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: null})
    }
    
    let currentTheme;
    try{
        currentTheme = user ? user.info.theme : props.auth.user.info.theme
        if(!currentTheme || props.theme.activeProfileTheme){
            currentTheme = user ? user.info.profile_theme : props.auth.user.info.profile_theme
        }
    } catch(e){}

    if(!currentTheme){
        currentTheme = "dark"
    }

    let arrowTheme = arrowLeftLight
    let themes_map = {
        "dark": "light",
        "light": "dark",
        "clover": "dark",
        "cozy": "light",
        "outrun": "light",
        "cyberpunk": "light",
        "skeleton": "light",
    }
    
    if(themes_map[currentTheme] === "light"){
        arrowTheme = arrowLeftLight
    } else {
        arrowTheme = arrowLeft
    }

    return(
        <div style={{ marginLeft: "10px" }} onClick={() => props.mobileChat ? mobilePrevious() : previous()}>
            <img
                src={arrowTheme}
                width={"30px"}
                height={"30px"}
                alt={"Back"}
            />
        </div>
    );
}

const mapStateToProps = state => {
    const { auth, social, theme } = state;
    return { auth: auth, social: social, theme: theme };
};
export default connect(mapStateToProps)(BackArrow);
