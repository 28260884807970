import React from "react";

import InfoIcon from "../../../../../assets/svg/icons/info.svg";

export function Alert(props){
    if(props.type.toLowerCase() == "warning"){
        return(
            <div style={{border: props.border != false && "3px solid #f1c40f", padding: "15px", borderRadius: "5px"}}>
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <InfoIcon width="30"/>
                    <h3 style={{margin: "0"}}>{props.title}</h3>
                </div>
                <p style={{margin: "0", marginTop: "5px"}}>{props.text}</p>
            </div>
        )
    }
}